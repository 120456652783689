// Libraries
import React, { useState } from 'react';

// Styles
import classes from './style.module.scss';
import 'react-multi-carousel/lib/styles.css';

// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import Carousel from 'react-multi-carousel';
import LeftArrow from './components/LeftArrow';
import RightArrow from './components/RightArrow';
import Video from '../Video';

export const Ribbon = ({
  allVideos,
  className,
  context,
  onScrollLeft,
  onScrollRight,
  videos,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: 14,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 23.5,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 20,
    },
  };

  return (
    <div className={`${classes.ribbonWrap} ${className}`}>
      <Carousel
        partialVisible={true}
        responsive={responsive}
        customLeftArrow={<LeftArrow onScroll={onScrollLeft} />}
        customRightArrow={<RightArrow onScroll={onScrollRight} />}
        sliderClass={classes.carousel}
      >
        {videos.map((video) => (
          <Video key={video.id} context={context} video={video} />
        ))}

        <div className={classes.video}>
          <div className={classes.inner}>
            <Link to={allVideos}>
              <FontAwesomeIcon
                className={classes.icon}
                icon={faArrowCircleRight}
              />
              <span className={classes.allLabel}>See All</span>
              <span className={classes.stretch}>&nbsp;</span>
            </Link>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Ribbon;
