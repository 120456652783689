import moment from 'moment';

export const activeTournaments = (tournaments) =>
  tournaments.filter(
    (tournament) =>
      moment(tournament.endTime).isAfter(moment()) && tournament.active
  );

export const openTournaments = (tournaments) =>
  tournaments.filter(
    (tournament) =>
      !tournament.closeTime || moment(tournament.closeTime).isAfter(moment())
  );

export const currentTournaments = (tournaments) =>
  tournaments.filter(
    (tournament) =>
      moment().isBefore(moment(tournament.endTime).add(24, 'hours')) &&
      tournament.active
  );

export const futureTournaments = (tournaments) =>
  tournaments.filter(
    (tournament) =>
      moment(tournament.endTime).isAfter(moment()) && !tournament.active
  );

export const pastTournaments = (tournaments) =>
  tournaments.filter((tournament) =>
    moment(tournament.endTime).isBefore(moment())
  );

export const currentTournament = (id, tournaments) =>
  tournaments.find((tournament) => tournament.id === id);

export const currentFilteredTournaments = (tournaments, filters = {}) => {
  // Filter for active tournaments first
  let current = tournaments
    .filter((tournament) => tournament.active)
    .filter((tournament) => moment(tournament.endTime).isAfter(moment()));

  // Apply each filter key
  Object.entries(filters).forEach(
    ([field, values]) =>
      (current = current.filter((tournament) =>
        values.length === 0 ? true : values.includes(tournament.filters[field])
      ))
  );

  return current;
};

export const divisions = (leaderboard) =>
  leaderboard.reduce(
    (acc, entry) => ({
      ...acc,
      [entry.division || 0]: [
        ...(acc[entry.division || 0] ? acc[entry.division || 0] : []),
        entry,
      ].sort((a, b) => b.userScore - a.userScore),
    }),
    {}
  );
