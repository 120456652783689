import { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';

export const pageView = trackPageView((action, prevState, nextState) => {
  const { type, route, title, ...params } = action;
  const queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const location = `${action.route}?${queryString}`;

  return { location, path: route };
});

// Events
export const challengePurchase = trackEvent((action) => ({
  category: 'Challenge',
  action: 'purchase',
  label: action.id,
  value: action.price,
  fieldsObject: {
    currency: 'USD',
    items: [
      {
        id: action.product.id,
        name: action.product.label,
        quantity: 1,
        price: action.product.price,
      },
    ],
  },
}));

export const challengeCheckout = trackEvent((action) => ({
  category: 'Challenge',
  action: 'begin_checkout',
  label: action.id,
  fieldsObject: {
    items: [
      {
        id: action.product.id,
        name: action.product.label,
        quantity: 1,
        price: action.product.price,
      },
    ],
  },
}));

export const connectTwitch = trackEvent((action) => ({
  category: 'Twitch',
  action: 'Connect',
}));

export const connectYouTube = trackEvent((action) => ({
  category: 'YouTube',
  action: 'Connect',
}));

export const joinUser = trackEvent((action) => ({
  category: 'User',
  action: 'Registration',
  value: action.id,
}));

export const updateUser = trackEvent((action) => ({
  category: 'User',
  action: 'Update Profile',
  value: action.id,
}));

export const nextVideo = trackEvent((action) => ({
  category: 'Video',
  action: 'Arrow Nav',
  value: action.fromId,
  fieldsObject: {
    navTo: action.toId,
  },
}));

export const revokeTwitch = trackEvent((action) => ({
  category: 'Twitch',
  action: 'Revoke',
}));

export const revokeYouTube = trackEvent((action) => ({
  category: 'YouTube',
  action: 'Revoke',
}));

export const scrollRibbon = trackEvent((action) => ({
  category: 'Homepage',
  action: 'Scroll Ribbon',
  label: action.ribbon,
  value: action.direction,
}));

export const submitVideo = trackEvent((action) => ({
  category: 'Video',
  action: 'Submit',
  value: action.id,
}));

export const voteVideo = trackEvent((action) => ({
  category: 'Video',
  action: 'Vote',
  value: action.id,
}));

export const watchVideo = trackEvent((action, prevState, nextState) => {
  return {
    category: 'Video',
    action: 'Watch',
    value: action.id,
    fieldsObject: {
      context: action.context,
    },
  };
});

export default {
  SET_ROUTE: pageView,
  TRACK_JOIN_USER: joinUser,
  TRACK_UPDATE_USER: updateUser,
  CONNECT_TWITCH: connectTwitch,
  CONNECT_YOUTUBE: connectYouTube,
  REVOKE_TWITCH: revokeTwitch,
  REVOKE_YOUTUBE: revokeYouTube,
  TRACK_CHALLENGE_CHECKOUT: challengeCheckout,
  TRACK_CHALLENGE_PURCHASE: challengePurchase,
  TRACK_NEXT_VIDEO: nextVideo,
  TRACK_SCROLL_RIBBON: scrollRibbon,
  TRACK_SUBMIT_VIDEO: submitVideo,
  TRACK_VOTE_VIDEO: voteVideo,
  TRACK_WATCH_VIDEO: watchVideo,
};
