// Libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import moment from 'moment';

// Style
import classes from './style.module.scss';

// Actions
import { fetchTournaments, linkEntry } from '../../redux/actions/tournaments';

// Selectors
import { currentTournament } from '../../redux/selectors/tournaments';

// Components
import { Link, useLocation, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';

export const FinalizePage = ({
  finalizing,
  success,
  tournaments,
  fetchTournaments,
  linkEntry,
}) => {
  // Routing
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  // Auth
  const user = firebase.auth().currentUser;

  // State
  const [tournament, setTournament] = useState(null);

  // Effects
  useEffect(() => {
    if (tournaments.length === 0) fetchTournaments();
    if (user) linkEntry(query.get('tournament'), query.get('entry'));
  }, []);

  useEffect(() => {
    if (tournaments.length > 0)
      setTournament(currentTournament(query.get('tournament'), tournaments));
  }, [tournaments]);

  useEffect(() => {
    if (!user)
      history.push(
        `/login?r=${encodeURIComponent(
          `/finalize?entry=${query.get('entry')}&tournament=${query.get(
            'tournament'
          )}`
        )}`
      );
  });

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Entry Successful! | You Freaking Noob</title>
      </Helmet>
      <h1>Entry Successful!</h1>

      <div className={classes.wrap}>
        {finalizing || !tournament ? (
          <Loader button={true} />
        ) : (
          <React.Fragment>
            <h2>Entry Successful!</h2>
            <p>
              Your Challenge Period begins on{' '}
              {moment(tournament.startTime).format('MMM Do, YYYY')} at{' '}
              {moment(tournament.startTime).format('h:mma')} and ends at{' '}
              {moment(tournament.endTime).format('h:mma')} on{' '}
              {moment(tournament.endTime).format('MMM Do, YYYY')}.
            </p>

            <p>
              We will track all eligible matches played during the Challenge
              Period using your Activision ID.
            </p>

            <p>
              The leaderboard can be accessed in the My Challenges section of
              your Account page.
            </p>

            <a
              className={classes.button}
              href="https://discord.com/channels/707234059172053102/707234059851661444"
            >
              Join our Discord to meet Challengers!
            </a>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export const mapStateToProps = ({ meta, tournaments }) => ({
  finalizing: meta.finalizingEntry,
  success: meta.finalizingEntrySuccess,
  tournaments,
});

export const mapDispatchToProps = (dispatch) => ({
  linkEntry: (tournament, entry) => dispatch(linkEntry(tournament, entry)),
  fetchTournaments: () => dispatch(fetchTournaments()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalizePage);
