// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Assets
import ad1 from '../../assets/ad1.jpg';
import ad2 from '../../assets/ad2.jpg';

// Components

export const AdBar = ({ className }) => {
  return (
    <div className={`${classes.adbar} ${className}`}>
      <h3>Stuff You May Like...</h3>
      <small>Advertisement</small>

      <img className={classes.placeholder} src={ad1} alt="" />
      <img className={classes.placeholder} src={ad2} alt="" />
    </div>
  );
};

export default AdBar;
