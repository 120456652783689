// Libraries
import { fork } from 'redux-saga/effects';

// Sagas
import auth from './auth';
import comments from './comments';
import contests from './contests';
import creditProducts from './creditProducts';
import leaderboard from './leaderboard';
import memberships from './memberships';
import router from './router';
import submit from './submit';
import tournaments from './tournaments';
import videos from './videos';

export default function* rootSaga() {
  yield fork(auth);
  yield fork(comments);
  yield fork(contests);
  yield fork(creditProducts);
  yield fork(leaderboard);
  yield fork(memberships);
  yield fork(router);
  yield fork(submit);
  yield fork(tournaments);
  yield fork(videos);
}
