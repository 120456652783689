export const createStripeCustomer = () => ({
  type: 'CREATE_STRIPE_CUSTOMER',
});

export const fetchMemberships = () => ({
  type: 'FETCH_MEMBERSHIPS',
});

export const setMemberships = (memberships) => ({
  type: 'SET_MEMBERSHIPS',
  memberships,
});

export const createStripeSubscription = (paymentMethod, membershipId) => ({
  type: 'CREATE_STRIPE_SUBSCRIPTION',
  paymentMethod,
  membershipId,
});
