export const fetchLeaderboard = (game, timeframe) => ({
  type: 'FETCH_LEADERBOARD',
  game,
  timeframe,
});

export const setLeaderboard = (data) => ({
  type: 'SET_LEADERBOARD',
  data,
});
