// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from './firebase';
import { createBrowserHistory } from 'history';

// Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripe = loadStripe(process.env.STRIPE_API_KEY);

// Graphql
import { ApolloProvider } from '@apollo/client';
import configureClient from './graphql';
import gql from 'graphql-tag';
const client = configureClient();

// Styles
import './style/base.scss';

// Components
import App from './components/App';
import LoadingPage from './pages/Loading';

// State
import configureStore from './redux';
import { Provider } from 'react-redux';
import { fetchUser } from './redux/actions/auth';
import { setRoute } from './redux/actions/router';
const store = configureStore();

// Render
const container = document.getElementById('app');

if (container) {
  ReactDOM.render(<LoadingPage />, container);
}

firebase.auth().onAuthStateChanged((user) => {
  client.writeQuery({
    query: gql`
      {
        user
      }
    `,
    data: { user },
  });

  store.dispatch(fetchUser(user));

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Elements stripe={stripe}>
          <App history={history} user={user} />
        </Elements>
      </Provider>
    </ApolloProvider>,
    container
  );
});
