// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Link } from 'react-router-dom';

export const Video = ({ context, video, onDelete }) => {
  return (
    <div key={video.id} className={classes.video}>
      {onDelete && (
        <button className={classes.delete} onClick={(e) => onDelete(video)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      )}
      <Link to={`/watch?v=${video.id}&c=${context}`}>
        <div className={classes.thumbWrap}>
          <img src={video.thumbnail_url} alt="" />
          <FontAwesomeIcon className={classes.playIcon} icon={faPlay} />
        </div>

        <div className={classes.meta}>
          <h3>{video.title}</h3>
          <div className={classes.lower}>
            <div className={classes.submitter}>{video.submitter.username}</div>
            <div className={classes.views}>
              <FontAwesomeIcon className={classes.icon} icon={faEye} />
              {video.views}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Video;
