// Libraries
import React from 'react';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

// Components
import Countdown from '../../../../components/Countdown';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';

export const Contest = ({ contest }) => {
  const timer = (contest) => {
    switch (contest.state) {
      case 'upcoming':
        return contest.submissionStart;
      case 'submission':
        return contest.votingStart;
      case 'voting':
      case 'complete':
        return contest.votingEnd;
    }
  };

  return (
    <div className={classes.contest}>
      <div className={classes.text}>
        <h3>
          {contest.state === 'upcoming' ? (
            <span>{contest.name}</span>
          ) : (
            <Link to={`/contest/${contest.id}`}>
              <span>&nbsp;</span>
              {contest.name}
            </Link>
          )}
        </h3>

        <div className={classes.info}>
          <small>
            <FontAwesomeIcon
              className={classes.prize}
              icon={faMoneyBillWaveAlt}
            />
            {contest.prize}
          </small>
          <small>
            {contest.state === 'complete' ? (
              <React.Fragment>
                Ended {moment(timer(contest)).format('MMMM Do, YYYY')}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Countdown type={contest.state} end={timer(contest)} />{' '}
              </React.Fragment>
            )}
          </small>
        </div>
      </div>

      {contest.state !== 'upcoming' && (
        <FontAwesomeIcon className={classes.fa} icon={faChevronRight} />
      )}
    </div>
  );
};

export default Contest;
