// Libraries
import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import firebase from '../../firebase';
import { connect } from 'react-redux';

// Actions
import { fetchOtherUser } from '../../redux/actions/auth';
import {
  fetchLatestVideos,
  fetchTrendingVideos,
  fetchUserVideos,
} from '../../redux/actions/videos';

// Selectors
import { selectVideos } from '../../redux/selectors/videos';

// Styles
import classes from './style.module.scss';

// Components
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import VideoLayout from './components/VideoLayout';

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      username
      videos {
        id
        title
        thumbnail_url
        submitter {
          id
          username
        }
        views
      }
    }
  }
`;

export const DELETE_VIDEO = gql`
  mutation($id: ID!) {
    deleteVideo(id: $id) {
      id
      title
      thumbnail_url
    }
  }
`;

export const VideosPage = ({
  type,
  user,
  videos,
  videosLoading,
  fetchLatestVideos,
  fetchTrendingVideos,
  fetchUserVideos,
  fetchOtherUser,
}) => {
  // Routing
  const { id } = useParams();

  // GraphQL
  const [deleteVideo, { loading: deleting }] = useMutation(DELETE_VIDEO, {
    onCompleted: () => {
      setDeleteTarget(null);
      refetch();
    },
  });

  // State
  const [hasMore, setHasMore] = useState(true);
  const [title, setTitle] = useState('Videos');
  // const [videos, setVideos] = useState([]);
  const [lastCount, setLastCount] = useState(0);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [fetchDispatched, setFetchDispatched] = useState(false);

  useEffect(() => {
    switch (type) {
      case 'latest':
        setTitle('Latest Videos');
        fetchLatestVideos();
        break;
      case 'trending':
        setTitle('Trending Videos');
        fetchTrendingVideos();
        break;
      case 'user':
        fetchUserVideos(id);
        break;
    }
  }, []);

  useEffect(() => {
    if (!user) fetchOtherUser(id);
  }, []);

  useEffect(() => {
    if (type === 'user' && user) setTitle(user.username);
  }, [user]);

  useEffect(() => {
    if (!videosLoading && !loadingMore && videos.length < 14) {
      setHasMore(false);
    }

    if (!videosLoading && loadingMore && videos.length > lastCount) {
      setLoadingMore(false);
      setLastCount(videos.length);
      setHasMore(videos.length % 14 === 0);
    }
  }, [videosLoading]);

  // Handlers
  const handleDelete = (e) => {
    deleteVideo({
      variables: {
        id: deleteTarget.id,
      },
    });
  };

  const handleFetchMore = () => {
    setLoadingMore(true);
    switch (type) {
      case 'latest':
        fetchLatestVideos(videos[videos.length - 1].id);
        break;
      case 'trending':
        fetchTrendingVideos(videos[videos.length - 1].id);
        break;
      case 'user':
        fetchUserVideos(id, videos[videos.length - 1].id);
        break;
    }
  };

  return videosLoading && !loadingMore ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>{title} | You Freaking Noob</title>
      </Helmet>

      <VideoLayout
        type={type}
        title={title}
        videos={videos}
        onDelete={
          type === 'user' &&
          firebase.auth().currentUser &&
          id === firebase.auth().currentUser.uid
            ? (video) => setDeleteTarget(video)
            : undefined
        }
        fetchMore={handleFetchMore}
        hasMore={hasMore}
        loading={loadingMore}
      />

      <Modal
        appElement={document.getElementById('app')}
        isOpen={!!deleteTarget}
        onRequestClose={(e) => setDeleteTarget(null)}
        className={classes.modal}
        overlayClassName={classes.overlay}
      >
        {deleteTarget && (
          <React.Fragment>
            <h2>Are you sure you want to delete {deleteTarget.title}?</h2>

            <img src={deleteTarget.thumbnail_url} alt="" />

            <div className={classes.buttonWrap}>
              <button
                className={classes.secondary}
                onClick={(e) => setDeleteTarget(null)}
              >
                Cancel
              </button>
              <button
                className={classes.primary}
                disabled={deleting}
                onClick={handleDelete}
              >
                {deleting ? 'Deleting...' : 'Delete Video'}
              </button>
            </div>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth, meta, videos }, { match, type }) => {
  const selected = selectVideos(type, videos, match && match.params.id);
  return {
    user: auth.users.filter((user) => user.id === match.params.id)[0],
    videos: selected,
    videosLoading: meta.videosLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchLatestVideos: (cursor) => dispatch(fetchLatestVideos(cursor)),
  fetchTrendingVideos: (cursor) => dispatch(fetchTrendingVideos(cursor)),
  fetchUserVideos: (id, cursor) => dispatch(fetchUserVideos(id, cursor)),
  fetchOtherUser: (id) => dispatch(fetchOtherUser(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideosPage);
