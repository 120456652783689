// Libraries
import React from 'react';

// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

export const ErrorNoUsername = ({ classes = {} }) => {
  return (
    <React.Fragment>
      <h2>Select a Username to Submit</h2>
      <Link className={classes.ctaButton} to="/account">
        Set a username <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    </React.Fragment>
  );
};

export default ErrorNoUsername;
