// Libraries
import { call, put, takeEvery } from 'redux-saga/effects';

// Actions
import { setContests, mergeContestVideos } from '../../actions/contests';
import { setContestsLoading } from '../../actions/meta';

// Utils
import sendQuery from '../../utils/sendQuery';

export const GET_CONTEST = `
  query($id: ID!) {
    contest(id: $id) {
      id
      ctaText
      hero
      name
      prize
      state
      submissionStart
      subtitle
      topVideos(limit: 10) {
        id
        submitter {
          id
          username
        }
        thumbnail_url
        votes
      }
      videos {
        id
        title
        thumbnail_url
        submitter {
          id
          username
        }
        views
      }
      votingStart
      votingEnd
    }
  }
`;

export function* fetchContest(action) {
  const result = yield call(sendQuery, GET_CONTEST, {
    id: action.id,
  });
  const { data } = yield result.json();

  yield put(setContests([data.contest]));
}

export const GET_CONTESTS = `
  query($stage: String) {
    contests(stage: $stage) {
      id
      ctaText
      name
      prize
      state
      submissionStart
      votingStart
      votingEnd
    }
  }
`;

export function* fetchContests(action) {
  yield put(setContestsLoading(true));

  const result = yield call(sendQuery, GET_CONTESTS, {
    stage: action.state,
  });
  const { data } = yield result.json();

  yield put(setContests(data.contests));
  yield put(setContestsLoading(false));
}

export const GET_MORE_CONTEST_VIDEOS = `
  query ($id: ID!, $cursor: ID) {
    contest(id: $id) {
      id
      videos(cursor: $cursor) {
        id
        title
        thumbnail_url
        submitter {
          id
          username
        }
        views
      }
    }
  }
`;

export function* fetchMoreContestVideos(action) {
  const result = yield call(sendQuery, GET_MORE_CONTEST_VIDEOS, {
    id: action.id,
    cursor: action.cursor,
  });
  const { data } = yield result.json();

  yield put(mergeContestVideos(action.id, data.contest.videos));
}

export default function* contests() {
  yield takeEvery('FETCH_CONTEST', fetchContest);
  yield takeEvery('FETCH_CONTESTS', fetchContests);
  yield takeEvery('FETCH_MORE_CONTEST_VIDEOS', fetchMoreContestVideos);
}
