// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { fetchContests } from '../../redux/actions/contests';
import { fetchHomeVideos } from '../../redux/actions/videos';

// Selectors
import {
  active,
  complete,
  submission,
  upcoming,
  voting,
} from '../../redux/selectors/contests';
import {
  contestVideos,
  latestVideos,
  trendingVideos,
} from '../../redux/selectors/videos';

// Styles
import classes from './style.module.scss';

// Components
import Contest from './components/Contest';
import Countdown from '../../components/Countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';
import { Helmet } from 'react-helmet';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import Ribbon from '../../components/Ribbon';

export const ContestsPage = ({
  activeContests,
  contests,
  complete = [],
  submission = [],
  upcoming = [],
  voting = [],
  contestsLoading,
  videosLoading,
  latestVideos,
  trendingVideos,
  fetchContests,
  fetchHomeVideos,
}) => {
  useEffect(() => {
    fetchContests();
    fetchHomeVideos();
  }, []);

  const trackScrollRibbon = () => {};

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Contests | You Freaking Noob</title>
      </Helmet>

      <div className={classes.hero}>
        <div className={classes.wrap}>
          <div className={classes.innerWrap}>
            <div className={classes.textWrap}>
              <h1>Win Money Playing Games</h1>
              <small>
                Submit your best videos to a contest and get the most votes to
                win!
              </small>
            </div>

            <div className={classes.videoWrap}>
              <div className={classes.innerVideoWrap}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/d9XIlX30bZw"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ribbons}>
        {contestsLoading || videosLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {activeContests.map(
              (contest) =>
                contest.videos &&
                contest.videos.length >= 3 && (
                  <React.Fragment key={contest.id}>
                    <h2>
                      <Link to={`/contest/${contest.id}`}>
                        HIGHLIGHT: {contest.name}{' '}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Link>
                    </h2>
                    <div className={classes.info}>
                      <div className={classes.subtitle}>
                        <FontAwesomeIcon
                          className={classes.prize}
                          icon={faMoneyBillWaveAlt}
                        />{' '}
                        {contest.prize}
                      </div>
                      <div className={classes.subtitle}>
                        <Countdown
                          type={contest.state}
                          end={
                            contest.state === 'submission'
                              ? contest.votingStart
                              : contest.votingEnd
                          }
                        />
                      </div>
                    </div>
                    <Ribbon
                      className={classes.ribbon}
                      videos={contest.videos}
                      allVideos={`/contest/${contest.id}`}
                      context="contest"
                      onScrollLeft={() =>
                        trackScrollRibbon(contest.name, 'left')
                      }
                      onScrollRight={() =>
                        trackScrollRibbon(contest.name, 'right')
                      }
                    />
                  </React.Fragment>
                )
            )}

            <h2>
              <Link to="/latest">
                LATEST SUBMISSIONS <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </h2>
            <div className={classes.subtitle}>
              Check out the most recent clips
            </div>
            <Ribbon
              className={classes.ribbon}
              videos={latestVideos}
              allVideos="/latest"
              context="latest"
              onScrollLeft={() => trackScrollRibbon('Latest', 'left')}
              onScrollRight={() => trackScrollRibbon('Latest', 'right')}
            />

            <h2>
              <Link to="/trending">
                TRENDING <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </h2>
            <div className={classes.subtitle}>
              These clips are rocketing up the charts
            </div>
            <Ribbon
              className={classes.ribbon}
              videos={trendingVideos}
              allVideos="/trending"
              context="trending"
              onScrollLeft={() => trackScrollRibbon('Trending', 'left')}
              onScrollRight={() => trackScrollRibbon('Trending', 'right')}
            />
          </React.Fragment>
        )}
      </div>

      {contests.length === 0 ? (
        <Loader>Fetching contests...</Loader>
      ) : (
        <div className={classes.contests}>
          <h2>Contests Accepting Submissions</h2>

          <small>
            Have an amazing video that you want to enter into a contest? Choose
            the contest below and submit your video. Voting starts when the
            contest goes live!
          </small>

          {submission.length > 0 ? (
            submission.map((contest) => (
              <Contest key={contest.id} contest={contest} />
            ))
          ) : (
            <div>
              There are not currently any contests accepting submissions.
            </div>
          )}

          <h2>
            Live Contests{' '}
            <small>
              <a href="https://www.youfreakingnoob.com/voting-rules">
                Voting Rules
              </a>
            </small>
          </h2>
          <small>Voting is open... Vote for your favorite videos!</small>

          {voting.length > 0 || submission.length > 0 ? (
            [...voting, ...submission].map((contest) => (
              <Contest key={contest.id} contest={contest} />
            ))
          ) : (
            <div>There are not currently any contests in the voting phase.</div>
          )}

          <h2>Upcoming Contests</h2>
          <small>
            Check out our list of upcoming contests below. Get your videos
            ready!
          </small>

          {upcoming.length > 0 ? (
            upcoming.map((contest) => (
              <Contest key={contest.id} contest={contest} />
            ))
          ) : (
            <div>Check back for upcoming contests!</div>
          )}

          <h2>Past Contests</h2>
          <small>
            Want to see our previous winners? Check out all of our past
            contests!
          </small>

          {complete.map((contest) => (
            <Contest key={contest.id} contest={contest} />
          ))}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = ({ contests, meta, videos }) => ({
  contests,
  activeContests: active(contests)
    .map((contest) => ({
      ...contest,
      videos: contestVideos(contest.id, videos),
    }))
    .sort((a, b) => {
      const stateA = a.state.toUpperCase();
      const stateB = b.state.toUpperCase();
      return stateA < stateB ? -1 : stateA > stateB ? 1 : 0;
    }),
  complete: complete(contests),
  submission: submission(contests),
  upcoming: upcoming(contests),
  voting: voting(contests),
  contestsLoading: meta.contestsLoading,
  videosLoading: meta.videosLoading,
  latestVideos: latestVideos(videos),
  trendingVideos: trendingVideos(videos),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchContests: () => dispatch(fetchContests()),
  fetchHomeVideos: () => dispatch(fetchHomeVideos()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContestsPage);
