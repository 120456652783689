export const DEFAULT_STATE = {
  joinErrors: {
    email: '',
    password: '',
    username: '',
  },
  joinSuccess: false,
  joinLoading: false,
  user: null,
  users: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        users: [...state.users, action.user],
      };
    case 'SET_JOIN_ERROR':
      return {
        ...state,
        joinErrors: {
          ...state.joinErrors,
          ...action.errors,
        },
      };
    case 'SET_JOIN_LOADING':
      return {
        ...state,
        joinLoading: action.loading,
      };
    case 'SET_JOIN_SUCCESS':
      return {
        ...state,
        joinSuccess: action.success,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.updates,
        },
      };
    default:
      return state;
  }
};
