// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import PropagateLoader from 'react-spinners/PropagateLoader';

export const LoadingPage = () => {
  return (
    <div className={classes.page}>
      <PropagateLoader color="#e8e8e8" css={{ marginLeft: '-17px' }} />
    </div>
  );
};

export default LoadingPage;
