export const addComment = (comment) => ({
  type: 'ADD_COMMENT',
  comment,
});

export const addComments = (comments) => ({
  type: 'ADD_COMMENTS',
  comments,
});

export const fetchComments = (video) => ({
  type: 'FETCH_COMMENTS',
  video,
});
