export const addVideo = (video) => ({
  type: 'ADD_VIDEO',
  video,
});

export const addVideos = (videos) => ({
  type: 'ADD_VIDEOS',
  videos,
});

export const checkNewVideos = (cursor) => ({
  type: 'CHECK_NEW_VIDEOS',
  cursor,
});

export const doVote = (id) => ({
  type: 'DO_VOTE',
  id,
});

export const fetchContestVideos = (contest) => ({
  type: 'FETCH_CONTEST_VIDEOS',
  contest,
});

export const fetchLatestVideos = (cursor) => ({
  type: 'FETCH_LATEST_VIDEOS',
  cursor,
});

export const fetchNextVideo = (id, context) => ({
  type: 'FETCH_NEXT_VIDEO',
  id,
  context,
});

export const fetchPreviousVideo = (id, context) => ({
  type: 'FETCH_PREVIOUS_VIDEO',
  id,
  context,
});

export const fetchTrendingVideos = (cursor) => ({
  type: 'FETCH_TRENDING_VIDEOS',
  cursor,
});

export const fetchUserVideos = (id, cursor) => ({
  type: 'FETCH_USER_VIDEOS',
  id,
  cursor,
});

export const fetchVideo = (id, context) => ({
  type: 'FETCH_VIDEO',
  id,
  context,
});

export const fetchHomeVideos = () => ({
  type: 'FETCH_HOME_VIDEOS',
});

export const prependLatest = (video) => ({
  type: 'PREPEND_LATEST',
  video,
});

export const setContestVideos = (contest, videos) => ({
  type: 'SET_CONTEST_VIDEOS',
  contest,
  videos,
});

export const setLastVideo = (video) => ({
  type: 'SET_LAST_VIDEO',
  video,
});

export const setLatestVideos = (videos) => ({
  type: 'SET_LATEST_VIDEOS',
  videos,
});

export const setRefetch = (refetch) => ({
  type: 'SET_REFETCH',
  refetch,
});

export const setTrendingVideos = (videos) => ({
  type: 'SET_TRENDING_VIDEOS',
  videos,
});

export const setVideo = (video) => ({
  type: 'SET_VIDEO',
  video,
});

export const updateVideo = (id, updates) => ({
  type: 'UPDATE_VIDEO',
  id,
  updates,
});
