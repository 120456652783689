// Libraries
import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import firebase from '../../firebase';
import { connect } from 'react-redux';
import moment from 'moment';

// Styles
import classes from './style.module.scss';
import 'react-toastify/dist/ReactToastify.css';

// Assets
import noobCredits from '../../assets/noob-credits-white.png';

// Actions
import { trackUpdateUser } from '../../redux/actions/analytics';
import {
  completeCreditPurchase,
  fetchCreditProducts,
} from '../../redux/actions/creditProducts';
import { updateUser } from '../../redux/actions/auth';

// Components
import BirthdayInput from '../../components/BirthdayInput';
import BuyModal from '../../components/BuyModal';
import { Helmet } from 'react-helmet';
import Loader from '../../components/Loader';
import LinkContentSource from '../../components/LinkContentSource';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Link, Redirect } from 'react-router-dom';

export const GET_USER_DATA = gql`
  query {
    me {
      id
      username
      activisionId
      steamProfile
      discordTag
      bnetProfile
      biography
      birthday
      paypalEmail
      venmoUser

      twitchLinked
      youtubeLinked

      referralCount

      subscriptions {
        id
        membership {
          id
          name
        }
      }

      tournaments {
        id
        name
      }
      videoCount
      views
    }
  }
`;

export const CHECK_USERNAME = gql`
  query($username: String!) {
    usernameExists(username: $username)
    validUsername(username: $username)
  }
`;

export const UPDATE_USER = gql`
  mutation(
    $activisionId: String
    $steamProfile: String
    $discordTag: String
    $bnetProfile: String
    $biography: String
    $birthday: String
    $paypalEmail: String
    $venmoUser: String
    $username: String
  ) {
    updateUser(
      activisionId: $activisionId
      steamProfile: $steamProfile
      discordTag: $discordTag
      bnetProfile: $bnetProfile
      biography: $biography
      birthday: $birthday
      paypalEmail: $paypalEmail
      venmoUser: $venmoUser
      username: $username
    ) {
      id
      username
      steamProfile
      discordTag
      bnetProfile
      biography
      birthday
      paypalEmail
      venmoUser
    }
  }
`;

export const AccountPage = ({
  creditProducts,
  user,
  userLoading,
  completeCreditPurchase,
  fetchCreditProducts,
  trackUpdateUser,
  updateLocalUser,
}) => {
  // GraphQL
  const { loading, error, data, refetch } = useQuery(GET_USER_DATA);

  const [
    checkUsername,
    { loading: checkingUsername, data: usernameData },
  ] = useLazyQuery(CHECK_USERNAME, {
    onCompleted: (data) => {
      if (data.usernameExists) {
        setFormErrors({
          ...formErrors,
          username: 'Username is already in use',
        });
      }

      if (!data.validUsername) {
        setFormErrors({
          ...formErrors,
          username:
            'Username contains a banned word, please choose something different.',
        });
      }
    },
  });

  const [updateUser, { loading: submitting, error: submitError }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: () => {
        trackUpdateUser(data.me.id);
        toast('Profile updated!', {
          className: classes.toast,
          bodyClassName: classes.toastBody,
          progressClassName: classes.toastProgress,
        });
      },
    }
  );

  // State
  const [formState, setFormState] = useState({
    username: '',
    activisionId: '',
    steamProfile: '',
    discordTag: '',
    biography: '',
    birthday: '',
    paypalEmail: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
  });

  const [showHistory, setShowHistory] = useState(false);
  const [showBuy, setShowBuy] = useState(false);

  // Effects
  useEffect(() => {
    if (data) {
      setFormState({
        ...formState,
        ...data.me,
      });
    }
  }, [data]);

  useEffect(() => {
    fetchCreditProducts();
  }, []);

  // Handlers
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.id]: '',
    });
  };

  const handleCheckUsername = (e) => {
    checkUsername({
      variables: {
        username: formState.username,
      },
    });
  };

  const handleCopy = (e) => {
    e.preventDefault();

    navigator.clipboard.writeText(
      `https://app.youfreakingnoob.com/r/${data.me.id}`
    );
  };

  const handlePayment = (id, type) => {
    completeCreditPurchase(id, type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset password if those fields were filled
    if (formState.password && formState.newPass && formState.confirm) {
      try {
        const user = firebase.auth().currentUser;

        // Reauth
        const credential = await firebase
          .auth()
          .signInWithEmailAndPassword(user.email, formState.password);

        // Reset password
        await user.updatePassword(formState.newPass);

        toast('Password successfully reset!', {
          className: classes.toast,
          bodyClassName: classes.toastBody,
          progressClassName: classes.toastProgress,
        });
        setFormState({
          ...formState,
          password: '',
          newPass: '',
          confirm: '',
        });
      } catch (e) {
        console.error(e);
      }
    }

    // Trigger update user mutation
    updateUser({
      variables: {
        ...formState,
      },
    });
    updateLocalUser(formState);
  };

  // DEBUG
  const tournaments = [
    {
      id: 'i8jypzTex3UQz5WghDZe',
      name: 'Test Tournament',
    },
  ];

  if (userLoading) return <Loader />;
  if (!userLoading && !user) return <Redirect to="/login" />;

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Account | You Freaking Noob</title>
      </Helmet>
      <ToastContainer />
      <h1>Account</h1>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.wrapLeft}>
            <div className={classes.formWrap}>
              <h2>Link Video Sources</h2>
              <LinkContentSource
                twitch={data.me.twitchLinked}
                youtube={data.me.youtubeLinked}
              />
            </div>

            <div className={classes.formWrap}>
              <h2>Profile</h2>

              <form className={classes.form} onSubmit={handleSubmit}>
                <div
                  className={`${classes.group} ${
                    formErrors.username ? classes.error : ''
                  }`}
                >
                  <label htmlFor="username">
                    Username <span className={classes.required}>*</span>
                  </label>
                  <input
                    type="text"
                    id="username"
                    placeholder="CoolUsername"
                    onChange={handleChange}
                    onBlur={handleCheckUsername}
                    value={formState.username}
                  />
                  {formErrors.username && (
                    <div className={classes.error}>{formErrors.username}</div>
                  )}
                </div>
                <div className={classes.group}>
                  <label htmlFor="activisionId">Player ID</label>
                  <input
                    type="text"
                    id="activisionId"
                    placeholder="Noobz#123 (*ID is NOT your Gamertag)"
                    onChange={handleChange}
                    value={formState.activisionId}
                  />
                </div>
                <div className={classes.group}>
                  <label htmlFor="steamProfile">Steam Profile</label>
                  <input
                    type="text"
                    id="steamProfile"
                    placeholder="https://steamcommunity.com/profiles/12345678901234567"
                    onChange={handleChange}
                    value={formState.steamProfile}
                  />
                </div>
                <div className={classes.group}>
                  <label htmlFor="bnetProfile">BattleTag (Battle.net)</label>
                  <input
                    type="text"
                    id="bnetProfile"
                    placeholder="SomeUser#1234"
                    onChange={handleChange}
                    value={formState.bnetProfile}
                  />
                </div>
                <div className={classes.group}>
                  <label htmlFor="discordTag">Discord Tag</label>
                  <input
                    type="text"
                    id="discordTag"
                    placeholder="SomeUser#1234"
                    onChange={handleChange}
                    value={formState.discordTag}
                  />
                </div>
                <div className={classes.group}>
                  <label htmlFor="biography">Profile Bio</label>
                  <textarea
                    id="biography"
                    placeholder="I usually play..."
                    onChange={handleChange}
                    value={formState.biography}
                  />
                </div>

                <div className={classes.group}>
                  <label htmlFor="birthday">Birthday</label>
                  <BirthdayInput
                    id="birthday"
                    onChange={handleChange}
                    value={formState.birthday}
                  />
                </div>
                <button>Save</button>
              </form>
            </div>

            <div className={classes.formWrap}>
              <h2>Payment Info</h2>
              <small>How we pay you when you win</small>

              <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.group}>
                  <label htmlFor="paypalEmail">Paypal Email</label>
                  <input
                    type="email"
                    id="paypalEmail"
                    placeholder="me@example.com"
                    onChange={handleChange}
                    value={formState.paypalEmail}
                  />
                </div>

                {/*<div className={classes.group}>
                  <label htmlFor="venmoUser">Venmo Username</label>
                  <input
                    type="text"
                    id="venmoUser"
                    placeholder="Username"
                    onChange={handleChange}
                    value={formState.venmoUser}
                  />
                </div>*/}
                <button>Save</button>
              </form>
            </div>

            <div className={classes.formWrap}>
              <h2>Reset Password</h2>

              <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.group}>
                  <label htmlFor="password">Current Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your current password"
                    onChange={handleChange}
                    value={formState.password}
                  />
                </div>

                <div className={classes.group}>
                  <label htmlFor="newPass">New Password</label>
                  <input
                    type="password"
                    id="newPass"
                    placeholder="Choose a new password"
                    onChange={handleChange}
                    value={formState.newPass}
                  />
                </div>

                <div className={classes.group}>
                  <label htmlFor="confirm">Confirm New Password</label>
                  <input
                    type="password"
                    id="confirm"
                    placeholder="Enter your new password again"
                    onChange={handleChange}
                    value={formState.confirm}
                  />
                </div>

                <button>Save</button>
              </form>
            </div>
          </div>

          <div className={classes.wrapRight}>
            <div className={`${classes.formWrap} ${classes.stats}`}>
              <div className={classes.row}>
                <div className={classes.stat}>
                  <div className={classes.label}>Video Submissions</div>
                  <div className={classes.value}>{data.me.videoCount}</div>
                </div>
                <div className={classes.stat}>
                  <div className={classes.label}>Total Video Views</div>
                  <div className={classes.value}>{data.me.views}</div>
                </div>
                {/*<div className={classes.stat}>
                  <div className={classes.label}>Total Votes Received</div>
                  <div className={classes.value}>179</div>
                </div>*/}
              </div>
            </div>

            <div className={`${classes.formWrap} ${classes.stats}`}>
              <div className={classes.row}>
                <div className={classes.stat}>
                  <div className={classes.value}>
                    <span>{user.creditTotal}</span>
                    <img src={noobCredits} alt="Noob Credits" />
                  </div>
                </div>
              </div>
              {/*<div className={classes.row}>
                <button
                  className={classes.button}
                  onClick={() => setShowBuy(true)}
                >
                  Buy <img src={noobCredits} alt="noob credits" />
                </button>
              </div>*/}
              <div className={classes.row}>
                <button
                  className={classes.history}
                  onClick={() => setShowHistory(true)}
                >
                  See history
                </button>
              </div>

              <BuyModal
                isOpen={showBuy}
                onRequestClose={() => setShowBuy(false)}
                products={creditProducts}
                onPaymentComplete={handlePayment}
              />

              <Modal
                appElement={document.getElementById('app')}
                isOpen={showHistory}
                onRequestClose={() => setShowHistory(false)}
                className={classes.modal}
                overlayClassName={classes.overlay}
              >
                <button
                  className={classes.close}
                  onClick={() => setShowHistory(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2>Noob Credit History</h2>

                <table>
                  <tr className={classes.header}>
                    <th>Date</th>
                    <th>Details</th>
                    <th className={classes.amount}>Amount</th>
                  </tr>

                  {user.credits.length > 0 ? (
                    user.credits.map(({ date, amount, note }) => (
                      <tr>
                        <td>{moment(date).format('YYYY-MM-DD')}</td>
                        <td>{note}</td>
                        <td className={classes.amount}>{amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="3" className={classes.empty}>
                        No transactions yet
                      </td>
                    </tr>
                  )}
                </table>
              </Modal>
            </div>

            {data.me.username && (
              <div className={`${classes.formWrap} ${classes.linkBox}`}>
                <h2>
                  <Link to={`/user/${data.me.id}`}>
                    <div className={classes.innerWrap}>
                      <div>My Videos</div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <span>&nbsp;</span>
                  </Link>
                </h2>
              </div>
            )}

            {user && (
              <div className={classes.formWrap}>
                <h2>My Subscriptions</h2>
                {user.subscriptions.length > 0 ? (
                  <ul>
                    {user.subscriptions.map((sub) => (
                      <li>
                        {sub.membership.name}
                        <small>
                          (
                          <a
                            href="mailto:support@youfreakingnoob.com?subject=Cancel Membership"
                            target="_blank"
                          >
                            cancel
                          </a>
                          )
                        </small>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>
                    {/*You are not currently subscribed to yFn,{' '}
                    <Link to="/subscription">subscribe now!</Link>*/}
                    Coming soon!
                  </p>
                )}
              </div>
            )}

            {data.me.tournaments.length > 0 && (
              <div className={classes.formWrap}>
                <h2>My Tournaments</h2>
                <ul>
                  {data.me.tournaments.map((tournament) => (
                    <li>
                      <Link to={`/tournament/${tournament.id}`}>
                        {tournament.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className={`${classes.formWrap} ${classes.stats}`}>
              <h2>My Referrals</h2>
              <p>Earn rewards for referring your squad to YouFreakingNoob</p>

              <form className={`${classes.form} ${classes.copyWrap}`}>
                <label htmlFor="refLink">
                  Share this URL{' '}
                  <button
                    className={classes.copyButton}
                    onClick={handleCopy}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </label>
                <textarea
                  className={classes.refLink}
                  type="text"
                  value={`https://app.youfreakingnoob.com/r/${data.me.id}`}
                  readonly
                  onClick={(e) => e.target.select()}
                />
              </form>

              <div className={classes.row}>
                <div className={classes.stat}>
                  <div className={classes.label}>Referrals</div>
                  <div className={classes.value}>{data.me.referralCount}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = ({ auth, creditProducts, meta }) => ({
  creditProducts,
  user: auth.user,
  userLoading: meta.userLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  completeCreditPurchase: (id, type) =>
    dispatch(completeCreditPurchase(id, type)),
  fetchCreditProducts: () => dispatch(fetchCreditProducts()),
  trackUpdateUser: (id) => dispatch(trackUpdateUser(id)),
  updateLocalUser: (updates) => dispatch(updateUser(updates)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPage);
