// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

export const RightArrow = ({ onClick, onScroll }) => {
  const handleScroll = (e) => {
    onScroll();
    onClick(e);
  };

  return (
    <button className={classes.arrow} onClick={handleScroll}>
      <FontAwesomeIcon icon={faChevronRight} />
    </button>
  );
};

export default RightArrow;
