// Libraries
import React from 'react';

// Components
import LinkContentSource from '../../../LinkContentSource';

export const ErrorNoContentSource = () => {
  return (
    <React.Fragment>
      <h2>Link an Account to Submit</h2>
      <LinkContentSource />
    </React.Fragment>
  );
};

export default ErrorNoContentSource;
