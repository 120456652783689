// Libraries
import React, { useState, useEffect } from 'react';
import firebase from '../firebase';

export default () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(firebase.auth().currentUser);
  });

  return user;
};
