// Libraries
import { call, put, takeEvery } from 'redux-saga/effects';

// Actions
import { updateVideo } from '../../actions/videos';

// Utils
import sendQuery from '../../utils/sendQuery.js';

export const TRACK_VIEW = `
  mutation($videoId: ID!) {
    trackView(videoId: $videoId) {
      id
      views
    }
  }
`;

export function* trackView(action) {
  if (action.route === '/watch') {
    const result = yield call(sendQuery, TRACK_VIEW, {
      videoId: action.v,
    });
    const { data } = yield result.json();

    fbq('track', 'PageView');

    // yield put(updateVideo(data.trackView));
  }
}

export default function* router() {
  yield takeEvery('SET_ROUTE', trackView);
}
