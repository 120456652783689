// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Assets
import facebookLogo from '../../../../assets/facebook-wordmark-white.png';
import twitchGlitch from '../../../../assets/twitch-glitch-white.png';
import twitchLogo from '../../../../assets/twitch-wordmark-white.png';
import youtubeLogo from '../../../../assets/yt_logo_mono_dark.png';

// Actions
import {
  fetchFacebook,
  fetchTwitch,
  fetchYouTube,
} from '../../../../redux/actions/submit';

// Components
import ClipList from '../ClipList';
import Loader from '../../../../components/Loader';
import LinkContentSource from '../../../../components/LinkContentSource';

export const Step1 = ({
  classes = {},
  clips,
  cursors,
  initial,
  loading,
  more,
  facebookLinked,
  twitchLinked,
  youtubeLinked,
  currentStep,
  onSubmit,
  setClip,
}) => {
  // Short circuit component if not on the right step
  if (currentStep !== 1) return null;

  // State
  const [currentTab, setCurrentTab] = useState('twitch');

  // Effects
  useEffect(() => {
    if (facebookLinked) return setCurrentTab('facebook');
    if (twitchLinked) return setCurrentTab('twitch');
    if (youtubeLinked) return setCurrentTab('youtube');
  }, [facebookLinked, twitchLinked, youtubeLinked]);

  // Handlers
  const handleMore = (e) => {
    e.preventDefault();

    more[currentTab](cursors[currentTab]);
  };

  const handleSelect = (clip) => {
    setClip(clip);
    onSubmit();
  };

  return (
    <div className={classes.step}>
      <h2>1. Select Clip</h2>
      {!initial[currentTab] ? (
        <Loader button={true} />
      ) : (
        <React.Fragment>
          <div className={classes.tabs}>
            {/* Facebook */}
            {facebookLinked && (
              <button
                className={`${classes.tab} ${
                  currentTab === 'facebook' ? classes.active : ''
                }`}
                onClick={(e) => setCurrentTab('facebook')}
              >
                <img src={facebookLogo} alt="facebook" />
              </button>
            )}

            {/* Twitch */}
            {twitchLinked && (
              <button
                className={`${classes.tab} ${
                  currentTab === 'twitch' ? classes.active : ''
                }`}
                onClick={(e) => setCurrentTab('twitch')}
              >
                <img src={twitchLogo} alt="Twitch" />
              </button>
            )}

            {/* YouTube */}
            {youtubeLinked && (
              <button
                className={`${classes.tab} ${
                  currentTab === 'youtube' ? classes.active : ''
                }`}
                onClick={(e) => setCurrentTab('youtube')}
              >
                <img src={youtubeLogo} alt="YouTube" />
              </button>
            )}
          </div>

          <ClipList
            clips={clips[currentTab]}
            loading={loading[currentTab]}
            hasMore={cursors[currentTab]}
            requestMore={handleMore}
            onSelect={handleSelect}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export const mapStateToProps = ({ auth, submit }) => ({
  clips: {
    facebook: submit.facebook ? submit.facebook.clips : [],
    twitch: submit.twitch ? submit.twitch.clips : [],
    youtube: submit.youtube ? submit.youtube.clips : [],
  },
  cursors: {
    facebook: submit.facebook && submit.facebook.cursor,
    twitch: submit.twitch && submit.twitch.cursor,
    youtube: submit.youtube && submit.youtube.cursor,
  },
  loading: {
    facebook: submit.facebookLoading,
    twitch: submit.twitchLoading,
    youtube: submit.youtubeLoading,
  },
  initial: {
    facebook: submit.facebookInitial,
    twitch: submit.twitchInitial,
    youtube: submit.youtubeInitial,
  },
  facebookLinked: auth.user && auth.user.facebookLinked,
  twitchLinked: auth.user && auth.user.twitchLinked,
  youtubeLinked: auth.user && auth.user.youtubeLinked,
});

export const mapDispatchToProps = (dispatch) => ({
  more: {
    facebook: (cursor) => dispatch(fetchFacebook(cursor)),
    twitch: (cursor) => dispatch(fetchTwitch(cursor)),
    youtube: (cursor) => dispatch(fetchYouTube(cursor)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
