// Libraries
import React from 'react';

export const TournamentSelect = ({
  tournaments,
  value,
  onChange,
  disabled,
}) => {
  return (
    <select value={value} onChange={onChange} disabled={disabled}>
      <option value="" disabled hidden>
        Select a tournament...
      </option>
      {tournaments.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default TournamentSelect;
