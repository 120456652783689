// Libraries
import React from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

// Components
import Loader from '../../components/Loader';

export const AffiliatePage = () => {
  const history = useHistory();
  const { id } = useParams();
  document.cookie = `referrer=${id};path='/';expires=${moment()
    .add(30, 'days')
    .toDate()
    .toUTCString()}`;

  history.replace(`/register?promo=${id}`);

  return <Loader />;
};

export default AffiliatePage;
