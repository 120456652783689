// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// Styles
import classes from './style.module.scss';

// Actions
import {
  fetchContest,
  fetchMoreContestVideos,
} from '../../redux/actions/contests';

// Components
import Countdown from '../../components/Countdown';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import VideoList from '../../components/VideoList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

export const ContestPage = ({
  contest,
  fetchContest,
  fetchMoreContestVideos,
  user,
}) => {
  // State
  const [videoCount, setVideoCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  // Hooks
  const params = useParams();

  useEffect(() => {
    fetchContest(params.id);
  }, []);

  useEffect(() => {
    if (contest.videos && loading) {
      if (contest.videos.length % 14 !== 0) setHasMore(false);
      else setHasMore(videoCount !== contest.videos.length);

      setVideoCount(contest.videos.length);
      setLoading(false);
    }
  }, [contest]);

  // Handlers
  const handleFetchMore = (e) => {
    setLoading(true);
    fetchMoreContestVideos(
      params.id,
      contest.videos[contest.videos.length - 1].id
    );
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>{contest.name || 'Contest'} | You Freaking Noob</title>
      </Helmet>
      <div
        className={classes.hero}
        style={{
          backgroundImage: `url("${contest.hero}")`,
        }}
      >
        <div className={classes.wrap}>
          <div className={classes.innerWrap}>
            <h1>{contest.name}</h1>
            <small className={classes.subtitle}>{contest.subtitle}</small>
            {user ? (
              <Link to="/submit" className={classes.ctaButton}>
                {contest.ctaText ? contest.ctaText : 'Upload Here'}{' '}
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            ) : (
              <Link to="/register" className={classes.ctaButton}>
                {contest.ctaText ? contest.ctaText : 'Upload Here'}{' '}
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )}
          </div>
        </div>
      </div>

      {contest.state === 'complete' && (
        <div className={classes.leaderboard}>
          <h2>Leaderboard</h2>
          <ol>
            {contest.topVideos &&
              contest.topVideos.map((video, i) => (
                <li key={video.id} className={classes.video}>
                  <div className={classes.place}>{i + 1}.</div>
                  <div className={classes.info}>
                    <div className={classes.user}>
                      {video.submitter.username}
                    </div>
                    {/*<div className={classes.votes}>{video.votes} Votes</div>*/}
                  </div>
                  <div className={classes.thumbnail}>
                    <Link to={`/watch?v=${video.id}&c=contest`}>
                      <img src={video.thumbnail_url} alt="" />
                      <div className={classes.play}>
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </Link>
                  </div>
                </li>
              ))}
          </ol>
        </div>
      )}

      {contest.state === 'submission' && (
        <div className={classes.countdown}>
          <div className={classes.textWrap}>
            <div className={classes.prize}>
              <FontAwesomeIcon icon={faMoneyBillWaveAlt} /> {contest.prize}
            </div>
            <div className={classes.countdownInner}>
              <Countdown type={contest.state} end={contest.votingStart} />
            </div>
          </div>
          {/*<Link to={`/submit/${contest.id}`}>Submit Video</Link>*/}
        </div>
      )}

      {contest.state === 'voting' && (
        <div className={classes.countdown}>
          <div>
            <Countdown type={contest.state} end={contest.votingEnd} />
          </div>
        </div>
      )}

      <div className={classes.videoWrap}>
        <VideoList
          type="contest"
          loading={loading}
          videos={contest.videos}
          onDelete={null}
          fetchMore={handleFetchMore}
          hasMore={hasMore}
        >
          There have not been any submissions to this contest.
        </VideoList>

        {false && (
          <button onClick={handleFetchMore}>
            {loading ? <Loader button={true} /> : 'Load more...'}
          </button>
        )}
      </div>
    </div>
  );
};

export const mapStateToProps = ({ auth, contests }, { match }) => {
  const contest =
    contests.filter((contest) => contest.id === match.params.id)[0] || {};

  return {
    contest,
    user: auth.user,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchContest: (id) => dispatch(fetchContest(id)),
  fetchMoreContestVideos: (id, cursor) =>
    dispatch(fetchMoreContestVideos(id, cursor)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContestPage);
