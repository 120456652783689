// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

// Actions
import {
  createStripeCustomer,
  createStripeSubscription,
  fetchMemberships,
} from '../../redux/actions/memberships';

// Styles
import cardStyle from '../../components/CheckoutForm/style.js';
import classes from './style.module.scss';

// Components
import Loader from '../../components/Loader';
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export const SubscriptionPage = ({
  customer,
  history,
  loading,
  match,
  membership,
  memberships,
  subscriptionCheckoutLoading,
  subscriptionLoading,
  subscriptionSuccess,
  subscriptionError,
  user,
  userLoading,
  fetchMemberships,
  createStripeCustomer,
  createSubscription,
}) => {
  // Hooks
  const elements = useElements();
  const stripe = useStripe();

  // Effects
  useEffect(() => {
    if (!userLoading && !user)
      history.replace(
        `/login?r=${encodeURIComponent(
          membership ? `/subscription/${membership.id}` : '/subscription'
        )}`
      );
  }, [user, userLoading]);

  useEffect(() => {
    if (memberships.length === 0) fetchMemberships();
  }, [memberships]);

  useEffect(() => {
    if (customer) setCheckout(true);
  }, [customer]);

  useEffect(() => {
    if (subscriptionLoading) setSubscribeLoading(false);
  }, [subscriptionLoading]);

  // State
  const [subscription, setSubscription] = useState(match.params.id || '');

  const [checkout, setCheckout] = useState(false);

  const [coupon, setCoupon] = useState('');
  const [cardError, setCardError] = useState('');

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [upsellOpen, setUpsellOpen] = useState(false);
  const [upsellAttempted, setUpsellAttempted] = useState(false);

  // Handlers
  const handleSubscription = (e) => {
    setSubscription(e.target.value);
    history.replace(`/subscription/${e.target.value}`);
  };

  const handleSelectionSubmit = (e) => {
    e.preventDefault();

    // Setup stripe customer
    createStripeCustomer();
  };

  const handleCardInput = (e) => {
    setCardError('');
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    // Set subscription button to loading state
    setSubscribeLoading(true);

    // Create PaymentMethod
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: user.username,
        email: user.email,
      },
    });

    // Handle results errors
    if (result.error) {
      setPaymentMethodError(result.error.message);
      setSubscribeLoading(false);
      return;
    }

    // Set the payment method
    setPaymentMethod(result.paymentMethod);

    // If there is an upsell option, open modal
    if (membership.upsellId && !upsellAttempted) {
      setUpsellAttempted(true);
      setUpsellOpen(true);

      setTimeout(() => setSubscribeLoading(false), 500);
    } else {
      processTransaction(membership.id);
    }
  };

  const processTransaction = async (membershipId) => {
    // Close upsell modal
    setSubscribeLoading(true);
    setUpsellOpen(false);

    // Change membership info in the background
    history.replace(`/subscription/${membershipId}`);

    // Send PaymentMethod ID to the server
    createSubscription(paymentMethod.id, membershipId);
  };

  return (
    <div className={classes.page}>
      <div className={classes.wrap}>
        <h1>Join Today!</h1>

        <div className={classes.formWrap}>
          {loading || memberships.length === 0 ? (
            <Loader />
          ) : (
            <React.Fragment>
              {!subscriptionSuccess ? (
                <React.Fragment>
                  <form
                    className={classes.form}
                    onSubmit={handleSelectionSubmit}
                  >
                    <div className={`${classes.group}`}>
                      <label htmlFor="subscription" className={classes.header}>
                        Select a Subscription
                      </label>
                      <select
                        id="subscription"
                        value={subscription}
                        onChange={handleSubscription}
                        disabled={checkout}
                      >
                        <option value="" readOnly hidden>
                          Select a Subscription...
                        </option>
                        {memberships
                          .filter((m) => !m.hidden)
                          .map((membership) => (
                            <option key={membership.id} value={membership.id}>
                              {membership.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    {membership && (
                      <React.Fragment>
                        <div
                          className={classes.benefits}
                          dangerouslySetInnerHTML={{
                            __html: membership.benefitsHtml,
                          }}
                        />

                        <div className={classes.fee}>
                          <span>Monthly Fee</span>
                          <span>{membership.price}</span>
                        </div>

                        <div
                          className={classes.coupon}
                          dangerouslySetInnerHTML={{
                            __html: membership.couponHtml,
                          }}
                        />

                        {!checkout && (
                          <button disabled={subscriptionCheckoutLoading}>
                            {subscriptionCheckoutLoading ? (
                              <Loader button={true} />
                            ) : (
                              'Checkout'
                            )}
                          </button>
                        )}
                      </React.Fragment>
                    )}
                  </form>

                  {checkout && (
                    <React.Fragment>
                      <h2>Payment Info</h2>

                      <form
                        className={classes.form}
                        onSubmit={handlePaymentSubmit}
                      >
                        {subscriptionError ||
                          (paymentMethodError && (
                            <div className={classes.error}>
                              {subscriptionError || paymentMethodError}
                            </div>
                          ))}

                        <div className={classes.cardWrap}>
                          <CardElement
                            id="card-element"
                            options={cardStyle}
                            onChange={handleCardInput}
                          />
                        </div>

                        <button
                          disabled={subscriptionLoading || subscribeLoading}
                        >
                          {subscriptionLoading || subscribeLoading ? (
                            <Loader button={true} />
                          ) : (
                            'Subscribe Now'
                          )}
                        </button>
                      </form>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <div className={classes.successMessage}>
                  <h2>{membership.thankYouHeaderText}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: membership.thankYouHtml,
                    }}
                  />
                  <a
                    className={classes.button}
                    href={membership.thankYouButtonUrl}
                  >
                    {membership.thankYouButtonText}
                  </a>
                </div>
              )}

              <Modal
                appElement={document.getElementById('app')}
                isOpen={upsellOpen}
                onRequestClose={() => processTransaction(membership.id)}
                className={classes.modal}
                overlayClassName={classes.overlay}
              >
                {membership ? (
                  <React.Fragment>
                    <h2>{membership.upsellHeading}</h2>

                    <div
                      className={classes.upsellContent}
                      dangerouslySetInnerHTML={{
                        __html: membership.upsellHtml,
                      }}
                    />

                    <button
                      onClick={() => processTransaction(membership.upsellId)}
                    >
                      {membership.upsellAccept}
                    </button>
                    <button
                      className={classes.link}
                      onClick={() => processTransaction(membership.id)}
                    >
                      {membership.upsellDecline}
                    </button>
                  </React.Fragment>
                ) : (
                  <Loader />
                )}
              </Modal>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = ({ auth, memberships, meta }, { match }) => ({
  customer: auth.user ? auth.user.stripeCustomer : '',
  loading: meta.membershipsLoading,
  memberships,
  membership:
    memberships && match.params.id
      ? memberships.find((membership) => membership.id === match.params.id)
      : null,
  subscriptionCheckoutLoading: meta.subscriptionCheckoutLoading,
  subscriptionLoading: meta.subscriptionLoading,
  subscriptionSuccess: meta.subscriptionSuccess,
  subscriptionError: meta.subscriptionError,
  user: auth.user,
  userLoading: meta.userLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  createStripeCustomer: () => dispatch(createStripeCustomer()),
  createSubscription: (paymentMethod, membershipId) =>
    dispatch(createStripeSubscription(paymentMethod, membershipId)),
  fetchMemberships: () => dispatch(fetchMemberships()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPage);
