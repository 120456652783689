// Libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

// Components
import Countdown from '../../../../components/Countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { Link } from 'react-router-dom';

export const Tournament = ({
  id,
  active,
  name,
  startTime,
  endTime,
  closeTime,
  prizePool,
  scoreMetric,
  numMatches,
  userTournament,
  hasMinimumEntries,
  full,
  limit,
  remaining,
  privateMatch,
  alternateMetrics,
}) => {
  const [countdown, setCountdown] = useState(true);

  return (
    <li className={classes.tournament}>
      <div className={classes.meta}>
        <div className={classes.row}>
          <h3>{name}</h3>
        </div>
        <div className={classes.row}>
          <div className={classes.prize}>
            <FontAwesomeIcon
              icon={faMoneyBillWaveAlt}
              className={classes.icon}
            />
            {prizePool}
          </div>

          {moment().isAfter(moment(endTime)) ? (
            <div />
          ) : moment().isAfter(moment(startTime)) ? (
            <div className={classes.views}>
              <FontAwesomeIcon
                icon={faFire}
                className={`${classes.icon} ${classes.red}`}
              />{' '}
              Happening Now!
            </div>
          ) : moment().isBetween(
              moment(closeTime).subtract(12, 'hours'),
              moment(closeTime)
            ) ? (
            <React.Fragment>
              {active ? (
                <React.Fragment>
                  {userTournament ? (
                    <div className={classes.views}>
                      <FontAwesomeIcon
                        icon={faFire}
                        className={`${classes.icon} ${classes.red}`}
                      />{' '}
                      Starting in{' '}
                      <Countdown
                        end={startTime}
                        icon={false}
                        format={'HH:MM:SS'}
                        onComplete={() => setCountdown(false)}
                      />
                    </div>
                  ) : (
                    <div className={classes.views}>
                      <FontAwesomeIcon
                        icon={faFire}
                        className={`${classes.icon} ${classes.red}`}
                      />{' '}
                      Registration closes in{' '}
                      <Countdown
                        end={closeTime}
                        icon={false}
                        format={'HH:MM:SS'}
                        onComplete={() =>
                          setTimeout(() => setCountdown(false), 0)
                        }
                      />
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div className={classes.views}>
                  <FontAwesomeIcon
                    icon={faFire}
                    className={`${classes.icon} ${classes.red}`}
                  />{' '}
                  Starting in{' '}
                  <Countdown
                    end={startTime}
                    icon={false}
                    format={'HH:MM:SS'}
                    onComplete={() => setCountdown(false)}
                  />
                </div>
              )}
            </React.Fragment>
          ) : moment().isBetween(
              moment(startTime).subtract(24, 'hours'),
              moment(startTime)
            ) ? (
            <div className={classes.views}>
              <FontAwesomeIcon
                icon={faFire}
                className={`${classes.icon} ${classes.red}`}
              />{' '}
              Starting in{' '}
              <Countdown
                end={startTime}
                icon={false}
                format={'HH:MM:SS'}
                onComplete={() => setCountdown(false)}
              />
            </div>
          ) : (
            <React.Fragment>
              {active ? (
                <div />
              ) : (
                <div className={classes.views}>Registration not open yet</div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classes.row}>
          {privateMatch ? (
            <div className={classes.metric}>{alternateMetrics}</div>
          ) : (
            <div className={classes.metric}>
              Most <strong>{scoreMetric}</strong> over{' '}
              <strong>{numMatches}</strong> matches wins!
            </div>
          )}
          {limit && (
            <div className={classes.limit}>
              <FontAwesomeIcon icon={faUser} className={classes.icon} /> {limit}{' '}
              Limit
            </div>
          )}
        </div>
        <div className={classes.row}>
          <div className={classes.date}>
            <span className={classes.label}>Start Date:</span>{' '}
            <span>
              {moment(startTime).format('MMM Do, YYYY [at] h:mm A z')}
            </span>
          </div>
        </div>

        {!privateMatch && (
          <div className={classes.row}>
            <div className={classes.date}>
              <span className={classes.label}>End Date:</span>{' '}
              <span>
                {moment(endTime).format('MMM Do, YYYY [at] h:mm A z')}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Only show a button if the tournament is active */}
      {active && (
        <React.Fragment>
          {(
            closeTime
              ? moment().isBefore(closeTime)
              : moment().isBefore(endTime)
          ) ? (
            userTournament && userTournament.entry.activisionId ? (
              <React.Fragment>
                {moment().isBefore(moment(startTime)) ? (
                  <div className={classes.entered}>Entered</div>
                ) : (
                  <React.Fragment>
                    {hasMinimumEntries ? (
                      <Link to={`/tournament/${id}`} className={classes.cta}>
                        Leaderboard <FontAwesomeIcon icon={faChevronRight} />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : userTournament ? (
              <Link to={`/entry/details/${id}`} className={classes.cta}>
                Complete Entry <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            ) : (
              <Link to={`/entry/${id}`} className={classes.cta}>
                Enter Now <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )
          ) : closeTime &&
            moment().isAfter(closeTime) &&
            moment().isBefore(endTime) ? (
            <div />
          ) : (
            <React.Fragment>
              {hasMinimumEntries ? (
                <Link to={`/tournament/${id}`} className={classes.cta}>
                  See Results <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              ) : (
                <div />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </li>
  );
};

export default Tournament;
