// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Actions
import {
  fetchFacebook,
  fetchTwitch,
  fetchYouTube,
  submitClip,
} from '../../redux/actions/submit';

// Components
import Loader from '../Loader';

import Step1 from './components/Step1';
import Step2 from './components/Step2';

import ErrorNoUsername from './components/ErrorNoUsername';
import ErrorNoContentSource from './components/ErrorNoContentSource';

export const SubmitForm = ({
  fetchFacebook,
  fetchTwitch,
  fetchYouTube,
  submitClip,
  loading,
  sources,
  username,
}) => {
  // State
  const [currentStep, setCurrentStep] = useState(1);
  const [clip, setClip] = useState(null);

  // Effects
  useEffect(() => {
    if (!loading) {
      fetchFacebook();
      fetchTwitch();
      fetchYouTube();
    }
  }, [loading]);

  // Handlers
  const handleSubmit = (contest, title, desc) => {
    if (currentStep === 1) return setCurrentStep(2);

    submitClip(clip.id, contest, title, desc, clip.type);
  };

  // Prerequisite checks
  if (loading)
    return (
      <div className={classes.formWrap}>
        <Loader button={true} />
      </div>
    );

  if (!username)
    return (
      <div className={classes.formWrap}>
        <ErrorNoUsername classes={classes} />
      </div>
    );

  if (!Object.values(sources).some((value) => !!value))
    return (
      <div className={classes.formWrap}>
        <ErrorNoContentSource />
      </div>
    );

  return (
    <div className={classes.formWrap}>
      {/* Step 1: Select clip source */}
      <Step1
        classes={classes}
        currentStep={currentStep}
        setClip={setClip}
        onSubmit={handleSubmit}
      />

      {/* Step 2: Fill in Contest, Description, Agree to Terms and submit */}
      <Step2
        classes={classes}
        clip={clip}
        currentStep={currentStep}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export const mapStateToProps = ({ auth }) => ({
  loading: !auth.user,
  sources: {
    facebook: auth.user ? auth.user.facebookLinked : false,
    twitch: auth.user ? auth.user.twitchLinked : false,
    youtube: auth.user ? auth.user.youtubeLinked : false,
  },
  username: auth.user ? auth.user.username : '',
});

export const mapDispatchToProps = (dispatch) => ({
  fetchFacebook: () => dispatch(fetchFacebook()),
  fetchTwitch: () => dispatch(fetchTwitch()),
  fetchYouTube: () => dispatch(fetchYouTube()),
  submitClip: (clipId, contestId, title, desc, type) =>
    dispatch(submitClip(clipId, contestId, title, desc, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitForm);
