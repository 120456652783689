// Libraries
import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// Actions
import { addComment, fetchComments } from '../../redux/actions/comments';

// Selectors
import { videoComments } from '../../redux/selectors/comments';

// Styles
import classes from './style.module.scss';

// Components
import AddComment from './components/AddComment';
import CommentList from './components/CommentList';
import { Link } from 'react-router-dom';

export const Comments = ({
  comments,
  userId,
  username,
  videoId,
  addComment,
  fetchComments,
}) => {
  // Effects
  useEffect(() => {
    if (comments.length === 0) {
      fetchComments(videoId);
    }
  }, []);

  // Handlers
  const handleSubmit = (comment) => {
    addComment({
      commenterId: userId,
      commenter: username,
      comment,
      date: new Date(),
      video: videoId,
    });
  };

  return (
    <div className={classes.comments}>
      <h2>
        {comments.length} Comment{comments.length === 1 ? '' : 's'}
      </h2>

      {userId && username ? (
        <AddComment onSubmit={handleSubmit} />
      ) : userId && !username ? (
        <Link className={classes.login} to="/account">
          You must set a username before you can comment.
        </Link>
      ) : (
        <Link className={classes.login} to={`/login?r=/watch?v=${videoId}`}>
          Login to comment
        </Link>
      )}

      <CommentList comments={comments} />
    </div>
  );
};

export const mapStateToProps = ({ auth, comments }, { videoId }) => ({
  comments: videoComments(videoId, comments).sort((a, b) =>
    moment(a.date).isBefore(moment(b.date)) ? 1 : -1
  ),
  userId: auth.user && auth.user.uid,
  username: auth.user && auth.user.username,
});

export const mapDispatchToProps = (dispatch) => ({
  addComment: (comment) => dispatch(addComment(comment)),
  fetchComments: (video) => dispatch(fetchComments(video)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
