// Libraries
import { call, put, takeEvery } from 'redux-saga/effects';
import firebase from '../../../firebase';
import gql from 'graphql-tag';

// Actions
import {
  addUser,
  sendWelcomeEmail,
  setJoinError,
  setJoinLoading,
  setJoinSuccess,
  setUser,
  updateUser,
} from '../../actions/auth';
import { trackJoinUser } from '../../actions/analytics';
import { setMetaFlag } from '../../actions/meta';

// Utils
import sendQuery from '../../utils/sendQuery';

export const createUserWithEmailAndPassword = (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const sendVerificationEmail = () =>
  firebase.auth().currentUser.sendEmailVerification({
    url: `${process.env.BASE_URL}/`,
  });

export function* connectFacebook(action) {
  try {
    const facebook = yield new Promise((resolve) => {
      FB.login((response) => resolve(response), { scope: 'user_videos' });
    });

    yield put(
      updateUser({ facebook: facebook.authResponse, facebookLinked: true })
    );
  } catch (e) {
    console.error(e);
  }
}

export const GET_TWITCH_ACCESS_TOKEN = `
  mutation($code: String!) {
    getTwitchAccessToken(code: $code) {
      id
      twitchLinked
    }
  }
`;

export function* connectTwitch(action) {
  const result = yield call(sendQuery, GET_TWITCH_ACCESS_TOKEN, {
    code: action.code,
  });
  const { data } = yield result.json();

  yield put(updateUser(data.getTwitchAccessToken));
}

export const GET_YOUTUBE_ACCESS_TOKEN = `
  mutation($code: String!) {
    getYouTubeAccessToken(code: $code) {
      id
      youtubeLinked
    }
  }
`;

export function* connectYoutube(action) {
  const result = yield call(sendQuery, GET_YOUTUBE_ACCESS_TOKEN, {
    code: action.code,
  });
  const { data } = yield result.json();

  yield put(updateUser(data.getYouTubeAccessToken));
}

export const CREATE_USER = `
  mutation($username: String, $referrer: String) {
    createUser(username: $username, referrer: $referrer) {
      id
      username
    }
  }
`;

export function* createUser(action) {
  try {
    // Create Firebase auth user
    const joinResult = yield call(
      createUserWithEmailAndPassword,
      action.email,
      action.password
    );

    // Create database record for user
    const result = yield call(sendQuery, CREATE_USER, {
      username: action.username,
      referrer: action.referrer,
    });

    // Send verification email
    yield call(sendVerificationEmail);

    // Dispatch analytics event
    yield put(trackJoinUser(joinResult.uid));
    fbq('track', 'CompleteRegistration');

    // Set local user data
    // yield put(
    //   updateUser({
    //     username: action.username,
    //   })
    // );

    // If we made it this far, success
    yield put(setJoinSuccess(true));
  } catch (e) {
    switch (e.code) {
      case 'auth/email-already-in-use':
        yield put(setJoinError({ email: 'Email address is already in use.' }));
        break;
      case 'auth/invalid-email':
        yield put(setJoinError({ email: 'Please enter a valid email.' }));
        break;
      case 'auth/weak-password':
        yield put(
          setJoinError({
            email: 'The password must be 6 characters long or more.',
          })
        );
        break;
    }

    yield put(setJoinLoading(false));
  }
}

export function* createProviderUser(action) {
  // Create database record for user
  const result = yield call(sendQuery, CREATE_USER, {
    referrer: action.referrer,
  });

  yield put(setJoinSuccess(true));
}

export const FETCH_USER = `
  query($id: ID!) {
    user(id: $id) {
      id
      username
      twitchLinked
      youtubeLinked
      isAdmin
      activisionId
      credits {
        id
        amount
        date
        expired
        note
      }
      creditTotal
      sendWelcomeEmailRequired
      subscriptions {
        id
        membership {
          id
          name
        }
      }
      tournaments {
        id
        name
        entry {
          id
          activisionId
          user {
            id
          }
        }
      }
    }
  }
`;

export function* fetchUser(action) {
  if (!action.user) {
    yield put(setUser(null));
    yield put(setMetaFlag('userLoading', false));
  } else {
    yield put(setMetaFlag('userLoading', true));
    const result = yield call(sendQuery, FETCH_USER, {
      id: action.user.uid,
    });
    const { data, errors } = yield result.json();

    // Check initial Facebook state
    // const facebook = yield new Promise((resolve, reject) => {
    //   FB.getLoginStatus((response) => {
    //     return resolve(response.authResponse);
    //   });
    // });

    yield put(
      setUser({
        ...action.user,
        ...data.user,
        facebook: {},
        facebookLinked: false,
      })
    );

    if (data.user.sendWelcomeEmailRequired) {
      yield put(sendWelcomeEmail());
    }

    yield put(setMetaFlag('userLoading', false));
  }
}

export const REVOKE_TWITCH = `
  mutation {
    revokeTwitch {
      id
      twitchLinked
    }
  }
`;

export function* revokeTwitch(action) {
  const result = yield call(sendQuery, REVOKE_TWITCH);
  const { data } = yield result.json();

  yield put(updateUser(data.revokeTwitch));
}

export const REVOKE_YOUTUBE = `
  mutation {
    revokeYoutube {
      id
      youtubeLinked
    }
  }
`;

export function* revokeYoutube(action) {
  const result = yield call(sendQuery, REVOKE_YOUTUBE);
  const { data, errors } = yield result.json();

  yield put(updateUser(data.revokeYoutube));
}

export function* revokeFacebook(action) {
  const result = yield new Promise((resolve) => {
    FB.logout((result) => resolve(result));
  });

  yield put(
    updateUser({
      facebook: null,
      facebookLinked: false,
    })
  );
}

export const FETCH_OTHER_USER = `
  query($id: ID!) {
    user(id: $id) {
      id
      username
    }
  }
`;

export function* fetchOtherUser(action) {
  const result = yield call(sendQuery, FETCH_OTHER_USER, {
    id: action.id,
  });
  const { data, errors } = yield result.json();

  yield put(addUser(data.user));
}

export const SEND_WELCOME_EMAIL = `
  mutation {
    sendWelcomeEmail
  }
`;

export function* doSendWelcomeEmail(action) {
  const result = yield call(sendQuery, SEND_WELCOME_EMAIL);
  const { data, errors } = yield result.json();
}

export default function* auth() {
  yield takeEvery('CONNECT_FACEBOOK', connectFacebook);
  yield takeEvery('CONNECT_TWITCH', connectTwitch);
  yield takeEvery('CONNECT_YOUTUBE', connectYoutube);
  yield takeEvery('CREATE_USER', createUser);
  yield takeEvery('CREATE_PROVIDER_USER', createProviderUser);
  yield takeEvery('FETCH_USER', fetchUser);
  yield takeEvery('FETCH_OTHER_USER', fetchOtherUser);
  yield takeEvery('REVOKE_FACEBOOK', revokeFacebook);
  yield takeEvery('REVOKE_TWITCH', revokeTwitch);
  yield takeEvery('REVOKE_YOUTUBE', revokeYoutube);
  yield takeEvery('SEND_WELCOME_EMAIL', doSendWelcomeEmail);
}
