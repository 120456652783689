export const appendFacebook = (feed) => ({
  type: 'APPEND_FACEBOOK',
  feed,
});

export const appendTwitch = (feed) => ({
  type: 'APPEND_TWITCH',
  feed,
});

export const appendYouTube = (feed) => ({
  type: 'APPEND_YOUTUBE',
  feed,
});

export const fetchFacebook = (cursor = '') => ({
  type: 'FETCH_FACEBOOK',
  cursor,
});

export const fetchTwitch = (cursor = '') => ({
  type: 'FETCH_TWITCH',
  cursor,
});

export const fetchYouTube = (cursor = '') => ({
  type: 'FETCH_YOUTUBE',
  cursor,
});

export const resetSubmit = () => ({
  type: 'RESET_SUBMIT',
});

export const setSubmitLoading = (loading) => ({
  type: 'SET_SUBMIT_LOADING',
  loading,
});

export const setSubmitSuccess = (success) => ({
  type: 'SET_SUBMIT_SUCCESS',
  success,
});

export const setFacebook = (feed) => ({
  type: 'SET_FACEBOOK',
  feed,
});

export const setFacebookLinked = (linked) => ({
  type: 'SET_FACEBOOK_LINKED',
  linked,
});

export const setFacebookLoading = (loading) => ({
  type: 'SET_FACEBOOK_LOADING',
  loading,
});

export const setFacebookInitial = (initial) => ({
  type: 'SET_FACEBOOK_INITIAL',
  initial,
});

export const setTwitch = (feed) => ({
  type: 'SET_TWITCH',
  feed,
});

export const setTwitchLinked = (linked) => ({
  type: 'SET_TWITCH_LINKED',
  linked,
});

export const setTwitchLoading = (loading) => ({
  type: 'SET_TWITCH_LOADING',
  loading,
});

export const setTwitchInitial = (initial) => ({
  type: 'SET_TWITCH_INITIAL',
  initial,
});

export const setYouTube = (feed) => ({
  type: 'SET_YOUTUBE',
  feed,
});

export const setYouTubeLinked = (linked) => ({
  type: 'SET_YOUTUBE_LINKED',
  linked,
});

export const setYouTubeLoading = (loading) => ({
  type: 'SET_YOUTUBE_LOADING',
  loading,
});

export const setYouTubeInitial = (initial) => ({
  type: 'SET_YOUTUBE_INITIAL',
  initial,
});

export const submitClip = (
  clipId,
  contestId,
  title,
  description,
  clipType
) => ({
  type: 'SUBMIT_CLIP',
  clipId,
  contestId,
  title,
  description,
  clipType,
});
