// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

export const ClipList = ({
  clips,
  loading,
  hasMore,
  requestMore,
  onSelect,
  type,
}) => {
  return (
    <ul className={classes.clips}>
      {clips.length > 0 ? (
        clips.map((clip) => (
          <li key={clip.id} className={classes.clip}>
            <button onClick={(e) => onSelect(clip)}>
              <img src={clip.thumbnail_url} alt="" />{' '}
              {clip.title || '[No Title]'}
            </button>
          </li>
        ))
      ) : (
        <li className={classes.empty}>
          {type === 'twitch'
            ? 'You do not have any clips of your channel.'
            : 'You do not have any videos on your channel.'}
        </li>
      )}
      {hasMore && (
        <li className={classes.more}>
          <button onClick={requestMore}>
            {loading ? 'Loading...' : 'Load more'}
          </button>
        </li>
      )}
    </ul>
  );
};

export default ClipList;
