export const contestVideos = (contest, videos) =>
  videos.filter((video) => video.contest && video.contest.id === contest);

export const latestVideos = (videos) =>
  videos
    .filter(
      (video) => video.tags && video.tags.some((tag) => tag.id === 'latest')
    )
    .sort(
      (a, b) =>
        a.tags.find((tag) => tag.id === 'latest').sort -
        b.tags.find((tag) => tag.id === 'latest').sort
    );

export const trendingVideos = (videos) =>
  videos.filter(
    (video) => video.tags && video.tags.some((tag) => tag.id === 'trending')
  );

export const userVideos = (uid, videos) =>
  videos.filter((video) => video.submitter.id === uid);

export const selectVideos = (type, videos, uid) => {
  switch (type) {
    case 'latest':
      return latestVideos(videos);
    case 'trending':
      return trendingVideos(videos);
    case 'user':
      return userVideos(uid, videos);
    default:
      return videos;
  }
};
