export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_CREDIT_PRODUCTS':
      return action.products;
    default:
      return state;
  }
};
