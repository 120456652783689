// Libraries
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Actions
import { fetchContests } from '../../redux/actions/contests';
import {
  fetchContestVideos,
  fetchLatestVideos,
  fetchHomeVideos,
  setLastVideo,
} from '../../redux/actions/videos';
import { trackScrollRibbon } from '../../redux/actions/analytics';

// Selectors
import { active } from '../../redux/selectors/contests';
import {
  contestVideos,
  latestVideos,
  trendingVideos,
} from '../../redux/selectors/videos';

// Components
import AdBar from '../../components/AdBar';
import Countdown from '../../components/Countdown';
import { Helmet } from 'react-helmet';
import Ribbon from '../../components/Ribbon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';

export const HomePage = ({
  contests,
  contestsLoading,
  videosLoading,
  latestVideos,
  trendingVideos,
  refetch,
  fetchContests,
  fetchContestVideos,
  fetchLatestVideos,
  fetchHomeVideos,
  setLastVideo,
  trackScrollRibbon,
}) => {
  window.location.replace('/tournaments');
  return (
    <React.Fragment>
      <Loader />
      <Helmet>
        <title>You Freaking Noob</title>
      </Helmet>
    </React.Fragment>
  );

  const user = firebase.auth().currentUser;

  // State
  const [populatedVideos, setPopulatedVideos] = useState(false);

  // Effects
  useEffect(() => {
    if (refetch) doRefetch();
  }, [refetch]);

  useEffect(() => {
    if (latestVideos.length > 0) {
      setLastVideo({
        id: latestVideos[0].id,
      });
    }
  }, [latestVideos]);

  useEffect(() => {
    if (contests.length === 0) fetchContests();
    fetchHomeVideos();
  }, []);

  return (
    <div className={classes.page}>
      <Helmet>
        <title>You Freaking Noob</title>
      </Helmet>
      <Redirect to="https://www.youfreakingnoob.com" />
      <div className={classes.hero}>
        <div className={classes.wrap}>
          {user ? (
            <div className={classes.innerWrap}>
              <h1>Enter to Win</h1>
              <small>
                Submit your best video clips for a chance to WIN MONEY!
              </small>
              <Link to="/submit" className={classes.ctaButton}>
                Upload Here <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          ) : (
            <div className={classes.innerWrap}>
              <h1>Sign up to win $$</h1>
              <small>Submit your best clips today!</small>
              <Link to="/register" className={classes.ctaButton}>
                Get Started <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className={classes.ribbons}>
        {contestsLoading || videosLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {contests.map(
              (contest) =>
                contest.videos &&
                contest.videos.length >= 3 && (
                  <React.Fragment key={contest.id}>
                    <h2>
                      <Link to={`/contest/${contest.id}`}>
                        HIGHLIGHT: {contest.name}{' '}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Link>
                    </h2>
                    <div className={classes.info}>
                      <div className={classes.subtitle}>
                        <FontAwesomeIcon
                          className={classes.prize}
                          icon={faMoneyBillWaveAlt}
                        />{' '}
                        {contest.prize}
                      </div>
                      <div className={classes.subtitle}>
                        <Countdown
                          type={contest.state}
                          end={
                            contest.state === 'submission'
                              ? contest.votingStart
                              : contest.votingEnd
                          }
                        />
                      </div>
                    </div>
                    <Ribbon
                      className={classes.ribbon}
                      videos={contest.videos}
                      allVideos={`/contest/${contest.id}`}
                      context="contest"
                      onScrollLeft={() =>
                        trackScrollRibbon(contest.name, 'left')
                      }
                      onScrollRight={() =>
                        trackScrollRibbon(contest.name, 'right')
                      }
                    />
                  </React.Fragment>
                )
            )}

            <h2>
              <Link to="/latest">
                LATEST SUBMISSIONS <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </h2>
            <div className={classes.subtitle}>
              Check out the most recent clips
            </div>
            <Ribbon
              className={classes.ribbon}
              videos={latestVideos}
              allVideos="/latest"
              context="latest"
              onScrollLeft={() => trackScrollRibbon('Latest', 'left')}
              onScrollRight={() => trackScrollRibbon('Latest', 'right')}
            />

            <h2>
              <Link to="/trending">
                TRENDING <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </h2>
            <div className={classes.subtitle}>
              These clips are rocketing up the charts
            </div>
            <Ribbon
              className={classes.ribbon}
              videos={trendingVideos}
              allVideos="/trending"
              context="trending"
              onScrollLeft={() => trackScrollRibbon('Trending', 'left')}
              onScrollRight={() => trackScrollRibbon('Trending', 'right')}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ contests, meta, videos }) => ({
  contests: active(contests)
    .map((contest) => ({
      ...contest,
      videos: contestVideos(contest.id, videos),
    }))
    .sort((a, b) => {
      const stateA = a.state.toUpperCase();
      const stateB = b.state.toUpperCase();
      return stateA < stateB ? -1 : stateA > stateB ? 1 : 0;
    }),
  contestsLoading: meta.contestsLoading,
  videosLoading: meta.videosLoading,
  latestVideos: latestVideos(videos),
  trendingVideos: trendingVideos(videos),
  refetch: videos.refetch,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContests: () => dispatch(fetchContests('active')),
  fetchContestVideos: (id) => dispatch(fetchContestVideos(id)),
  fetchLatestVideos: () => dispatch(fetchLatestVideos()),
  fetchHomeVideos: () => dispatch(fetchHomeVideos()),
  setLastVideo: (video) => dispatch(setLastVideo(video)),
  trackScrollRibbon: (ribbon, direction) =>
    dispatch(trackScrollRibbon(ribbon, direction)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
