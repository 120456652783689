export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'ADD_TOURNAMENT':
      return [
        ...state.filter((tournament) => tournament.id !== action.tournament.id),
        action.tournament,
      ];
    case 'SET_TOURNAMENTS':
      return action.tournaments;
    default:
      return state;
  }
};
