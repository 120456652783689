// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import PropagateLoader from 'react-spinners/PropagateLoader';

export const Loader = ({ button, children, color }) => {
  return (
    <div className={classes.loader} style={{ color }}>
      <div className={classes.spinner}>
        <PropagateLoader
          color={color || '#e8e8e8'}
          css={{
            marginTop: button ? '-8px' : 0,
            marginLeft: button ? '' : '-17px',
            width: '1px',
          }}
        />
      </div>
      {!button && (
        <div className={classes.text}>
          {children || 'Loading, please wait...'}
        </div>
      )}
    </div>
  );
};

export default Loader;
