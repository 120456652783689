// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord';
import { Link, useLocation } from 'react-router-dom';

export const Footer = () => {
  const location = useLocation();

  if (location.pathname === '/verify') return null;

  return (
    <footer className={classes.footer}>
      <nav>
        <ul>
          <li>
            <Link to="/">
              Home <span className={classes.spacer}>&nbsp;</span>
            </Link>
            <div className={classes.dot} />
          </li>

          <li>
            <a href="https://www.youfreakingnoob.com/blog/" target="_blank">
              Blog <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>

          <li>
            <a href="https://www.youfreakingnoob.com/about" target="_blank">
              About <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>

          <li>
            <a href="https://www.youfreakingnoob.com/contact" target="_blank">
              Contact <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>

          <li>
            <a
              href="https://www.youfreakingnoob.com/contest-rules/"
              target="_blank"
            >
              Contest Rules <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>

          <li>
            <a
              href="https://www.youfreakingnoob.com/terms-of-service#privacy-policy"
              target="_blank"
            >
              Privacy Policy <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>

          <li>
            <a
              href="https://www.youfreakingnoob.com/terms-of-service"
              target="_blank"
            >
              Terms of Service <span className={classes.spacer}>&nbsp;</span>
            </a>
            <div className={classes.dot} />
          </li>
        </ul>

        <ul className={classes.social}>
          <li>
            <a href="https://www.facebook.com/youfreakingnoob">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </li>

          <li>
            <a href="https://twitter.com/youfreakingnoob">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>

          <li>
            <a href="https://instagram.com/youfreakingnoob">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>

          <li>
            <a href="https://youtube.com/youfreakingnoob">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>

          <li>
            <a href="https://discord.gg/g8DApH7">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
          </li>
        </ul>
      </nav>
      <div className={classes.legal}>
        <div className={classes.spacer}>&nbsp;</div>
        <div className={classes.copyright}>
          Copyright &copy; 2021 youfreakingnoob.com
        </div>
        <div className={classes.version}>{process.env.VERSION}</div>
      </div>
    </footer>
  );
};

export default Footer;
