// Libraries
import React, { useEffect, useState } from 'react';
import firebase from '../../firebase';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Actions
import { setRoute } from '../../redux/actions/router';
import { clearAlert } from '../../redux/actions/alerts';
import { checkNewVideos } from '../../redux/actions/videos';

// Assets
import logo from '../../assets/logo-transparent.png';

// Hooks
import useFirebaseUser from '../../hooks/useFirebaseUser';

// Components
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

export const Header = ({
  alerts,
  user,
  checkNewVideos,
  clearAlert,
  setRoute,
}) => {
  const location = useLocation();
  const history = useHistory();

  // State
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(window.innerWidth <= 850);
  const [closeDropdowns, setCloseDropdowns] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [verified, setVerified] = useState(true);
  const [sent, setSent] = useState(false);
  const [verifyMobile, setVerifyMobile] = useState(window.innerWidth <= 640);

  // Effects
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });

    window.addEventListener('click', () => {
      setCloseDropdowns(true);
    });

    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth <= 850) setProfileOpen(true);
      else setProfileOpen(false);

      if (e.target.innerWidth <= 640) setVerifyMobile(true);
      else setVerifyMobile(false);
    });
  }, []);

  useEffect(() => {
    if (closeDropdowns && !hasOpened && window.innerWidth > 850) {
      if (alertOpen) setAlertOpen(false);
      if (profileOpen) setProfileOpen(false);

      setCloseDropdowns(false);
    } else if (hasOpened) {
      setCloseDropdowns(false);
      setHasOpened(false);
    }
  }, [closeDropdowns, hasOpened]);

  useEffect(() => {
    setTimeout(() => {
      const parsed = new URLSearchParams(window.location.search);
      const params = {};

      for (const [key, value] of parsed) params[key] = value;
      setRoute(window.location.pathname, params, document.title);
    }, 0);

    history.listen((location) => {
      setTimeout(() => {
        const parsed = new URLSearchParams(location.search);
        const params = {};
        for (const [key, value] of parsed) params[key] = value;
        setRoute(location.pathname, params, document.title);
      }, 0);
    });
  }, []);

  useEffect(() => {
    setVerified(
      !(
        user &&
        !user.emailVerified &&
        user.providerData &&
        !!user.providerData.find((p) => p.providerId === 'password')
      )
    );
  }, [user]);

  // Handlers
  const handleResend = (e) => {
    e.preventDefault();
    firebase.auth().currentUser.sendEmailVerification({
      url: `${process.env.BASE_URL}/`,
    });
    setSent(true);
  };

  const heroPath =
    location.pathname === '/' ||
    location.pathname.includes('/contest/') ||
    location.pathname.includes('/tournaments') ||
    location.pathname.includes('/contests');

  if (location.pathname === '/verify') return null;

  return (
    <React.Fragment>
      <header
        className={`${classes.headWrap} ${heroPath ? classes.homeWrap : ''}`}
        style={{
          height:
            (!heroPath ? 8 : 0) + // If it's not a hero path, add 8rem for solid header
            (!verified ? 5 : 0) + // If the user is not verified, add 5rem for verified bar
            (!verified && verifyMobile ? 5 : 0) + // If the user is not verified and on mobile, add 5rem for tall verified bar
            'rem',
        }}
      >
        {!verified && (
          <div className={classes.verify}>
            <div className={classes.text}>
              Please check your inbox (and spam) to verify your email address...
              so we can reach you with prize notifications.{' '}
            </div>
            <button onClick={handleResend} disabled={sent}>
              {sent ? 'Sent!' : 'Resend verification email'}
            </button>
          </div>
        )}
        <div
          className={`${classes.header} ${heroPath ? classes.home : ''}`}
          style={{
            top: !verified && verifyMobile ? '10rem' : !verified ? '5rem' : '',
          }}
          data-scroll={scroll}
        >
          <div className={classes.left}>
            <div className={classes.logo}>
              <a
                className={classes.logoInner}
                href="https://www.youfreakingnoob.com/"
              >
                <img src={logo} alt="" />
                <span className={classes.text}>
                  You Freaking Noob <span className={classes.beta}>BETA</span>
                </span>
              </a>
            </div>
          </div>

          <button
            className={classes.mobileButton}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>

          <div className={`${classes.right} ${open ? classes.open : ''}`}>
            {user ? (
              <nav>
                <ul>
                  <li>
                    <NavLink onClick={() => setOpen(false)} to="/tournaments">
                      Tournaments
                      <span className={classes.spreader}>&nbsp;</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => setOpen(false)} to="/contests">
                      Contests<span className={classes.spreader}>&nbsp;</span>
                    </NavLink>
                  </li>
                  <li className={classes.primary}>
                    <a href="https://youfreakingnoob.com/membership-benefits-core/">
                      Membership<span className={classes.spreader}>&nbsp;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            ) : (
              <nav>
                <ul>
                  <li>
                    <NavLink onClick={() => setOpen(false)} to="/tournaments">
                      Tournaments
                      <span className={classes.spreader}>&nbsp;</span>
                    </NavLink>
                  </li>
                  <li>
                    <a href="https://youfreakingnoob.com/membership-benefits-core/">
                      Membership<span className={classes.spreader}>&nbsp;</span>
                    </a>
                  </li>
                  <li className={classes.primary}>
                    <NavLink onClick={() => setOpen(false)} to="/register">
                      Create FREE Account
                    </NavLink>
                  </li>
                </ul>
              </nav>
            )}

            <nav>
              <ul>
                <li className={classes.iconDropdown}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className={`${classes.notifications} ${
                      alerts.length > 0 ? classes.active : ''
                    }`}
                    onClick={() => {
                      setAlertOpen(true);
                      setHasOpened(true);
                    }}
                  />

                  {alertOpen && (
                    <div className={classes.alertWrap}>
                      <ul className={classes.alerts}>
                        <li className={classes.arrow}>&nbsp;</li>
                        {alerts.length > 0 ? (
                          alerts.map(({ id, message, target }) => (
                            <li
                              id={id}
                              className={classes.alert}
                              onClick={() => clearAlert(id)}
                            >
                              <Link to={target}>
                                {message}
                                <span className={classes.spacer}>&nbsp;</span>
                              </Link>
                              <button className={classes.clear}>
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </li>
                          ))
                        ) : (
                          <li className={classes.noAlerts}>
                            No notifications.
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </li>
                <li className={classes.iconDropdown}>
                  {user ? (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        className={classes.profile}
                        onClick={() => {
                          setProfileOpen(true);
                          setHasOpened(true);
                        }}
                      />

                      {profileOpen && (
                        <div className={classes.dropdown}>
                          <ul>
                            <li className={classes.arrow}>&nbsp;</li>
                            <li>
                              <Link
                                onClick={() => setOpen(false)}
                                to="/account"
                              >
                                Account
                                <span className={classes.spacer}>&nbsp;</span>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={() => setOpen(false)} to="/logout">
                                Logout
                                <span className={classes.spacer}>&nbsp;</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <NavLink to="/login" onClick={() => setOpen(false)}>
                      Login
                    </NavLink>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export const mapStateToProps = ({ alerts, auth }) => ({
  alerts,
  user: auth.user,
});

export const mapDispatchToProps = (dispatch) => ({
  checkNewVideos: () => dispatch(checkNewVideos()),
  clearAlert: (id) => dispatch(clearAlert(id)),
  setRoute: (route, params, title) => dispatch(setRoute(route, params, title)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
