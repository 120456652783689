// Libraries
import React, { useEffect } from 'react';
import firebase from '../../firebase';
import moment from 'moment';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Selectors
import {
  currentTournament,
  divisions,
} from '../../redux/selectors/tournaments';

// Actions
import { fetchTournament } from '../../redux/actions/tournaments';

// Components
import Loader from '../../components/Loader';
import { Link, useHistory } from 'react-router-dom';

export const ChallengePage = ({
  tournament,
  user,
  userLoading,
  fetchTournament,
  match,
}) => {
  // Redirect anon users
  const history = useHistory();
  // if (!firebase.auth().currentUser)
  //   history.push(
  //     `/login?r=${encodeURIComponent(`/tournament/${match.params.id}`)}`
  //   );

  useEffect(() => {
    fetchTournament(match.params.id);
  }, []);

  if (!tournament || userLoading) return <Loader />;

  return (
    <div className={classes.page}>
      <div className={classes.hero}>
        <div className={classes.wrap}>
          <div className={classes.innerWrap}>
            <h1>{tournament.name}</h1>
          </div>
        </div>
      </div>

      {user && user.tournaments.find((t) => t.id === match.params.id) && (
        <div
          class={classes.html}
          dangerouslySetInnerHTML={{ __html: tournament.html }}
          style={{ fontSize: '1.6rem' }}
        />
      )}

      <div className={classes.leaderboard}>
        {tournament.leaderboard.length >= 3 ? (
          <React.Fragment>
            <h2>Leaderboard</h2>
            <small>
              Last Updated:{' '}
              {moment
                .max(
                  tournament.leaderboard.map(({ dateUpdated }) =>
                    moment(dateUpdated)
                  )
                )
                .format('MMMM Do YYYY [at] h:mm a')}
            </small>

            {Object.entries(divisions(tournament.leaderboard)).map(
              ([division, entries]) => (
                <React.Fragment key={division}>
                  {division !== '0' && (
                    <h3>
                      Division {division}{' '}
                      <small>
                        ({tournament.leaderboardTotals[division]}{' '}
                        {tournament.leaderboardTotals[division] === 1
                          ? tournament.individual
                            ? 'entry'
                            : 'team'
                          : tournament.individual
                          ? 'entries'
                          : 'teams'}
                        )
                      </small>
                    </h3>
                  )}
                  <ol>
                    {entries.map(
                      (
                        {
                          id,
                          team,
                          user: { id: userId, username },
                          userScore,
                          gapped,
                          highlighted,
                          members,
                          place,
                        },
                        i
                      ) => (
                        <React.Fragment>
                          {gapped && <li className={classes.gapped}>...</li>}
                          <li
                            key={id}
                            className={highlighted ? classes.highlight : ''}
                          >
                            <div className={classes.username}>
                              {`${place}. `}
                              {tournament.individual ? username : team}
                              {members && members.length > 0 && (
                                <ul>
                                  {members.map((member) => (
                                    <li>
                                      <div class={classes.username}>
                                        {member.username}
                                      </div>
                                      <div class={classes.singleScore}>
                                        {member.score}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            <div className={classes.score}>
                              {userScore} {tournament.scoreMetric}
                            </div>
                          </li>
                        </React.Fragment>
                      )
                    )}
                  </ol>
                </React.Fragment>
              )
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>Leaderboard not yet available</h2>
            <p>Check back soon!</p>
          </React.Fragment>
        )}
      </div>

      {(!user || !user.tournaments.find((t) => t.id === match.params.id)) && (
        <React.Fragment>
          {tournament.closeTime &&
            moment(tournament.closeTime).isAfter(moment()) && (
              <div class={classes.leaderboard}>
                <Link className={classes.cta} to={`/entry/${match.params.id}`}>
                  Enter Now
                </Link>
              </div>
            )}
        </React.Fragment>
      )}
    </div>
  );
};

export const mapStateToProps = ({ auth, meta, tournaments }, { match }) => ({
  tournament: currentTournament(match.params.id, tournaments),
  userLoading: meta.userLoading,
  user: auth.user,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchTournament: (id) => dispatch(fetchTournament(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePage);
