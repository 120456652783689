// Libraries
import { call, put, takeEvery } from 'redux-saga/effects';

// Utils
import sendQuery from '../../utils/sendQuery';

// Actions
import { setCreditProducts } from '../../actions/creditProducts';
import { updateUser } from '../../actions/auth';

export const COMPLETE_CREDIT_PURCHASE = `
  mutation($order: ID!, $type: String!) {
    buyCredits(order: $order, type: $type)
  }
`;

export function* completeCreditPurchase(action) {
  const result = yield call(sendQuery, COMPLETE_CREDIT_PURCHASE, {
    order: action.order,
    type: action.orderType,
  });
  const { data, errors } = yield result.json();

  console.log(data);
  if (data.buyCredits > 0) {
    // Purchase was successful
    yield put(
      updateUser({
        creditTotal: data.buyCredits,
      })
    );
  } else {
    // Error
  }
}

export const FETCH_CREDIT_PRODUCTS = `
  query {
    creditProducts {
      id
      product
      credits
      label
      price
    }
  }
`;

export function* fetchCreditProducts(action) {
  const result = yield call(sendQuery, FETCH_CREDIT_PRODUCTS, {});
  const { data, errors } = yield result.json();

  yield put(setCreditProducts(data.creditProducts));
}

export default function*() {
  yield takeEvery('COMPLETE_CREDIT_PURCHASE', completeCreditPurchase);
  yield takeEvery('FETCH_CREDIT_PRODUCTS', fetchCreditProducts);
}
