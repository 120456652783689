// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Style
import classes from './style.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';

export const Countdown = ({
  end,
  type,
  icon = true,
  format = 'DD days HH hours MM minutes SS seconds',
  onComplete,
}) => {
  const [display, setDisplay] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      const duration = moment.duration(
        moment(new Date(end)).diff(moment(), 'milliseconds')
      );

      // setDisplay(
      //   `${
      //     duration.months() * 30 + duration.days()
      //   } days ${duration.hours()} hours ${duration.minutes()} minutes ${duration.seconds()} seconds`
      // );
      setDisplay(
        format
          .replace('DD', duration.months() * 30 + duration.days())
          .replace('HH', duration.hours())
          .replace(
            'MM',
            duration.minutes() >= 10
              ? duration.minutes()
              : `0${duration.minutes()}`
          )
          .replace(
            'SS',
            duration.seconds() >= 10
              ? duration.seconds()
              : `0${duration.seconds()}`
          )
      );

      if (duration.as('milliseconds') <= 0) onComplete();

      setUpdate(!update);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [update]);

  return (
    <React.Fragment>
      {icon && <FontAwesomeIcon icon={faClock} className={classes.icon} />}
      <span>
        {type === 'upcoming'
          ? 'Contest opens in: '
          : type === 'submission'
          ? 'Submissions close in: '
          : type === 'voting'
          ? 'Voting closes in: '
          : ''}
        {display}
      </span>
    </React.Fragment>
  );
};

export default Countdown;
