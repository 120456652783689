export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'ADD_VIDEO':
      const index = state.findIndex((el) => el.id === action.video.id);
      if (index === -1) return [...state, action.video];
      return state;
    case 'ADD_VIDEOS':
      // Overwrite existing videos with new data if duplicate
      const mergedState = [...state].map((video) => {
        const index = action.videos.findIndex((el) => el.id === video.id);

        // Short-circuit if there is not a duplicate
        if (index === -1) return video;

        // Merge tags ahead of time
        const tags = [
          ...(video.tags || []),
          ...(action.videos[index].tags || []),
        ];

        return {
          ...video,
          ...action.videos[index],
          tags: Array.from(new Set(tags.map((tag) => tag.id))).map((id) =>
            tags.find((tag) => tag.id === id)
          ),
        };
      });

      // Add new videos that aren't already in the store
      const newVideos = action.videos.filter((video) => {
        const index = state.findIndex((el) => el.id === video.id);
        return index === -1;
      });

      // Merge the two lists
      return [...mergedState, ...newVideos];
    case 'PREPEND_LATEST':
      return [
        ...state.map((video) => {
          // Short circuit if the video is not tagged as latest
          if (!video.tags || !video.tags.some((tag) => tag.id === 'latest'))
            return video;

          // Shift the video by one sort index
          return {
            ...video,
            tags: video.tags.map((tag) =>
              tag.id === 'latest' ? { id: 'latest', sort: tag.sort + 1 } : tag
            ),
          };
        }),
        {
          ...action.video,
          tags: [
            ...(action.video.tags ? action.video.tags : []),
            { id: 'latest', sort: 0 },
          ],
        },
      ];
    case 'SET_VIDEO':
      return {
        ...state,
        watch: action.video,
      };
    case 'UPDATE_VIDEO':
      return state.map((video) =>
        video.id === action.id ? { ...video, ...action.updates } : video
      );
    case 'SET_CONTEST_VIDEOS':
      return {
        ...state,
        [action.contest]: action.videos,
      };
    case 'SET_TRENDING_VIDEOS':
      return {
        ...state,
        trending: action.videos,
      };
    case 'SET_LATEST_VIDEOS':
      return {
        ...state,
        latest: action.videos,
      };
    case 'SET_REFETCH':
      return {
        ...state,
        refetch: action.refetch,
      };
    default:
      return state;
  }
};
