export const DEfAULT_STATE = {
  facebook: {
    cursor: null,
    clips: [],
  },
  facebookLinked: false,
  facebookLoading: false,
  facebookInitial: false,
  twitch: {
    cursor: null,
    clips: [],
  },
  twitchLinked: false,
  twitchLoading: false,
  twitchInitial: false,
  youtube: {
    cursor: null,
    clips: [],
  },
  youtubeLinked: false,
  youtubeLoading: false,
  twitchInitial: false,
  submitLoading: false,
  submitSuccess: false,
};

export default (state = DEfAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_SUBMIT_LOADING':
      return {
        ...state,
        submitLoading: action.loading,
      };
    case 'SET_SUBMIT_SUCCESS':
      return {
        ...state,
        submitSuccess: action.success,
      };

    // Facebook
    case 'APPEND_FACEBOOK':
      return {
        ...state,
        facebook: {
          cursor: action.feed.cursor,
          clips: [...state.facebook.clips, ...action.feed.clips],
        },
      };
    case 'SET_FACEBOOK':
      return {
        ...state,
        facebook: action.feed,
      };
    case 'SET_FACEBOOK_LINKED':
      return {
        ...state,
        facebookLinked: action.linked,
      };
    case 'SET_FACEBOOK_LOADING':
      return {
        ...state,
        facebookLoading: action.loading,
      };
    case 'SET_FACEBOOK_INITIAL':
      return {
        ...state,
        facebookInitial: action.initial,
      };

    // Twitch
    case 'APPEND_TWITCH':
      return {
        ...state,
        twitch: {
          cursor: action.feed.cursor,
          clips: [...state.twitch.clips, ...action.feed.clips],
        },
      };
    case 'SET_TWITCH':
      return {
        ...state,
        twitch: action.feed,
      };
    case 'SET_TWITCH_LINKED':
      return {
        ...state,
        twitchLinked: action.linked,
      };
    case 'SET_TWITCH_LOADING':
      return {
        ...state,
        twitchLoading: action.loading,
      };
    case 'SET_TWITCH_INITIAL':
      return {
        ...state,
        twitchInitial: action.initial,
      };

    // Youtube
    case 'APPEND_YOUTUBE':
      return {
        ...state,
        youtube: {
          cursor: action.feed.cursor,
          clips: [...state.youtube.clips, ...action.feed.clips],
        },
      };
    case 'SET_YOUTUBE':
      return {
        ...state,
        youtube: action.feed,
      };
    case 'SET_YOUTUBE_LINKED':
      return {
        ...state,
        youtubeLinked: action.linked,
      };
    case 'SET_YOUTUBE_LOADING':
      return {
        ...state,
        youtubeLoading: action.loading,
      };
    case 'SET_YOUTUBE_INITIAL':
      return {
        ...state,
        youtubeInitial: action.initial,
      };

    default:
      return state;
  }
};
