// Libraries
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import firebase from '../../../firebase';

// Actions
import { addAlert, clearAlertTarget } from '../../actions/alerts';
import { trackVoteVideo } from '../../actions/analytics';
import { setMetaFlag, setVideosLoading } from '../../actions/meta';
import {
  setRefetch,
  addVideo,
  addVideos,
  setLatestVideos,
  updateVideo,
} from '../../actions/videos';

// Utils
import sendQuery from '../../utils/sendQuery.js';

export const DO_VOTE = `
  mutation($videoId: ID!) {
    vote(videoId: $videoId) {
      id
      hasVoted
    }
  }
`;

export function* doVote(action) {
  const result = yield call(sendQuery, DO_VOTE, {
    videoId: action.id,
  });
  const { data } = yield result.json();

  yield put(trackVoteVideo(action.id));
  yield put(updateVideo(action.id, data.vote));
}

export const FETCH_VIDEO = `
  query($id: ID!) {
    video(id: $id) {
      id
      title
      description
      embed_html
      embed_url
      hasVoted
      contest {
        id
        canVote
      }
      submitter {
        id
        username
      }
    }
  }
`;

export function* fetchVideo(action) {
  yield put(setMetaFlag('videoLoading', true));
  const result = yield call(sendQuery, FETCH_VIDEO, {
    id: action.id,
    context: action.context,
  });
  const { data } = yield result.json();

  if (data.video) yield put(addVideo(data.video));
  yield put(setMetaFlag('videoLoading', false));
}

export const FETCH_NEXT_VIDEO = `
  query($id: ID!, $context: String!) {
    video(id: $id) {
      id
      nextVideo(context: $context) {
        id
      }
    }
  }
`;

export function* fetchNextVideo(action) {
  const result = yield call(sendQuery, FETCH_NEXT_VIDEO, {
    id: action.id,
    context: action.context,
  });
  const { data } = yield result.json();

  yield put(
    updateVideo(action.id, {
      nextVideo: data.video.nextVideo || {},
    })
  );
}

export const FETCH_PREVIOUS_VIDEO = `
  query($id: ID!, $context: String!) {
    video(id: $id) {
      id
      previousVideo(context: $context) {
        id
      }
    }
  }
`;

export function* fetchPreviousVideo(action) {
  const result = yield call(sendQuery, FETCH_PREVIOUS_VIDEO, {
    id: action.id,
    context: action.context,
  });
  const { data } = yield result.json();

  yield put(
    updateVideo(action.id, {
      previousVideo: data.video.previousVideo || {},
    })
  );
}

export const GET_CONTEST_VIDEOS = `
  query($id: ID!) {
    contest(id: $id) {
      id
      videos {
        id
        context
        contest {
          id
        }
        embed_url
        embed_html
        thumbnail_url
        title
        submitter {
          id
          username
        }
        views
      }
    }
  }
`;

export function* fetchContestVideos(action) {
  const result = yield call(sendQuery, GET_CONTEST_VIDEOS, {
    id: action.contest,
  });
  const { data, errors } = yield result.json();

  yield put(addVideos(data.contest.videos));
}

export const GET_LATEST_VIDEOS = `
  query ($limit: Int, $cursor: ID) {
    latestVideos(limit: $limit, cursor: $cursor) {
      id
      context
      thumbnail_url
      title
      embed_url
      embed_html
      submitter {
        id
        username
      }
      views
    }
  }
`;

export function* fetchLatestVideos(action) {
  yield put(setVideosLoading(true));

  const result = yield call(sendQuery, GET_LATEST_VIDEOS, {
    limit: 14,
    cursor: action.cursor,
  });
  const { data, errors } = yield result.json();

  yield put(
    addVideos(
      data.latestVideos.map((video, i) => ({
        ...video,
        tags: [{ id: 'latest', sort: i }],
      }))
    )
  );
  yield put(setVideosLoading(false));
}

export const GET_TRENDING_VIDEOS = `
  query ($limit: Int, $cursor: ID) {
    trendingVideos(limit: $limit, cursor: $cursor) {
      id
      context
      thumbnail_url
      title
      embed_url
      embed_html
      submitter {
        id
        username
      }
      views
    }
  }
`;

export function* fetchTrendingVideos(action) {
  yield put(setVideosLoading(true));

  const result = yield call(sendQuery, GET_TRENDING_VIDEOS, {
    limit: 14,
    cursor: action.cursor,
  });
  const { data, errors } = yield result.json();

  yield put(
    addVideos(
      data.trendingVideos.map((video, i) => ({
        ...video,
        tags: [{ id: 'trending', sort: i }],
      }))
    )
  );

  yield put(setVideosLoading(false));
}

export const GET_USER_VIDEOS = `
  query($id: ID!, $cursor: ID, $limit: Int) {
    videosByUser(id: $id, cursor: $cursor, limit: $limit) {
      id
      context
      thumbnail_url
      title
      embed_url
      embed_html
      submitter {
        id
        username
      }
      views
    }
  }
`;

export function* fetchUserVideos(action) {
  yield put(setVideosLoading(true));

  const result = yield call(sendQuery, GET_USER_VIDEOS, {
    id: action.id,
    limit: 14,
    cursor: action.cursor,
  });
  const { data, errors } = yield result.json();

  yield put(
    addVideos(
      data.videosByUser.map((video, i) => ({
        ...video,
        tags: [{ id: 'trending', sort: i }],
      }))
    )
  );

  yield put(setVideosLoading(false));
}

export const GET_HOME_VIDEOS = `
  query ($stage: String, $limit: Int) {
    contests(stage: $stage) {
      id
      videos {
        id
        context
        contest {
          id
        }
        embed_url
        embed_html  
        thumbnail_url
        title
        submitter {
          id
          username
        }
        views
      }
    }
    latestVideos(limit: $limit) {
      id
      context
      thumbnail_url
      title
      embed_url
      embed_html
      submitter {
        id
        username
      }
      views
    }
    trendingVideos(limit: $limit) {
      id
      context
      thumbnail_url
      title
      embed_url
      embed_html
      submitter {
        id
        username
      }
      views
    }
  }
`;

export function* fetchHomeVideos(action) {
  yield put(setVideosLoading(true));

  const result = yield call(sendQuery, GET_HOME_VIDEOS, {
    stage: 'active',
    limit: 14,
  });
  const { data, errors } = yield result.json();

  // Store contest videos
  for (const contest of data.contests) {
    yield put(addVideos(contest.videos));
  }

  // Store latest videos
  yield put(
    addVideos(
      data.latestVideos.map((video, i) => ({
        ...video,
        tags: [{ id: 'latest', sort: i }],
      }))
    )
  );

  // Store trending videos
  yield put(
    addVideos(
      data.trendingVideos.map((video, i) => ({
        ...video,
        tags: [{ id: 'trending', sort: i }],
      }))
    )
  );

  yield put(setVideosLoading(false));
}

export const GET_NEW_VIDEOS = `
  query($cursor: ID) {
    latestVideos(limit: 14, cursor: $cursor, reverseCursor: true) {
      id
      context
      thumbnail_url
      title
      submitter {
        id
        username
      }
      views
    }
  }
`;

export const getLastVideo = (state) => state.videos.last;

export function* checkNewVideos(action) {
  const lastVideo = yield select(getLastVideo);

  if (lastVideo) {
    const result = yield call(sendQuery, GET_NEW_VIDEOS, {
      cursor: lastVideo.id,
    });
    const { data, errors } = yield result.json();

    if (data.latestVideos.length > 0) {
      yield put(setRefetch(true));
      yield put(clearAlertTarget('/'));
      yield put(addAlert(`${data.latestVideos.length} new videos`, '/'));
    }
  }
}

export default function* videos() {
  yield takeEvery('DO_VOTE', doVote);
  yield takeEvery('FETCH_CONTEST_VIDEOS', fetchContestVideos);
  yield takeEvery('FETCH_VIDEO', fetchVideo);
  yield takeEvery('FETCH_NEXT_VIDEO', fetchNextVideo);
  yield takeEvery('FETCH_PREVIOUS_VIDEO', fetchPreviousVideo);
  yield takeEvery('FETCH_LATEST_VIDEOS', fetchLatestVideos);
  yield takeEvery('FETCH_TRENDING_VIDEOS', fetchTrendingVideos);
  yield takeEvery('FETCH_USER_VIDEOS', fetchUserVideos);
  yield takeEvery('FETCH_HOME_VIDEOS', fetchHomeVideos);
  yield takeEvery('CHECK_NEW_VIDEOS', checkNewVideos);
}
