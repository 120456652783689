// Libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

export const BirthdayInput = ({ id, onChange, value }) => {
  // State
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  // Effects
  useEffect(() => {
    if (value && !day && !month && !year) {
      const date = moment(value);
      setDay(date.date());
      setMonth(date.month() + 1);
      setYear(date.year());
    }
  }, [value]);

  // Handlers
  const callHandler = (day, month, year) => {
    const date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    onChange({
      target: {
        id,
        value: date.toDate(),
      },
    });
  };

  const handleChange = (e) => {
    switch (e.target.id) {
      case id:
        setMonth(e.target.value);
        callHandler(day, e.target.value, year);
        break;
      case 'day':
        setDay(e.target.value);
        callHandler(e.target.value, month, year);
        break;
      case 'year':
        setYear(e.target.value);
        callHandler(day, month, e.target.value);
        break;
    }
  };

  return (
    <div className={classes.wrap}>
      <select
        id={id}
        className={classes.month}
        onChange={handleChange}
        value={month}
      >
        <option value="" disabled hidden>
          Select month...
        </option>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>

      <input
        type="number"
        id="day"
        className={classes.day}
        placeholder="Day"
        min="1"
        max="31"
        value={day}
        onChange={handleChange}
      />

      <input
        type="number"
        id="year"
        className={classes.year}
        placeholder="Year"
        min="1900"
        max={moment().year()}
        value={year}
        onChange={handleChange}
      />
    </div>
  );
};

export default BirthdayInput;
