// Libraries
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { gql, useQuery, useMutation } from '@apollo/client';
import useFirebaseUser from '../../hooks/useFirebaseUser';
import { connect } from 'react-redux';

// Assets
import twitchGlitch from '../../assets/twitch-glitch-white.png';
import twitchLogo from '../../assets/twitch-wordmark-white.png';
import youtubeLogo from '../../assets/yt_logo_mono_dark.png';

// Styles
import classes from './style.module.scss';

// Actions
import { fetchContests } from '../../redux/actions/contests';
import { fetchTwitch, fetchYouTube } from '../../redux/actions/submit';
import { trackSubmitVideo } from '../../redux/actions/analytics';

// Selectors
import { submission } from '../../redux/selectors/contests';

// Components
import ClipList from './components/ClipList';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import TextArea from '../../components/TextArea';
import LinkContentSource from '../../components/LinkContentSource';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';

import SubmitForm from '../../components/SubmitForm';

// Queries
import { GET_VIDEOS } from '../Home';

export const GET_TWITCH_CLIPS = gql`
  query($cursor: String) {
    me {
      id
      username
      twitchLinked
      twitchClips(cursor: $cursor) {
        cursor
        clips {
          id
          embed_url
          title
          thumbnail_url
          type
          view_count
        }
      }
      youtubeLinked
      youtubeClips(cursor: $cursor) {
        cursor
        clips {
          id
          embed_url
          title
          thumbnail_url
          type
        }
      }
    }
    contests(stage: "submission") {
      id
      name
    }
  }
`;

export const SUBMIT_VIDEO = gql`
  mutation(
    $clipId: ID!
    $contestId: ID!
    $description: String!
    $type: String!
  ) {
    submitVideo(
      clipId: $clipId
      contestId: $contestId
      description: $description
      type: $type
    ) {
      id
      embed_url
      thumbnail_url
      title
      description
      submitter {
        id
        videos {
          id
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query {
    me {
      id
      videoCount
    }
  }
`;

export const SubmitPage = ({
  contests,
  user,
  facebook,
  facebookLinked,
  twitch,
  twitchLinked,
  youtube,
  youtubeLinked,
  fetchContests,
  fetchTwitch,
  fetchYouTube,
  trackSubmitVideo,
}) => {
  return (
    <div className={classes.page}>
      <Helmet>
        <title>Submit Video | You Freaking Noob</title>
      </Helmet>
      <h1>Submit Video</h1>

      <SubmitForm />

      <a href="mailto:support@youfreakingnoob.com" target="_blank">
        Need help?
      </a>
    </div>
  );

  // Hooks
  const history = useHistory();

  useEffect(() => {
    fetchContests('submission');
    fetchTwitch();
    fetchYouTube();
  }, []);

  // GraphQL
  const [
    submitVideo,
    { loading: submitLoading, error: submitError },
  ] = useMutation(SUBMIT_VIDEO, {
    onCompleted: (data) => {
      trackSubmitVideo(data.submitVideo.id);
      history.push(`/success/${data.submitVideo.id}`);
    },
    update: (cache, { data: { submitVideo } }) => {
      try {
        const { latestVideos } = cache.readQuery({
          query: GET_VIDEOS,
        });
        cache.writeQuery({
          query: GET_VIDEOS,
          data: {
            latestVideos: [
              submitVideo,
              ...latestVideos.slice(0, latestVideos.length - 1),
            ],
          },
        });
      } catch (e) {
        console.error('Caught', e);
      }

      try {
        const { me } = cache.readQuery({ query: GET_USER });
        cache.writeQuery({
          query: GET_USER,
          data: {
            me: {
              ...me,
              videoCount: me.videoCount + 1,
            },
          },
        });
      } catch (e) {
        console.error('Caught', e);
      }
    },
  });

  // State
  const [clip, setClip] = useState();
  const [desc, setDesc] = useState('');
  const [contest, setContest] = useState('');
  const [agree, setAgree] = useState(false);
  const [tab, setTab] = useState('');
  const [contestError, setContestError] = useState('');
  const [descError, setDescError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [loading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    if (twitchLinked && twitch) {
      setTab('twitch');
      setLoading(false);
    } else if (youtubeLinked && youtube) {
      setTab('youtube');
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [twitchLinked, youtubeLinked]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Short-curcuit if already loading
    if (submitLoading) return;

    if (!contest) return setContestError('You must select a contest');
    if (!desc) return setDescError('You must provide a video description');
    if (!agree)
      return setTermsError('You must agree to the terms to submit a video');

    submitVideo({
      variables: {
        clipId: clip.id,
        contestId: contest,
        description: desc,
        type: clip.type,
      },
    });
  };

  const render = () => {
    // if (error) return <div>{error.message}</div>;
    if (!user || loading) return <Loader />;

    if (!user.username) {
      return (
        <React.Fragment>
          <h2>Select a Username to Submit</h2>
          <Link className={classes.ctaButton} to="/account">
            Set a username <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </React.Fragment>
      );
    }

    if (!user.twitchLinked && !user.youtubeLinked)
      return (
        <React.Fragment>
          <h2>Link an Account to Submit</h2>
          <LinkContentSource />
        </React.Fragment>
      );

    if (!twitch && !youtube)
      return (
        <div>
          We are experiencing an issue with our connection to Twitch and/or
          Youtube at the moment. Please try again in a few minutes.
        </div>
      );

    if (!clip)
      return (
        <div className={classes.step1}>
          <h2>1. Select Clip</h2>

          {twitchLinked && twitch && youtubeLinked && youtube && (
            <div className={classes.tabs}>
              <button
                className={`${classes.tab} ${
                  tab === 'twitch' ? classes.active : ''
                }`}
                onClick={(e) => setTab('twitch')}
              >
                <img src={twitchLogo} alt="Twitch" />
              </button>
              <button
                className={`${classes.tab} ${
                  tab === 'youtube' ? classes.active : ''
                }`}
                onClick={(e) => setTab('youtube')}
              >
                <img src={youtubeLogo} alt="YouTube" />
              </button>
            </div>
          )}

          {tab ? (
            <ClipList
              clips={tab === 'twitch' ? twitch.clips : youtube.clips}
              type={tab}
              loading={tab === 'twitch' ? !twitch.clips : !youtube.clips}
              requestMore={() =>
                tab === 'twitch'
                  ? fetchTwitch(twitch.cursor)
                  : fetchYouTube(youtube.cursor)
              }
              onSelect={(clip) => setClip(clip)}
            />
          ) : (
            <Loader />
          )}
        </div>
      );

    return (
      <div className={classes.step2}>
        <h2>2. Add Info</h2>
        <iframe
          src={`${clip.embed_url}${
            clip.embed_url.includes('https://clips.twitch.tv')
              ? `&parent=${process.env.BASE_DOMAIN}`
              : ''
          }`}
          frameBorder="0"
          scrolling="no"
          allowFullScreen={true}
          width="100%"
          height="316px"
          allow={
            clip.type === 'youtube' &&
            'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          }
        />
        <h3>{clip.title}</h3>

        <form className={classes.form} onSubmit={handleSubmit}>
          <div
            className={`${classes.group} ${contestError ? classes.error : ''}`}
          >
            <label htmlFor="contest">Contest</label>
            <select
              value={contest}
              onChange={(e) => {
                setContest(e.target.value);
                setContestError('');
              }}
            >
              <option value="">Select contest...</option>
              {contests.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            {contestError && (
              <div className={classes.error}>{contestError}</div>
            )}
          </div>
          <div className={`${classes.group} ${descError ? classes.error : ''}`}>
            <label htmlFor="description">
              Tell us why your clip should win
            </label>
            <TextArea
              id="description"
              value={desc}
              onChange={(value) => {
                setDesc(value);
                setDescError('');
              }}
              limit={300}
            />

            {descError && <div className={classes.error}>{descError}</div>}
          </div>
          <div
            className={`${classes.group} ${termsError ? classes.error : ''}`}
          >
            <label htmlFor="confirm">
              <input
                id="confirm"
                type="checkbox"
                value={agree}
                onChange={(e) => {
                  setAgree(e.target.value);
                  setTermsError('');
                }}
              />
              I agree to abide by the{' '}
              <a
                href="https://youfreakingnoob.com/terms-of-service/"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="https://youfreakingnoob.com/contest-rules">
                Contest Rules
              </a>
            </label>

            {termsError && <div className={classes.error}>{termsError}</div>}
          </div>
          <button disabled={submitLoading}>
            {submitLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Submit Video | You Freaking Noob</title>
      </Helmet>
      <h1>Submit Video</h1>

      <SubmitForm />

      <a href="mailto:support@youfreakingnoob.com">Need help?</a>
    </div>
  );
};

export const mapStateToProps = ({ auth, contests, submit }) => ({
  contests: submission(contests),
  user: auth.user,
  facebook: auth.user ? auth.user.facebook : false,
  facebookLinked: !!(auth.user && auth.user.facebook),
  twitch: submit.twitch,
  twitchLinked: submit.twitchLinked,
  youtube: submit.youtube,
  youtubeLinked: submit.youtubeLinked,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchContests: (state) => dispatch(fetchContests(state)),
  fetchTwitch: (cursor) => dispatch(fetchTwitch(cursor)),
  fetchYouTube: (cursor) => dispatch(fetchYouTube(cursor)),
  trackSubmitVideo: (id) => dispatch(trackSubmitVideo(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitPage);
