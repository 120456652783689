// Libraries
import { call, put, select, takeEvery } from 'redux-saga/effects';

// Actions
import { updateUser } from '../../actions/auth';
import { setMetaFlag } from '../../actions/meta';
import {
  fetchMemberships as fetchMembershipsAction,
  setMemberships,
} from '../../actions/memberships';

// Utils
import sendQuery from '../../utils/sendQuery';

export const GET_SUBSCRIPTIONS = `
  query {
    memberships {
      id
      name
      price
      benefitsUrl
      benefitsHtml
      couponHtml
      hidden
      thankYouHeaderText
      thankYouHtml
      thankYouButtonText
      thankYouButtonUrl
      upsellAccept
      upsellDecline
      upsellHeading
      upsellHtml
      upsellId
    }
  }
`;

export function* fetchMemberships(action) {
  yield put(setMetaFlag('membershipsLoading', true));

  const result = yield call(sendQuery, GET_SUBSCRIPTIONS);
  const { data, errors } = yield result.json();

  yield put(setMemberships(data.memberships));

  yield put(setMetaFlag('membershipsLoading', false));
}

export const CREATE_STRIPE_CUSTOMER = `
  mutation {
    createStripeCustomer
  }
`;

export function* createStripeCustomer(action) {
  yield put(setMetaFlag('subscriptionCheckoutLoading', true));

  const result = yield call(sendQuery, CREATE_STRIPE_CUSTOMER);
  const { data, errors } = yield result.json();

  yield put(
    updateUser({
      stripeCustomer: data.createStripeCustomer,
    })
  );

  yield put(setMetaFlag('subscriptionCheckoutLoading', false));
}

export const CREATE_STRIPE_SUBSCRIPTION = `
  mutation ($paymentMethod: ID!, $membershipId: ID!) {
    createSubscription(paymentMethod: $paymentMethod, membershipId: $membershipId)
  }
`;

export function* createStripeSubscription({ paymentMethod, membershipId }) {
  yield put(setMetaFlag('subscriptionLoading', true));

  const result = yield call(sendQuery, CREATE_STRIPE_SUBSCRIPTION, {
    paymentMethod,
    membershipId,
  });
  const { data, errors } = yield result.json();

  // Handle errors
  if (errors) {
    yield put(setMetaFlag('subscriptionError', errors[0].message));
    yield put(setMetaFlag('subscriptionSuccess', false));
  } else if (data && data.createSubscription) {
    yield put(setMetaFlag('subscriptionSuccess', true));

    yield put(fetchMembershipsAction());
    const membership = yield select((state) =>
      state.memberships.find((membership) => membership.id === membershipId)
    );
    const user = yield select((state) => state.auth.user);
    yield put(
      updateUser({
        subscriptions: [
          ...user.subscriptions,
          {
            id: 'temp',
            membership,
          },
        ],
      })
    );
  }

  yield put(setMetaFlag('subscriptionLoading', false));
}

export default function*() {
  yield takeEvery('FETCH_MEMBERSHIPS', fetchMemberships);
  yield takeEvery('CREATE_STRIPE_CUSTOMER', createStripeCustomer);
  yield takeEvery('CREATE_STRIPE_SUBSCRIPTION', createStripeSubscription);
}
