// Libraries
import { call, select, put, takeEvery } from 'redux-saga/effects';

// Actions
import { trackSubmitVideo } from '../../actions/analytics';
import {
  appendFacebook,
  appendTwitch,
  appendYouTube,
  setSubmitLoading,
  setSubmitSuccess,
  setFacebook,
  setFacebookLoading,
  setFacebookInitial,
  setTwitch,
  setTwitchLinked,
  setTwitchLoading,
  setTwitchInitial,
  setYouTube,
  setYouTubeLinked,
  setYouTubeLoading,
  setYouTubeInitial,
} from '../../actions/submit';
import { prependLatest } from '../../actions/videos';

// Utils
import sendQuery from '../../utils/sendQuery';

export const GET_FACEBOOK_CLIPS = `
  query($cursor: String, $token: String!) {
    me {
      id
      facebookClips(cursor: $cursor, token: $token) {
        cursor
        clips {
          id
          embed_html
          title
          thumbnail_url
          type
        }
      }
    }
  }
`;

export const facebookToken = ({ auth }) =>
  auth.user && auth.user.facebook ? auth.user.facebook.accessToken : null;

export function* fetchFacebook(action) {
  yield put(setFacebookLoading(true));

  const token = yield select(facebookToken);

  if (!token) {
    yield put(setFacebookLoading(false));
    return;
  }

  const result = yield call(sendQuery, GET_FACEBOOK_CLIPS, {
    cursor: action.cursor,
    token,
  });
  const { data, errors } = yield result.json();

  if (data && data.me) {
    action.cursor
      ? yield put(appendFacebook(data.me.facebookClips))
      : yield put(setFacebook(data.me.facebookClips));
  }

  yield put(setFacebookInitial(true));
  yield put(setFacebookLoading(false));
}

export const GET_TWITCH_CLIPS = `
  query($cursor: String) {
    me {
      id
      twitchLinked
      twitchClips(cursor: $cursor) {
        cursor
        clips {
          id
          embed_url
          title
          thumbnail_url
          type
          view_count
        }
      }
    }
  }
`;

export function* fetchTwitch(action) {
  yield put(setTwitchLoading(true));

  const result = yield call(sendQuery, GET_TWITCH_CLIPS, {
    cursor: action.cursor,
  });
  const { data, errors } = yield result.json();

  if (data && data.me) {
    action.cursor
      ? yield put(appendTwitch(data.me.twitchClips))
      : yield put(setTwitch(data.me.twitchClips));
  }

  yield put(setTwitchInitial(true));
  yield put(setTwitchLoading(false));
}

export const GET_YOUTUBE_CLIPS = `
  query($cursor: String) {
    me {
      id
      youtubeLinked
      youtubeClips(cursor: $cursor) {
        cursor
        clips {
          id
          embed_url
          title
          thumbnail_url
          type
        }
      }
    }
  }
`;

export function* fetchYouTube(action) {
  yield put(setYouTubeLoading(true));

  const result = yield call(sendQuery, GET_YOUTUBE_CLIPS, {
    cursor: action.cursor,
  });
  const { data, errors } = yield result.json();

  if (data && data.me) {
    yield put(setYouTubeLinked(data.me.youtubeLinked));
    action.cursor
      ? yield put(appendYouTube(data.me.youtubeClips))
      : yield put(setYouTube(data.me.youtubeClips));
  }

  yield put(setYouTubeInitial(true));
  yield put(setYouTubeLoading(false));
}

export const DO_SUBMIT = `
  mutation(
    $clipId: ID!
    $contestId: ID!
    $title: String!
    $description: String!
    $type: String!
    $token: String
  ) {
    submitVideo(
      clipId: $clipId
      contestId: $contestId
      title: $title
      description: $description
      type: $type
      token: $token
    ) {
      id
      title
      description
      embed_url
      hasVoted
      contest {
        id
        canVote
      }
      submitter {
        id
        username
      }
    }
  }
`;

export function* submitClip(action) {
  yield put(setSubmitLoading(true));

  const token = yield select(facebookToken);

  // Submit the provided clip
  const result = yield call(sendQuery, DO_SUBMIT, {
    clipId: action.clipId,
    contestId: action.contestId,
    title: action.title,
    description: action.description,
    type: action.clipType,
    token,
  });
  const { data, errors } = yield result.json();

  // Add the clip to the videos array tagged as the most recent latest video
  yield put(prependLatest(data.submitVideo));

  // Track the video submission
  yield put(trackSubmitVideo(data.submitVideo.id));

  // End loading and signal success
  yield put(setSubmitLoading(false));
  yield put(setSubmitSuccess(true));
}

export function* resetSubmit() {
  yield put(setSubmitSuccess(false));
}

export default function*() {
  yield takeEvery('FETCH_FACEBOOK', fetchFacebook);
  yield takeEvery('FETCH_TWITCH', fetchTwitch);
  yield takeEvery('FETCH_YOUTUBE', fetchYouTube);
  yield takeEvery('RESET_SUBMIT', resetSubmit);
  yield takeEvery('SUBMIT_CLIP', submitClip);
}
