export const addTournament = (tournament) => ({
  type: 'ADD_TOURNAMENT',
  tournament,
});

export const fetchTournaments = (includePast = true) => ({
  type: 'FETCH_TOURNAMENTS',
  includePast,
});

export const fetchTournament = (id) => ({
  type: 'FETCH_TOURNAMENT',
  id,
});

export const fetchEntry = (tournament) => ({
  type: 'FETCH_ENTRY',
  tournament,
});

export const setTournaments = (tournaments) => ({
  type: 'SET_TOURNAMENTS',
  tournaments,
});

export const setEntry = (entry) => ({
  type: 'SET_ENTRY',
  entry,
});

export const recordEntry = (tournament, payment, paymentType) => ({
  type: 'RECORD_ENTRY',
  tournament,
  payment,
  paymentType,
});

export const recordEntryAttempt = (email, challenge) => ({
  type: 'RECORD_ENTRY_ATTEMPT',
  email,
  challenge,
});

export const resetEntry = () => ({
  type: 'RESET_ENTRY',
});

export const linkEntry = (tournament, entry) => ({
  type: 'LINK_ENTRY',
  tournament,
  entry,
});

export const resetEntryDetails = () => ({
  type: 'RESET_ENTRY_DETAILS',
});

export const updateEntry = (
  tournament,
  activisionId,
  team,
  platform,
  stream
) => ({
  type: 'UPDATE_ENTRY',
  tournament,
  activisionId,
  team,
  platform,
  stream,
});
