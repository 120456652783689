// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import { fetchLeaderboard } from '../../redux/actions/leaderboard';

// Styles
import classes from './style.module.scss';

// Components
import Loader from '../../components/Loader';

export const LeaderboardPage = ({
  leaderboard = [],
  loading,
  fetchLeaderboard,
}) => {
  // State
  const [timeframe, setTimeframe] = useState('all');

  // Effects
  useEffect(() => {
    fetchLeaderboard('warzone', timeframe);
  }, [timeframe]);

  return (
    <div className={classes.page}>
      <div className={classes.wrapper}>
        <h1>Warzone Leaderboard</h1>

        <div className={classes.leaderboard}>
          <div className={classes.filters}>
            <select
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
            >
              <option value="all">All Time</option>
              <option value="week">This week</option>
              <option value="month">This month</option>
              <option value="year">This year</option>
            </select>
          </div>

          <div className={classes.table}>
            <table>
              <tr>
                <th>#</th>
                <th>Player</th>
                <th>Wins</th>
                <th>Losses</th>
                <th>Win %</th>
                <th>Longest Streak</th>
                <th>Score</th>
              </tr>
              {loading ? (
                <tr>
                  <td className={classes.loader} colspan="7">
                    <Loader color="#424242" />
                  </td>
                </tr>
              ) : (
                leaderboard.map((row, i) => (
                  <tr>
                    <td>{i + 1}.</td>
                    <td>{row.user.username}</td>
                    <td>{row.wins}</td>
                    <td>{row.losses}</td>
                    <td>{Math.floor(row.winrate * 100)}%</td>
                    <td>{row.streak}</td>
                    <td>{row.kills}</td>
                  </tr>
                ))
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ leaderboard, meta }) => ({
  leaderboard,
  loading: meta.globalLeaderboardLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLeaderboard: (game, timeframe) =>
    dispatch(fetchLeaderboard(game, timeframe)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardPage);
