export const trackCheckout = (id, product) => ({
  type: 'TRACK_CHALLENGE_CHECKOUT',
  id,
  product,
});

export const trackPurchase = (id, product) => ({
  type: 'TRACK_CHALLENGE_PURCHASE',
  id,
  product,
});

export const trackJoinUser = (id) => ({
  type: 'TRACK_JOIN_USER',
  id,
});

export const trackUpdateUser = (id) => ({
  type: 'TRACK_UPDATE_USER',
  id,
});

export const trackNextVideo = (fromId, toId) => ({
  type: 'TRACK_NEXT_VIDEO',
  fromId,
  toId,
});

export const trackScrollRibbon = (ribbon, direction) => ({
  type: 'TRACK_SCROLL_RIBBON',
  ribbon,
  direction,
});

export const trackSubmitVideo = (id) => ({
  type: 'TRACK_SUBMIT_VIDEO',
  id,
});

export const trackVoteVideo = (id) => ({
  type: 'TRACK_VOTE_VIDEO',
  id,
});

export const trackWatchVideo = (id, context) => ({
  type: 'TRACK_WATCH_VIDEO',
  id,
  context,
});
