// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

export const LeftArrow = ({ onClick, onScroll }) => {
  const handleScroll = (e) => {
    onScroll();
    onClick(e);
  };

  return (
    <button className={classes.arrow} onClick={handleScroll}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
  );
};

export default LeftArrow;
