// Libraries
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createMiddleware as createBeaconMiddleware } from 'redux-beacon';
import GoogleAnalyticsGtag from '@redux-beacon/google-analytics-gtag';
import logger from '@redux-beacon/logger';

// Reducers
import alerts from './reducers/alerts';
import auth from './reducers/auth';
import comments from './reducers/comments';
import contests from './reducers/contests';
import creditProducts from './reducers/creditProducts';
import filters from './reducers/filters';
import leaderboard from './reducers/leaderboard';
import memberships from './reducers/memberships';
import meta from './reducers/meta';
import router from './reducers/router';
import submit from './reducers/submit';
import tournaments from './reducers/tournaments';
import videos from './reducers/videos';

// Sagas
import rootSaga from './sagas';

// Events
import eventsMap from './events';

export default () => {
  const appReducer = combineReducers({
    alerts,
    auth,
    comments,
    contests,
    creditProducts,
    filters,
    leaderboard,
    memberships,
    meta,
    router,
    submit,
    tournaments,
    videos,
  });

  const reducer = (state, action) => {
    if (action.type === 'LOGOUT') {
      state = undefined;
    }

    return appReducer(state, action);
  };

  // Integrate devtools
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Setup Sage
  const sagaMiddleware = createSagaMiddleware();

  // Setup Beacon
  const ga = GoogleAnalyticsGtag('UA-70662-50');
  const beaconMiddleware = createBeaconMiddleware(
    process.env.BASE_DOMAIN === 'localhost' ||
      process.env.BASE_DOMAIN === 'yfn-dev.web.app'
      ? {}
      : eventsMap,
    ga,
    { logger }
  );

  const middleware = composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(beaconMiddleware)
  );

  const store = createStore(reducer, middleware);

  sagaMiddleware.run(rootSaga);

  return store;
};
