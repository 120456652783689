// Libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Style
import classes from './style.module.scss';

// Assets
import activisionImage from '../../assets/activision-id-screenshot.jpg';

// Selectors
import { currentTournament } from '../../redux/selectors/tournaments';

// Actions
import {
  fetchTournament,
  fetchEntry,
  resetEntryDetails,
  updateEntry,
} from '../../redux/actions/tournaments';

// Components
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Modal from 'react-modal';

export const EntryDetails = ({
  history,
  match,
  entryLoading,
  finalizing,
  success,
  tournament,
  tournamentsLoading,
  user,
  userLoading,
  fetchTournament,
  resetEntryDetails,
  fetchEntry,
  updateEntry,
}) => {
  // State
  const [activision, setActivision] = useState('');
  const [team, setTeam] = useState('');
  const [platform, setPlatform] = useState('');
  const [stream, setStream] = useState('');
  const [idHelp, setIdHelp] = useState(false);

  const [activisionError, setActivisionError] = useState('');
  const [teamError, setTeamError] = useState('');
  const [teamWarning, setTeamWarning] = useState(false);
  const [platformError, setPlatformError] = useState('');
  const [streamError, setStreamError] = useState('');

  const [loading, setLoading] = useState(false);

  const [teamValidationDelay, setTeamValidationDelay] = useState(-1);

  // Effects
  useEffect(() => {
    if (!tournament) fetchTournament(match.params.id);
    resetEntryDetails();
  }, []);

  useEffect(() => {
    if (tournament && user && !user.entry) fetchEntry(tournament.id);
  }, [tournament, user]);

  useEffect(() => {
    if (user && user.entry && user.entry.activisionId)
      history.push(`/entry/success/${match.params.id}`);
  }, [user]);

  useEffect(() => {
    if (success) history.push(`/entry/success/${match.params.id}`);
  }, [success]);

  useEffect(() => {
    if (!userLoading && !user)
      history.push(
        `/login?r=${encodeURIComponent(`/entry/details/${match.params.id}`)}`
      );

    if (user) setActivision(user.activisionId || '');
  }, [user, userLoading]);

  // Utilities
  const validId = async (id, platform) => {
    const result = await fetch(process.env.GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query: `query($id: ID!, $platform: String!) { validActivision(id: $id, platform: $platform) }`,
        variables: {
          id,
          platform,
        },
      }),
    });
    const resultData = await result.json();

    return resultData.data.validActivision;
  };

  const validateTeam = async (name) => {
    const result = await fetch(process.env.GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query: `query($tournament: ID!, $name: String!) { teamExists(tournament: $tournament, name: $name) }`,
        variables: {
          tournament: tournament.id,
          name,
        },
      }),
    });
    const resultData = await result.json();

    return resultData.data.teamExists;
  };

  // Handlers
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let hasTeamWarning = teamWarning;
    setTeamWarning(false);

    // Validate form
    let hasError = false;

    if (!platform) {
      hasError = true;
      setPlatformError('You must select a platform for this tournament');
    }

    if (!activision) {
      hasError = true;
      await setActivisionError('You must provide your Gamertag');
    } else if (platform) {
      const result = await validId(activision, platform);

      if (result) {
        hasError = true;
        setActivisionError(result);
      }
    }
    if (!tournament.individual && !team) {
      hasError = true;
      setTeamError('You must provide a team name for this tournament');
    }
    if (platform === 'pc' && !stream) {
      hasError = true;
      setStreamError('You must provide a stream URL');
    }

    if (!tournament.individual && hasTeamWarning) {
      hasError = true;
      setTeamWarning(true);
    }

    // If there are any errors, short-circuit
    if (hasError) {
      console.log('error');
      setLoading(false);
      return;
    }

    fbq('track', 'CompleteRegistration', {
      id: tournament.id,
      content_name: tournament.name,
    });

    updateEntry(match.params.id, activision, team, platform, stream);
    setLoading(false);
  };

  const handleActivision = (e) => {
    setActivisionError('');
    setActivision(e.target.value);
  };

  const handleTeam = (e) => {
    const teamname = e.target.value;

    setTeamError('');
    setTeam(teamname);

    clearInterval(teamValidationDelay);
    let delay = setTimeout(async () => {
      if (!teamname) {
        setTeamWarning(false);
        return;
      }

      const exists = await validateTeam(teamname);

      if (!exists) {
        setTeamWarning(true);
      } else {
        setTeamWarning(false);
      }
    }, 500);
    setTeamValidationDelay(delay);
  };

  const handleTeamWarning = (e) => {
    e.preventDefault();
    setTeamWarning(false);
  };

  const handlePlatform = (e) => {
    setPlatformError('');
    setPlatform(e.target.value);
  };

  const handleStream = (e) => {
    setStreamError('');
    setStream(e.target.value);
  };

  return (
    <div className={classes.page}>
      <div className={classes.wrap}>
        <h1>Entry Details</h1>

        <div className={classes.formWrap}>
          {tournamentsLoading || entryLoading ? (
            <Loader button={true} />
          ) : !tournament ? (
            <h2>Invalid Tournament</h2>
          ) : (
            <React.Fragment>
              <h2>Just a few more details...</h2>

              <form className={classes.form} onSubmit={handleSubmit}>
                <div
                  className={`${classes.group} ${
                    platformError ? classes.error : ''
                  }`}
                >
                  <label htmlFor="platform">Platform</label>
                  <select value={platform} onChange={handlePlatform}>
                    <option value="" disabled hidden>
                      Select your platform...
                    </option>
                    <option value="playstation">PlayStation</option>
                    <option value="xbox">Xbox</option>
                    <option value="pc">PC</option>
                  </select>
                  {platformError && (
                    <div className={classes.error}>{platformError}</div>
                  )}
                </div>

                <div
                  className={`${classes.group} ${
                    activisionError ? classes.error : ''
                  }`}
                >
                  <label htmlFor="activision">
                    Enter Gamertag for your Platform{' '}
                    <small>(this is NOT your Activision ID)</small>
                  </label>
                  <input
                    id="activision"
                    type="text"
                    value={activision}
                    onChange={handleActivision}
                    placeholder="MyPlayerId"
                  />
                  {activisionError && (
                    <div
                      className={classes.error}
                      dangerouslySetInnerHTML={{ __html: activisionError }}
                    />
                  )}
                </div>

                {!tournament.individual && (
                  <div
                    className={`${classes.group} ${
                      teamError ? classes.error : ''
                    } ${teamWarning ? classes.warning : ''}`}
                  >
                    <label htmlFor="team">Team</label>
                    <input
                      id="team"
                      type="text"
                      value={team}
                      onChange={handleTeam}
                      placeholder="Council_of_Noobs"
                    />
                    {teamError && (
                      <div className={classes.error}>{teamError}</div>
                    )}
                    {teamWarning && (
                      <div className={classes.warning}>
                        There is no team with this name,{' '}
                        <button
                          type="button"
                          onClick={handleTeamWarning}
                          className={classes.link}
                        >
                          create new
                        </button>
                        ? If your teammates have already registered, please
                        check the spelling of your team name with them to ensure
                        it matches.
                      </div>
                    )}
                  </div>
                )}

                {platform === 'pc' && (
                  <div
                    className={`${classes.group} ${
                      streamError ? classes.error : ''
                    }`}
                  >
                    <label htmlFor="stream">
                      Stream Link <small>(PC Players must stream event)</small>
                    </label>
                    <input
                      id="stream"
                      type="text"
                      value={stream}
                      onChange={handleStream}
                      placeholder="https://youtube.com/youfreakingnoob"
                    />
                    {streamError && (
                      <div className={classes.error}>{streamError}</div>
                    )}
                  </div>
                )}

                <button disabled={loading || finalizing}>
                  {loading || finalizing ? (
                    <Loader button={true} />
                  ) : (
                    'Complete Entry'
                  )}
                </button>
              </form>
            </React.Fragment>
          )}
        </div>
      </div>

      <Modal
        isOpen={idHelp}
        onRequestClose={() => setIdHelp(false)}
        className={classes.modal}
        overlayClassName={classes.overlay}
      >
        <button
          className={classes.close}
          type="button"
          onClick={() => setIdHelp(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h1>Find your Activision ID</h1>
        <img src={activisionImage} alt="" />
      </Modal>
    </div>
  );
};

export const mapStateToProps = ({ auth, meta, tournaments }, { match }) => ({
  entryLoading: meta.entryLoading,
  finalizing: meta.finalizingEntryDetails,
  success: meta.finalizeEntryDetailsSuccess,
  tournament: currentTournament(match.params.id, tournaments),
  tournamentsLoading: meta.tournamentsLoading,
  user: auth.user,
  userLoading: meta.userLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchTournament: (id) => dispatch(fetchTournament(id)),
  resetEntryDetails: () => dispatch(resetEntryDetails()),
  fetchEntry: (tournament) => dispatch(fetchEntry(tournament)),
  updateEntry: (tournament, activisionId, team, platform, stream) =>
    dispatch(updateEntry(tournament, activisionId, team, platform, stream)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryDetails);
