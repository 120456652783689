// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import MoonLoader from 'react-spinners/MoonLoader';
import Loader from '../Loader';
import Video from '../Video';

export const VideoList = ({
  children,
  loading,
  type,
  videos,
  onDelete,
  fetchMore,
  hasMore,
}) => {
  return (
    <div className={classes.videos}>
      {videos && videos.length > 0 ? (
        <React.Fragment>
          {videos.map((video) => (
            <Video
              key={video.id}
              context={type}
              video={video}
              onDelete={onDelete}
            />
          ))}

          {fetchMore && hasMore && (
            <button className={classes.more} onClick={fetchMore}>
              <div
                className={classes.icon}
                style={{ marginBottom: loading ? '27px' : 0 }}
              >
                {loading ? (
                  <MoonLoader color={'#e8e8e8'} size={78} />
                ) : (
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                )}
              </div>
              <div className={classes.text}>{loading ? '' : 'Load More'}</div>
            </button>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>{!loading && children}</React.Fragment>
      )}
    </div>
  );
};

export default VideoList;
