export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'ADD_COMMENT':
      return [...state, action.comment];
    case 'ADD_COMMENTS':
      return [...state, ...action.comments];
    default:
      return state;
  }
};
