import firebase from '../../firebase';

export default async (query, variables = {}) => {
  let token = null;
  if (firebase.auth().currentUser) {
    try {
      token = await firebase.auth().currentUser.getIdToken(true);
    } catch (e) {}
  }

  return await new Promise((resolve, reject) => {
    const worker = new Worker('../../workers/sendQuery.js');
    worker.postMessage({
      token,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    worker.onmessage = (e) => {
      resolve({
        json: () => e.data,
      });
    };
  });
};
