// Libraries
import React, { useState } from 'react';

// Styles
import classes from './style.module.scss';

// Assets
import noobCredits from '../../assets/noob-credits-white.png';

// Components
import CheckoutForm from '../../components/CheckoutForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Modal from 'react-modal';

export const BuyModal = ({
  isOpen,
  onRequestClose,
  products,
  onPaymentComplete,
}) => {
  // State
  const [checkout, setCheckout] = useState(false);
  const [productId, setProductId] = useState(products[0].id);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // Handlers
  const handlePaymentSuccess = (id, type) => {
    onPaymentComplete(id, type);
    setPaymentSuccess(true);
  };

  return (
    <Modal
      className={classes.modal}
      overlayClassName={classes.overlay}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <h2>
        Buy <img src={noobCredits} alt="noob credits" />
      </h2>
      <button className={classes.close} onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {!paymentSuccess ? (
        <form className={classes.form}>
          <div className={classes.group}>
            <label htmlFor="product">Token Package</label>
            <select
              disabled={checkout}
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
            >
              {products.map(({ id, label }) => (
                <option value={id}>{label}</option>
              ))}
            </select>
          </div>

          {!checkout && !paymentSuccess ? (
            <button onClick={() => setCheckout(true)}>Checkout</button>
          ) : (
            <div className={classes.checkout}>
              <header>
                <h3>Checkout</h3>
              </header>

              <CheckoutForm
                product={products.find((p) => p.id === productId)}
                onSuccess={handlePaymentSuccess}
                processing={false}
              />
            </div>
          )}
        </form>
      ) : (
        <div className={classes.success}>
          <p>
            <strong>Success!</strong> You may now close this window.
          </p>
          <button className={classes.button} onClick={onRequestClose}>
            Close
          </button>
        </div>
      )}
    </Modal>
  );
};

export default BuyModal;
