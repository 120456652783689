export const fetchContest = (id) => ({
  type: 'FETCH_CONTEST',
  id,
});

export const fetchContests = (state) => ({
  type: 'FETCH_CONTESTS',
  state,
});

export const fetchMoreContestVideos = (id, cursor) => ({
  type: 'FETCH_MORE_CONTEST_VIDEOS',
  id,
  cursor,
});

export const mergeContestVideos = (id, videos) => ({
  type: 'MERGE_CONTEST_VIDEOS',
  id,
  videos,
});

export const setContests = (contests) => ({
  type: 'SET_CONTESTS',
  contests,
});
