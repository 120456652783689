// Libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import firebase from '../../firebase';

// Styles
import cardStyle from './style.js';
import classes from './style.module.scss';

// Components
import Loader from '../Loader';

export const CheckoutForm = ({
  product = {},
  processing,
  onSuccess,
  user,
  paypalEnabled = false,
}) => {
  // Hooks
  const elements = useElements();
  const stripe = useStripe();

  // State
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  // Effects

  // On initial render, setup Stripe intent and payment request
  useEffect(() => {
    // Create intent on server and store the returned secret
    const createPaymentIntent = firebase
      .functions()
      .httpsCallable('createPaymentIntent');
    createPaymentIntent({
      product: product.product || product.id,
      description: product.label,
    }).then((result) => {
      setClientSecret(result.data);
    });

    // Create client-side payment request
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: product.label,
        amount: product.price * 100,
        description: product.label,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      // if (result) setPaymentRequest(pr);
    });
  }, []);

  // Once the payment request and intent are ready, setup payment request handler
  useEffect(() => {
    if (paymentRequest && clientSecret) {
      paymentRequest.on('paymentmethod', async (ev) => {
        const payload = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (payload.error) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          if (payload.paymentIntent.status === 'requires_action') {
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              setError(error.message);
            } else {
              onSuccess(payload.paymentIntent.id, 'stripe');
            }
          } else {
            onSuccess(payload.paymentIntent.id, 'stripe');
          }
        }
      });
    }
  }, [paymentRequest, clientSecret]);

  // Render paypal buttons
  useEffect(() => {
    setTimeout(() => {
      window.paypal
        .Buttons({ createOrder, onApprove })
        .render('#paypal-button-container');
    }, 0);
  }, []);

  // Handlers
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            description: `Tournament Entry: ${product.label}`,
            soft_descriptor: `COD Tournament Entry`,
            value: product.price,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    setLoading(true);
    return actions.order.capture().then((details) => {
      onSuccess(details.id, 'paypal');
      setLoading(false);
    });
  };

  const handleCardInput = (e) => {
    setError('');
  };

  // Handle standard card payment form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.username,
          email: user.email,
        },
      },
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setLoading(false);
    } else {
      onSuccess(payload.paymentIntent.id, 'stripe');
      setError(null);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {!paymentRequest && !clientSecret ? (
        <Loader button={true} />
      ) : (
        <React.Fragment>
          {paymentRequest ? (
            <div className={classes.paymentRequest}>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </div>
          ) : (
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.cardWrap}>
                <CardElement
                  id="card-element"
                  options={cardStyle}
                  onChange={handleCardInput}
                />
              </div>

              <button disabled={loading || processing}>
                {loading || processing ? <Loader button={true} /> : 'Enter Now'}
              </button>
            </form>
          )}
        </React.Fragment>
      )}

      {paypalEnabled && (
        <React.Fragment>
          <div className={classes.or}>
            <div className={classes.inner}>or</div>
          </div>

          <div id="paypal-button-container">&nbsp;</div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(CheckoutForm);
