// Libraries
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';

// Actions
import {
  connectFacebook,
  revokeFacebook,
  revokeTwitch,
  revokeYoutube,
} from '../../redux/actions/auth';

// Styles
import classes from './style.module.scss';

// Components
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';

export const GET_AUTH_URL = gql`
  query {
    twitchOAuthURL
    youtubeOAuthURL
  }
`;

export const LinkContentSource = ({
  isAdmin,
  facebook,
  twitch,
  youtube,
  connectFacebook,
  revokeFacebook,
  revokeTwitch,
  revokeYoutube,
  showFacebook = true,
  showTwitch = true,
  showYouTube = true,
  hideLinked = false,
  userLoading,
}) => {
  // Loading State
  const [revokingFacebook, setRevokingFacebook] = useState(false);
  const [revokingTwitch, setRevokingTwitch] = useState(false);
  const [revokingYoutube, setRevokingYoutube] = useState(false);

  // Stop loading when facebook state changes
  useEffect(() => {
    setRevokingFacebook(false);
  }, [facebook]);

  // Stop loading when twitch state changes
  useEffect(() => {
    setRevokingTwitch(false);
  }, [twitch]);

  // Stop loading when youtube state changes
  useEffect(() => {
    setRevokingYoutube(false);
  }, [youtube]);

  // GraphQL
  const { loading, error, data } = useQuery(GET_AUTH_URL);

  // Handlers
  const handleLinkFacebook = (e) => {
    connectFacebook();
  };

  const handleRevokeFacebook = (e) => {
    setRevokingFacebook(true);
    revokeFacebook();
  };

  const handleRevokeTwitch = (e) => {
    setRevokingTwitch(true);
    revokeTwitch();
  };

  const handleRevokeYoutube = (e) => {
    setRevokingYoutube(true);
    revokeYoutube();
  };

  return (
    <div className={classes.wrapper}>
      {loading || userLoading ? (
        <Loader />
      ) : (
        <div className={classes.innerWrap}>
          {showFacebook && (
            <React.Fragment>
              <button
                className={`${classes.facebook} ${
                  facebook ? classes.disabled : ''
                }`}
                onClick={handleLinkFacebook}
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className={classes.logo}
                />
                <div className={classes.text}>
                  {facebook ? 'Connected!' : 'Connect Facebook'}
                </div>
              </button>

              {facebook && (
                <button
                  className={classes.disconnect}
                  onClick={handleRevokeFacebook}
                >
                  {revokingFacebook ? 'Revoking...' : 'Disconnect Facebook'}
                </button>
              )}
            </React.Fragment>
          )}

          {showTwitch && (
            <React.Fragment>
              <a
                href={twitch ? '#' : data.twitchOAuthURL}
                className={`${classes.twitch} ${
                  twitch ? classes.disabled : ''
                }`}
              >
                <FontAwesomeIcon icon={faTwitch} className={classes.logo} />
                <div className={classes.text}>
                  {twitch ? 'Connected!' : 'Connect Twitch'}
                </div>
              </a>

              {twitch && (
                <button
                  className={classes.disconnect}
                  onClick={handleRevokeTwitch}
                >
                  {revokingTwitch ? 'Revoking...' : 'Disconnect Twitch'}
                </button>
              )}
            </React.Fragment>
          )}

          {showYouTube && (
            <React.Fragment>
              <a
                href={youtube ? '#' : data.youtubeOAuthURL}
                className={`${classes.youtube} ${
                  youtube ? classes.disabled : ''
                }`}
              >
                <FontAwesomeIcon icon={faYoutube} className={classes.logo} />
                <div className={classes.text}>
                  {youtube ? 'Connected!' : 'Connect YouTube'}
                </div>
              </a>

              {youtube && (
                <button
                  className={classes.disconnect}
                  onClick={handleRevokeYoutube}
                >
                  {revokingYoutube ? 'Revoking...' : 'Disconnect YouTube'}
                </button>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = ({ auth, meta }) => ({
  isAdmin: auth.user ? auth.user.isAdmin : false,
  facebook: auth.user ? auth.user.facebookLinked : false,
  twitch: auth.user ? auth.user.twitchLinked : false,
  youtube: auth.user ? auth.user.youtubeLinked : false,
  userLoading: meta.userLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  connectFacebook: () => dispatch(connectFacebook()),
  revokeFacebook: () => dispatch(revokeFacebook()),
  revokeTwitch: () => dispatch(revokeTwitch()),
  revokeYoutube: () => dispatch(revokeYoutube()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkContentSource);
