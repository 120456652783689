export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return [
        ...state,
        {
          id: state.length,
          message: action.message,
          target: action.target,
        },
      ];
      break;
    case 'CLEAR_ALERT':
      return state.filter(({ id }) => id !== action.id);
    case 'CLEAR_ALERT_TARGET':
      return state.filter(({ target }) => target !== action.target);
    case 'CLEAR_ALERTS':
      return [];
    default:
      return state;
  }
};
