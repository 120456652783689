// Libraries
import React, { useEffect } from 'react';
import firebase from '../../firebase';
import { connect } from 'react-redux';

// Actions
import { logout } from '../../redux/actions/auth';

// Components
import { Redirect, useHistory } from 'react-router-dom';

export const LogoutPage = ({ logout }) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await firebase.auth().signOut();
      logout();
      history.replace('/');
    })();
  });

  return <div />;
};

export const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  undefined,
  mapDispatchToProps
)(LogoutPage);
