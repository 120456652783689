// Libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

// Assets
import noobCredits from '../../assets/noob-credits-white.png';
import stripeBadge from '../../assets/powered_by_stripe.png';

// Styles
import classes from './style.module.scss';

// Actions
import {
  completeCreditPurchase,
  fetchCreditProducts,
} from '../../redux/actions/creditProducts';
import { fetchTournaments, recordEntry } from '../../redux/actions/tournaments';

// Selectors
import {
  currentTournament,
  currentTournaments,
  activeTournaments,
  openTournaments,
} from '../../redux/selectors/tournaments';

// Components
import BuyModal from '../../components/BuyModal';
import CheckoutForm from '../../components/CheckoutForm';
import Loader from '../../components/Loader';
import TournamentDetails from './components/TournamentDetails';
import TournamentSelect from './components/TournamentSelect';

export const EntryPage = ({
  creditProducts,
  entryError,
  entryLoading,
  entrySuccess,
  entered,
  tournaments,
  user,
  userLoading,
  completeCreditPurchase,
  fetchCreditProducts,
  fetchTournaments,
  recordEntry,
}) => {
  // Hooks
  const history = useHistory();
  const { id = '' } = useParams();

  // State
  const [checkout, setCheckout] = useState(false);
  const [tournament, setTournament] = useState(null);

  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState('');

  const [showBuy, setShowBuy] = useState(false);

  // Effects
  useEffect(() => {
    if (tournaments.length === 0) fetchTournaments();
    if (creditProducts.length === 0) fetchCreditProducts();
  }, []);

  useEffect(() => {
    if (!userLoading && !user)
      history.replace(`/register?r=${encodeURIComponent(`/entry/${id}`)}`);
  }, [user, userLoading]);

  useEffect(() => {
    if (tournaments.length > 0) {
      const t = tournaments.find((t) => t.id === id);
      if (t && moment(t.closeTime).isBefore(moment()))
        history.replace(`/tournament/${id}`);

      setTournament(currentTournament(id, tournaments));
    }
  }, [id, tournaments]);

  useEffect(() => {
    if (entrySuccess) history.push(`/entry/details/${id}`);
  }, [entrySuccess]);

  // Handlers
  const handleTournament = (e) => {
    history.replace(`/entry/${e.target.value}`);
  };

  const handleTerms = (e) => {
    setTermsError('');
    setTerms(e.target.checked);
  };

  const handlePayment = (paymentId, method) => {
    completeCreditPurchase(paymentId, method);
  };

  const handlePaymentSuccess = (paymentId, method) => {
    fbq('track', 'Purchase', {
      content_ids: id,
      content_name: tournament.name,
      content_type: 'tournament',
      num_items: 1,
      currency: 'USD',
      value: tournament.product.price,
    });
    recordEntry(tournament.id, paymentId, method);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (!terms) {
      setTermsError('You must agree to the terms to enter');
      return;
    }

    // If free, record and redirect
    if (tournament.product.price === 0) {
      handlePaymentSuccess('', 'free');
    } else {
      // else, record credit payment
      // handlePaymentSuccess('', 'credit');
      setCheckout(true);
    }
  };

  return (
    <div className={classes.page}>
      <div className={classes.wrap}>
        <h1>Tournament Entry</h1>

        <div className={classes.formWrap}>
          <h2>Select a Tournament</h2>
          {(id && !tournament) || userLoading ? (
            <Loader button={true} />
          ) : (
            <React.Fragment>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TournamentSelect
                  tournaments={openTournaments(tournaments)}
                  value={id}
                  onChange={handleTournament}
                  disabled={checkout}
                />

                {tournament && (
                  <React.Fragment>
                    <TournamentDetails tournament={tournament} />

                    {entered ? (
                      <p>You have already entered this tournament.</p>
                    ) : tournament.full ? (
                      <p>
                        This tournament is already at max capacity! Check back
                        later for more tournaments like this.
                      </p>
                    ) : (
                      <React.Fragment>
                        <div className={classes.fee}>
                          <div className={classes.label}>Entry Fee</div>
                          <div class={classes.price}>
                            {tournament.product.label}
                          </div>
                          {/*tournament.entryCredits > 0 ? (
                            <div className={classes.price}>
                              <img src={noobCredits} alt="Noob credits" />{' '}
                              {tournament.entryCredits}
                            </div>
                          ) : (
                            <div className={classes.price}>FREE</div>
                          )*/}
                        </div>

                        <label htmlFor="terms">
                          <input
                            id="terms"
                            type="checkbox"
                            value={terms}
                            onChange={handleTerms}
                          />{' '}
                          I agree to abide by the{' '}
                          <a href="http://www.youfreakingnoob.com/tournaments-rules/">
                            Official Rules
                          </a>{' '}
                          and{' '}
                          <a href="https://www.youfreakingnoob.com/terms-of-service">
                            Terms of Service
                          </a>{' '}
                          of You Freaking Noob
                        </label>

                        {/*<div className={classes.credits}>
                          <img src={noobCredits} alt="Noob credit" /> Balance:{' '}
                          <span className={classes.quantity}>
                            {user.creditTotal}
                          </span>
                        </div>*/}

                        {termsError && (
                          <div className={classes.error}>{termsError}</div>
                        )}

                        {!checkout ? (
                          <React.Fragment>
                            {/*user.creditTotal < tournament.entryCredits && (
                              <button
                                type="button"
                                onClick={() => setShowBuy(true)}
                              >
                                Buy Credits
                              </button>
                            )}
                            {creditProducts.length > 0 && (
                              <BuyModal
                                isOpen={showBuy}
                                onRequestClose={() => setShowBuy(false)}
                                products={creditProducts}
                                onPaymentComplete={handlePayment}
                              />
                            )*/}

                            <button disabled={entryLoading}>
                              {/* disabled={
                                entryLoading ||
                                user.creditTotal < tournament.entryCredits
                              }*/}
                              {entryLoading ? (
                                <Loader button={true} />
                              ) : tournament.product.price === 0 ? (
                                'Enter for Free'
                              ) : (
                                'Checkout'
                              )}
                            </button>

                            <div className={classes.highlight}>Important!</div>

                            <div className={classes.info}>
                              Your Gamertag
                              {!tournament.individual && ', Team Name, '} and
                              Platform will be entered in the next step
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className={classes.divider} />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </form>

              {checkout && (
                <React.Fragment>
                  <h2>
                    Payment Details{' '}
                    <a href="https://stripe.com/">
                      <img src={stripeBadge} alt="Powered by Stripe" />
                    </a>
                  </h2>

                  <CheckoutForm
                    product={tournament.product}
                    onSuccess={handlePaymentSuccess}
                    processing={entryLoading}
                    error={entryError}
                    paypalEnabled={true}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (
  { auth, creditProducts, meta, tournaments },
  { match }
) => ({
  creditProducts,
  entryError: meta.entryError,
  entryLoading: meta.processingEntry,
  entrySuccess: meta.entrySuccess,
  entered:
    match.params.id && auth.user
      ? !!auth.user.tournaments.find((t) => t.id === match.params.id)
      : false,
  tournaments: activeTournaments(tournaments),
  user: auth.user,
  userLoading: meta.userLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  completeCreditPurchase: (id, type) =>
    dispatch(completeCreditPurchase(id, type)),
  fetchCreditProducts: () => dispatch(fetchCreditProducts()),
  fetchTournaments: () => dispatch(fetchTournaments(false)),
  recordEntry: (tournament, payment, paymentType) =>
    dispatch(recordEntry(tournament, payment, paymentType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryPage);
