// Libraries
import React from 'react';

// Styles
import classes from '../../style.module.scss';

// Components
import Loader from '../../../../components/Loader';
import { Link } from 'react-router-dom';
import VideoList from '../../../../components/VideoList';

export const VideoLayout = ({
  onDelete,
  title,
  type,
  videos,
  hasMore,
  fetchMore,
  loading,
}) => {
  return (
    <div className={classes.page}>
      <h1>{title}</h1>

      {type === 'contest' ? (
        <Link to={`/contests`} className={classes.backLink}>
          ← See all contests
        </Link>
      ) : (
        <Link to={`/`} className={classes.backLink}>
          ← Home
        </Link>
      )}

      <div className={classes.wrapper}>
        <VideoList
          type={type}
          videos={videos}
          onDelete={onDelete}
          hasMore={hasMore}
          fetchMore={fetchMore}
          loading={loading}
        >
          {type === 'user' ? (
            <p>This user has not yet submitted any videos.</p>
          ) : (
            <p>
              There have not been any videos submitted to this contest yet.{' '}
              <Link to="/submit">Be the first!</Link>
            </p>
          )}
        </VideoList>
      </div>
    </div>
  );
};

export default VideoLayout;
