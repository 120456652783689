// Libraries
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';

// Style
import classes from './style.module.scss';

// Components
import { Helmet } from 'react-helmet';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ProviderButtons from '../../components/ProviderButtons';

export const LoginPage = () => {
  // Routing
  const query = new URLSearchParams(useLocation().search);

  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');

  // Handlers
  const handleInput = (e) => {
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      const target = query.get('r');
      if (!target) return setRedirect('/');

      setRedirect(target);
    } catch (e) {
      setError('Invalid username/password combination.');
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Login | You Freaking Noob</title>
      </Helmet>
      {redirect && <Redirect push to={redirect} />}

      <div className={classes.formWrap}>
        <h1>Login</h1>

        <ProviderButtons setError={setError} />

        <div className={classes.divider}>
          <div className={classes.inner}>or</div>
        </div>

        <form onSubmit={handleSubmit} className={classes.form}>
          {error && <div className={classes.error}>{error}</div>}
          <div className={classes.group}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={handleInput}
            />
          </div>

          <div className={classes.group}>
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={handleInput}
            />
          </div>

          <button disabled={loading}>
            {loading ? (
              <PropagateLoader
                css={{
                  height: '22px',
                  marginLeft: '-15px',
                }}
                color="#e8e8e8"
                size={22}
              />
            ) : (
              'Login'
            )}
          </button>
        </form>

        <Link to="/reset">Forgot Password?</Link>

        <div className={classes.registerLink}>
          Need an account?{' '}
          <Link
            to={`/register${
              query.get('r') ? `?r=${encodeURIComponent(query.get('r'))}` : ''
            }`}
          >
            Register here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
