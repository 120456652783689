// Libraries
import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from '../../firebase';
import { connect, useSelector } from 'react-redux';

// Actions
import { trackNextVideo, trackWatchVideo } from '../../redux/actions/analytics';
import { addComment } from '../../redux/actions/comments';
import {
  doVote,
  fetchVideo,
  fetchNextVideo,
  fetchPreviousVideo,
} from '../../redux/actions/videos';

// Styles
import classes from './style.module.scss';

// Components
import AdBar from '../../components/AdBar';
import Comments from '../../components/Comments';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import UpvoteButton from '../../components/UpvoteButton';

export const WatchPage = ({
  videos,
  doVote,
  fetchVideo,
  fetchNextVideo,
  fetchPreviousVideo,
  trackNextVideo,
  trackWatchVideo,
}) => {
  // Routing
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const context = params.get('c') || '';

  // Store data
  const loading = useSelector(({ meta }) => meta.videoLoading);

  // State
  const [video, setVideo] = useState(
    videos.filter((video) => (video ? video.id === params.get('v') : false))[0]
  );
  const [watched, setWatched] = useState(false);

  // Effects
  useEffect(() => {
    const nextVideo = videos.filter((video) =>
      video ? video.id === params.get('v') : false
    )[0];

    if (!nextVideo) {
      fetchVideo(params.get('v'), context);
    }

    console.log(nextVideo);
    setVideo(nextVideo);
  }, [videos, params.get('v')]);

  useEffect(() => {
    if (video && !video.previousVideo) {
      fetchPreviousVideo(params.get('v'), context);
    }
    if (video && !video.nextVideo) {
      fetchNextVideo(params.get('v'), context);
    }
    if (video && !watched) {
      setWatched(true);
      trackWatchVideo(params.get('v'), context);
    }
  }, [video]);

  // Handlers
  const handleVote = (e) => {
    e.preventDefault();

    doVote(video.id);
  };

  const handleBack = (e) => {
    switch (context) {
      case 'contest':
        history.push(`/contest/${video.contest.id}`);
        break;
      case 'latest':
        history.push(`/latest`);
        break;
      case 'trending':
        history.push(`/trending`);
    }
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>{(video && video.title) || 'Video'} | You Freaking Noob</title>

        <meta property="og:type" content="video.other" />
      </Helmet>
      {video && (
        <Link
          className={classes.backButton}
          to={`/${context}${
            context === 'contest'
              ? `/${video.contest && video.contest.id}`
              : context === 'user'
              ? `/${video.submitter && video.submitter.id}`
              : ''
          }`}
        >
          {context ? '← All Videos' : '← Home'}
        </Link>
      )}

      <div className={classes.videoWrap}>
        {loading && !video ? (
          <Loader />
        ) : (
          <React.Fragment>
            {video ? (
              <React.Fragment>
                <div className={classes.player}>
                  {video.nextVideo && video.nextVideo.id && (
                    <Link
                      to={`/watch?${
                        params.get('c') ? `c=${params.get('c')}` : ''
                      }&v=${video.nextVideo.id}`}
                      className={`${classes.navButton} ${classes.left}`}
                      onClick={() =>
                        trackNextVideo(video.id, video.nextVideo.id)
                      }
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                  )}

                  {video.embed_url ? (
                    <iframe
                      src={`${video.embed_url}${
                        video &&
                        video.embed_url &&
                        video.embed_url.includes('https://clips.twitch.tv')
                          ? `&parent=${process.env.BASE_DOMAIN}&autoplay=false`
                          : ''
                      }`}
                      frameBorder="0"
                      scrolling="no"
                      allowFullScreen={true}
                      width="100%"
                      height="100%"
                      allow={
                        video.type === 'youtube'
                          ? 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          : ''
                      }
                    />
                  ) : (
                    <React.Fragment>
                      {video.embed_html && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: video.embed_html.replace(
                              /width="\d*"/g,
                              'width="auth"'
                            ),
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {video.previousVideo && video.previousVideo.id && (
                    <Link
                      to={`/watch?${
                        params.get('c') ? `c=${params.get('c')}` : ''
                      }&v=${video.previousVideo.id}`}
                      className={`${classes.navButton} ${classes.right}`}
                      onClick={() =>
                        trackNextVideo(video.id, video.previousVideo.id)
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  )}
                </div>

                <div className={classes.meta}>
                  <div className={classes.titleBar}>
                    <h1>{video.title}</h1>
                    <small className={classes.submitter}>
                      Submitted by{' '}
                      <Link to={`/user/${video.submitter.id}`}>
                        {video.submitter.username}
                      </Link>
                    </small>

                    <div className={classes.controls}>
                      {/*<a
                        className={classes.secondary}
                        href="https://www.humblebundle.com/store"
                      >
                        Buy [game] Now!
                      </a>*/}
                    </div>
                  </div>
                  <div className={classes.descWrap}>
                    <p className={classes.desc}>{video.description}</p>
                    <UpvoteButton
                      loggedIn={firebase.auth().currentUser}
                      voted={video.hasVoted}
                      onVote={handleVote}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className={classes.wrap404}>
                <h1>Video Not Found</h1>
                <Link to="/">Go Home</Link>
              </div>
            )}
          </React.Fragment>
        )}
      </div>

      <Comments videoId={params.get('v')} />

      <div className={classes.chatbox}>&nbsp;</div>
    </div>
  );
};

export const mapStateToProps = ({ videos }, { match }) => ({
  videos,
});

export const mapDispatchToProps = (dispatch) => ({
  addComment: (comment) => dispatch(addComment({})),
  doVote: (id) => dispatch(doVote(id)),
  fetchVideo: (id, context) => dispatch(fetchVideo(id, context)),
  fetchNextVideo: (id, context) => dispatch(fetchNextVideo(id, context)),
  fetchPreviousVideo: (id, context) =>
    dispatch(fetchPreviousVideo(id, context)),
  trackNextVideo: (fromId, toId) => dispatch(trackNextVideo(fromId, toId)),
  trackWatchVideo: (id, context) => dispatch(trackWatchVideo(id, context)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchPage);
