// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const UploadSuccessPage = () => {
  const params = useParams();

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Upload Successful | You Freaking Noob</title>
      </Helmet>
      <div className={classes.wrapper}>
        <h1>Upload Success!</h1>
        <p>
          Your <Link to={`/watch?v=${params.video}`}>video</Link> has
          successfully been submitted.
        </p>
        <Link to="/submit" className={classes.button}>
          Submit Another
        </Link>
        <Link to="/" className={`${classes.button} ${classes.secondary}`}>
          I'm Done
        </Link>
      </div>
    </div>
  );
};

export default UploadSuccessPage;
