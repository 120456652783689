// Libraries
import React, { useState } from 'react';

// Styles
import classes from './style.module.scss';

export const TextArea = ({ limit, onChange, value, ...props }) => {
  const handleChange = (e) => {
    onChange(e.target.value.slice(0, limit));
  };

  return (
    <div className={classes.wrap}>
      <textarea onChange={handleChange} value={value} {...props} />
      <span
        className={`${classes.remaining} ${
          value.length > 0 ? classes.open : ''
        }`}
      >
        {limit - value.length}
      </span>
    </div>
  );
};

export default TextArea;
