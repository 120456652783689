export const DEFAULT_STATE = {
  route: '/',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_ROUTE':
      return {
        ...state,
        route: action.route,
      };
    default:
      return state;
  }
};
