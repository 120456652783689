// Libraries
import React from 'react';

// Styles
import classes from './style.module.scss';

// Components
import Comment from './components/Comment';

export const CommentList = ({ comments = [] }) => {
  return (
    <div className={classes.commentList}>
      <ul>
        {comments.map((comment) => (
          <Comment {...comment} />
        ))}
      </ul>
    </div>
  );
};

export default CommentList;
