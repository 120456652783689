// Libraries
import React, { useState } from 'react';
import firebase from '../../firebase';

// Styles
import classes from './style.module.scss';

// Components
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');

  // Handlers
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Reset Password | You Freaking Noob</title>
      </Helmet>
      <div className={classes.formWrap}>
        <Link to="/login">← Back to Login</Link>
        <h1>Forgot Password</h1>

        {error ? (
          <React.Fragment>
            <h2>Error</h2>
            <p>{error}</p>

            <button onClick={(e) => setError('')}>Try Again</button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {sent ? (
              <React.Fragment>
                <h2>Reset Instructions Sent</h2>
                <p>
                  Please check your email for instructions on how to complete
                  the password reset process
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2>Enter Your Email</h2>
                <p>
                  We will send you an email with instructions to reset your
                  password.
                </p>

                <form className={classes.form} onSubmit={handleSubmit}>
                  <div className={classes.group}>
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button disabled={loading}>
                    {loading ? <Loader button={true} /> : 'Reset Password'}
                  </button>
                </form>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
