// Libraries
import React, { useState } from 'react';

// Styles
import classes from './style.module.scss';

export const AddComment = ({ onSubmit }) => {
  const [active, setActive] = useState(false);
  const [comment, setComment] = useState('');

  const clearComment = (e) => {
    e.preventDefault();
    setActive(false);
    setComment('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setActive(false);
    setComment('');
    onSubmit(comment);
  };

  return (
    <form
      className={`${classes.addComment} ${active ? classes.active : ''}`}
      onSubmit={handleSubmit}
    >
      <input
        className={classes.input}
        type="text"
        name="add-comment"
        id="add-comment"
        placeholder="Add a public comment..."
        onFocus={() => setActive(true)}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />

      <div className={classes.controls}>
        <button
          className={classes.secondary}
          type="button"
          onClick={clearComment}
        >
          Cancel
        </button>
        <button className={classes.primary}>Comment</button>
      </div>
    </form>
  );
};

export default AddComment;
