export const addAlert = (message, target) => ({
  type: 'ADD_ALERT',
  message,
  target,
});

export const clearAlert = (id) => ({
  type: 'CLEAR_ALERT',
  id,
});

export const clearAlertTarget = (target) => ({
  type: 'CLEAR_ALERT_TARGET',
  target,
});

export const clearAlerts = () => ({
  type: 'CLEAR_ALERTS',
});
