export const addUser = (user) => ({
  type: 'ADD_USER',
  user,
});

export const connectFacebook = () => ({
  type: 'CONNECT_FACEBOOK',
});

export const connectTwitch = (code) => ({
  type: 'CONNECT_TWITCH',
  code,
});

export const connectYoutube = (code) => ({
  type: 'CONNECT_YOUTUBE',
  code,
});

export const createUser = (email, password, username, referrer) => ({
  type: 'CREATE_USER',
  email,
  password,
  username,
  referrer,
});

export const createProviderUser = (referrer) => ({
  type: 'CREATE_PROVIDER_USER',
  referrer,
});

export const fetchUser = (user) => ({
  type: 'FETCH_USER',
  user,
});

export const fetchOtherUser = (id) => ({
  type: 'FETCH_OTHER_USER',
  id,
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const revokeFacebook = () => ({
  type: 'REVOKE_FACEBOOK',
});

export const revokeTwitch = () => ({
  type: 'REVOKE_TWITCH',
});

export const revokeYoutube = () => ({
  type: 'REVOKE_YOUTUBE',
});

export const sendWelcomeEmail = () => ({
  type: 'SEND_WELCOME_EMAIL',
});

export const setJoinError = (errors) => ({
  type: 'SET_JOIN_ERROR',
  errors,
});

export const setJoinLoading = (loading) => ({
  type: 'SET_JOIN_LOADING',
  loading,
});

export const setJoinSuccess = (success) => ({
  type: 'SET_JOIN_SUCCESS',
  success,
});

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
});

export const updateUser = (updates) => ({
  type: 'UPDATE_USER',
  updates,
});
