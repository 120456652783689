export const DEFAULT_STATE = {
  mode: [],
  price: [],
  day: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_FILTERS':
      return DEFAULT_STATE;
    case 'SET_FILTERS':
      return {
        ...state,
        ...action.filters,
      };
    default:
      return state;
  }
};
