// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

// Components
import Countdown from '../../../../components/Countdown';
import Loader from '../../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillWaveAlt';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';

export const TournamentDetails = ({ tournament }) => {
  // State
  const [expanded, setExpanded] = useState(false);
  const [countdown, setCountdown] = useState(
    moment().isBetween(
      moment(tournament.startTime).subtract(6, 'hours'),
      moment(tournament.startTime)
    )
  );

  // Effects
  useEffect(() => {
    setInterval(() => {
      if (
        !moment().isBetween(
          moment(tournament.startTime).subtract(6, 'hours'),
          moment(tournament.startTime)
        )
      )
        setCountdown(false);
    }, 1000);
  });

  if (!tournament) return <Loader />;

  return (
    <div className={classes.details}>
      <div className={classes.row}>
        {tournament.privateMatch ? (
          <div className={classes.headline}>{tournament.alternateMetrics}</div>
        ) : (
          <div className={classes.headline}>
            Highest scoring <strong>{tournament.numMatches}</strong> matches
            wins!
          </div>
        )}
      </div>

      <div className={classes.row}>
        <div className={classes.prize}>
          <FontAwesomeIcon icon={faMoneyBillWaveAlt} className={classes.icon} />
          {tournament.prizePool}
        </div>{' '}
        {tournament.limit && (
          <div className={classes.limit}>
            <FontAwesomeIcon icon={faUser} className={classes.icon} />{' '}
            {tournament.limit} Limit
          </div>
        )}
        {moment().isBetween(
          moment(tournament.startTime).subtract(6, 'hours'),
          moment(tournament.startTime)
        ) && countdown ? (
          <div className={classes.views}>
            <FontAwesomeIcon
              icon={faFire}
              className={`${classes.icon} ${classes.red}`}
            />{' '}
            Starting in{' '}
            <Countdown
              end={tournament.startTime}
              icon={false}
              format={'HH:MM:SS'}
            />
          </div>
        ) : moment().isAfter(moment(tournament.startTime)) ? (
          <div className={classes.views}>
            <FontAwesomeIcon
              icon={faFire}
              className={`${classes.icon} ${classes.red}`}
            />{' '}
            Happening Now!
          </div>
        ) : (
          <div />
        )}
      </div>

      <div className={classes.row}>
        <div className={classes.date}>
          <span className={classes.label}>Start Date:</span>{' '}
          <span>
            {moment(tournament.startTime).format('MMM Do, YYYY [at] h:mm A z')}
          </span>
        </div>
      </div>

      {!tournament.privateMatch && (
        <div className={classes.row}>
          <div className={classes.date}>
            <span className={classes.label}>End Date:</span>{' '}
            <span>
              {moment(tournament.endTime).format('MMM Do, YYYY [at] h:mm A z')}
            </span>
          </div>
        </div>
      )}

      {tournament.moreInfo && (
        <React.Fragment>
          {expanded && (
            <div
              className={classes.moreInfo}
              dangerouslySetInnerHTML={{ __html: tournament.moreInfo }}
            />
          )}
          <button
            type="button"
            className={classes.expander}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Less info...' : 'More info...'}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default TournamentDetails;
