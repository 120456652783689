// Libraries
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Styles
import classes from './style.module.scss';

export const ValidationPage = ({ user }) => {
  // Hooks
  const history = useHistory();

  // State
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  // Effects
  useEffect(() => {
    if (user && user.emailVerified) history.replace('/account');
    if (user) setEmail(user.email);
  }, [user]);

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    await firebase.auth().currentUser.sendEmailVerification({
      url: `${process.env.BASE_URL}/account`,
    });
    setSent(true);
  };

  return (
    <div className={classes.page}>
      <div className={classes.formWrap}>
        <h1>Verify Your Email to Proceed</h1>
        <p>
          Please click the verification link in the email we sent you. If the
          email displayed below is incorrect, please contact us at{' '}
          <a href="mailto:support@youfreakingnoob.com">
            support@youfreakingnoob.com
          </a>
          .
        </p>

        {sent && (
          <p className={classes.success}>
            Your verification email has been sent. Please check your spam folder
            if it doesn't arrive within the next few minutes.
          </p>
        )}

        <form className={classes.form} onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input type="email" value={email} disabled />

          <button>Resend Verification Email</button>
        </form>
      </div>
    </div>
  );
};

export const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(ValidationPage);
