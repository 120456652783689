// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Actions
// import { fetchTournaments } from '../../redux/actions/tournaments';

// Selectors
// import { pastTournaments } from '../../redux/selectors/tournaments';

export const ArchivePage = ({ tournaments, fetchTournaments }) => {
  useEffect(() => {
    // fetchTournaments();
  }, []);

  return (
    <div className={classes.page}>
      <h1>Past Tournaments</h1>

      <div class="panel">
        {tournaments.map((tournament) => (
          <div>{tournament.name}</div>
        ))}
      </div>
    </div>
  );
};

// export const mapStateToProps = ({ tournaments }) => ({
//   tournaments: pastTournaments(tournaments),
// });

// export const mapDispatchToProps = (dispatch) => ({
//   fetchTournaments: () => dispatch(fetchTournaments()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ArchivePage);
export default ArchivePage;
