export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'MERGE_CONTEST_VIDEOS':
      return state.map((contest) =>
        contest.id === action.id
          ? {
              ...contest,
              videos: [...contest.videos, ...action.videos],
            }
          : contest
      );
    case 'SET_CONTESTS':
      return action.contests;
    default:
      return state;
  }
};
