// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { useHistory, useLocation } from 'react-router-dom';

// Actions
import { createProviderUser } from '../../redux/actions/auth';

// Style
import classes from './style.module.scss';

// Assets
import glogo from '../../assets/g-logo.png';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';

export const ProviderButtons = ({
  joinSuccess,
  createProviderUser,
  setError = () => {},
  redirect,
  referrer,
}) => {
  // Hooks
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  // Providers
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const facebookProvider = new firebase.auth.FacebookAuthProvider();

  // Handlers
  const handleLogin = async (e, provider) => {
    e.preventDefault();

    try {
      const result = await firebase.auth().signInWithPopup(provider);

      if (result.additionalUserInfo.isNewUser) {
        createProviderUser(referrer);
        fbq('track', 'CompleteRegistration');
      } else {
        const target = query.get('r');
        if (!target) return history.replace('/account');

        history.replace(target);
      }
    } catch (e) {
      switch (e.code) {
        case 'auth/account-exists-with-different-credential':
          setError(
            'An account with this email already exists, please log in with the same provider you registered with.'
          );
          break;
        default:
          console.error(e);
      }
    }
  };

  useEffect(() => {
    if (joinSuccess) {
      const target = query.get('r');
      if (!target) return history.replace('/account');

      history.replace(target);
    }
  }, [joinSuccess]);

  return (
    <div className={classes.wrap}>
      <button
        className={classes.google}
        onClick={(e) => handleLogin(e, googleProvider)}
      >
        <img src={glogo} alt="" /> Sign in with Google
      </button>
      <button
        className={classes.facebook}
        onClick={(e) => handleLogin(e, facebookProvider)}
      >
        <FontAwesomeIcon icon={faFacebookSquare} className={classes.logo} />
        <span className={classes.text}>Sign in with Facebook</span>
      </button>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  joinSuccess: auth.joinSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createProviderUser: (referrer) => dispatch(createProviderUser(referrer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderButtons);
