// Libraries
import { call, put, takeEvery } from 'redux-saga/effects';

// Actions
import { addComments } from '../../actions/comments';

// Utils
import sendQuery from '../../utils/sendQuery';

export const ADD_COMMENT = `
  mutation($comment: String!, $video: ID!) {
    addComment(comment: $comment, video: $video) {
      id
      comment
      commenter {
        id
        username
      }
      date
      video
    }
  }
`;

export function* addComment(action) {
  const result = yield call(sendQuery, ADD_COMMENT, {
    comment: action.comment.comment,
    video: action.comment.video,
  });
}

export const FETCH_COMMENTS = `
  query($videoId: ID!) {
    comments(videoId: $videoId) {
      id
      comment
      commenter {
        id
        username
      }
      date
      video
    }
  }
`;

export function* fetchComments(action) {
  const result = yield call(sendQuery, FETCH_COMMENTS, {
    videoId: action.video,
  });
  const { data } = yield result.json();

  yield put(
    addComments(
      data.comments.map((comment) => ({
        ...comment,
        commenter: comment.commenter.username,
      }))
    )
  );
}

export default function* comments() {
  yield takeEvery('ADD_COMMENT', addComment);
  yield takeEvery('FETCH_COMMENTS', fetchComments);
}
