// Libraries
import React, { useEffect, useState } from 'react';
import firebase from '../../firebase';
import { gql, useLazyQuery } from '@apollo/client';

// State
import { connect } from 'react-redux';
import {
  createUser,
  setJoinError,
  setJoinLoading,
} from '../../redux/actions/auth';

// Styles
import classes from './style.module.scss';

// Assets
import logo from '../../assets/logo-large.png';

// Components
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropagateLoader from 'react-spinners/PropagateLoader';
import ProviderButtons from '../../components/ProviderButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

export const CHECK_USERNAME = gql`
  query($username: String!) {
    usernameExists(username: $username)
    validUsername(username: $username)
  }
`;

export const JoinPage = ({
  errors,
  loading,
  success,
  createUser,
  setJoinError,
  setJoinLoading,
}) => {
  // GraphQL
  const [
    checkUsername,
    { loading: checkingUsername, error, data },
  ] = useLazyQuery(CHECK_USERNAME, {
    onCompleted: (data) => {
      if (data.usernameExists) {
        setJoinError({
          ...errors,
          username: 'Username is already in use',
        });
      }

      if (!data.validUsername) {
        setJoinError({
          ...errors,
          username:
            'Username contains a blocked word, please choose something different.',
        });
      }
    },
  });

  // Hooks
  const history = useHistory();

  // Routing
  const query = new URLSearchParams(useLocation().search);

  // State
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referrer, setReferrer] = useState('');

  // Effect
  useEffect(() => {
    if (success) {
      console.log(query.get('r'), !!query.get('r'));
      history.push(query.get('r') || '/tournaments');
    }
  }, [success]);

  useEffect(() => {
    if (firebase.auth().currentUser) history.push(query.get('r') || '/');
  }, []);

  useEffect(() => {
    let referrer = document.cookie
      .split(';')
      .find((value) => value.includes('referrer'));
    if (!referrer) referrer = query.get('promo');
    else referrer = referrer.trim().split('=')[1];

    setReferrer(referrer);
  });

  // Handlers
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return false;
    setJoinLoading(true);

    const errors = {};

    if (!username) {
      errors.username = 'Please enter a username.';
    } else if (data && data.usernameExists) {
      errors.username = 'Username is already in use.';
    } else if (data && !data.validUsername) {
      error.username =
        'Username contains a blocked word, please choose something different.';
    }

    if (errors.password || errors.username) {
      setJoinError(errors);
      setJoinLoading(false);
      return;
    }

    createUser(email, password, username, referrer);
  };

  const handleInput = (e) => {
    switch (e.target.id) {
      case 'username':
        setUsername(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
    }

    setJoinError({
      ...errors,
      [e.target.id]: '',
    });
  };

  const handleCheckUsername = (e) => {
    checkUsername({
      variables: {
        username,
      },
    });
  };

  const handleSetError = (error) => {
    setJoinError({
      ...errors,
      provider: error,
    });
  };

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Join Us | You Freaking Noob</title>
      </Helmet>

      <div className={classes.tagline}>
        <h1>Play Call of Duty, Make Money</h1>
        <h2>Join a Trusted Community for CoD Contests and Tournaments</h2>
        <div className={classes.stars}>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <small>
          "Glad to be part of an incredible Discord community" ~ Saurabi10x
        </small>
      </div>

      <div className={classes.providerWrap}>
        {errors.provider && (
          <div className={classes.errorMessage}>{errors.provider}</div>
        )}
        <ProviderButtons setError={handleSetError} referrer={referrer} />
      </div>

      <div className={classes.divider}>
        <div className={classes.inner}>or</div>
      </div>

      <div className={classes.formWrap}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div
            className={`${classes.group} ${errors.username && classes.error}`}
          >
            <label htmlFor="username">Name or Gamertag</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={handleInput}
              onBlur={handleCheckUsername}
            />
            {errors.username && (
              <div className={classes.errorMessage}>{errors.username}</div>
            )}
          </div>

          <div className={`${classes.group} ${errors.email && classes.error}`}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="text"
              value={email}
              onChange={handleInput}
            />
            {errors.email && (
              <div className={classes.errorMessage}>{errors.email}</div>
            )}
          </div>

          <div
            className={`${classes.group} ${errors.password && classes.error}`}
          >
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={handleInput}
            />
            {errors.password && (
              <div className={classes.errorMessage}>{errors.password}</div>
            )}
          </div>

          <button disabled={loading}>
            {loading ? (
              <PropagateLoader
                css={{
                  height: '22px',
                  marginLeft: '-15px',
                }}
                color="#e8e8e8"
                size={22}
              />
            ) : (
              'Join'
            )}
          </button>
        </form>

        <div className={classes.loginLink}>
          Already a member?{' '}
          <Link
            to={`/login${
              query.get('r') ? `?r=${encodeURIComponent(query.get('r'))}` : ''
            }`}
          >
            Login here
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.joinErrors,
  loading: auth.joinLoading,
  success: auth.joinSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (email, password, username, referrer) =>
    dispatch(createUser(email, password, username, referrer)),
  setJoinError: (errors) => dispatch(setJoinError(errors)),
  setJoinLoading: (loading) => dispatch(setJoinLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinPage);
