export const active = (contests) =>
  contests.filter(({ state }) => state === 'submission' || state === 'voting');

export const complete = (contests) =>
  contests.filter(({ state }) => state === 'complete');

export const submission = (contests) =>
  contests.filter(({ state }) => state === 'submission');

export const upcoming = (contests) =>
  contests.filter(({ state }) => state === 'upcoming');

export const voting = (contests) =>
  contests.filter(({ state }) => state === 'voting');
