export const setContestsLoading = (loading) => ({
  type: 'SET_CONTESTS_LOADING',
  loading,
});

export const setMetaFlag = (flag, value) => ({
  type: 'SET_META_FLAG',
  flag,
  value,
});

export const setVideosLoading = (loading) => ({
  type: 'SET_VIDEOS_LOADING',
  loading,
});
