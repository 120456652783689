// Libraries
import { call, put, select, takeEvery } from 'redux-saga/effects';

// Actions
import { updateUser } from '../../actions/auth';
import { setMetaFlag } from '../../actions/meta';
import {
  addTournament,
  setEntry,
  setTournaments,
} from '../../actions/tournaments';

// Utils
import sendQuery from '../../utils/sendQuery';

export const GET_ENTRY = `
  query ($tournamentId: ID!) {
    entry(tournamentId: $tournamentId) {
      id
      activisionId
      user {
        id
        username
      }
    }
  }
`;

export function* fetchEntry(action) {
  yield put(setMetaFlag('entryLoading', true));

  const result = yield call(sendQuery, GET_ENTRY, {
    tournamentId: action.tournament,
  });
  const { data, errors } = yield result.json();

  yield put(
    updateUser({
      entry: data.entry,
    })
  );

  yield put(setMetaFlag('entryLoading', false));
}

export const GET_TOURNAMENTS = `
  query($includePast: Boolean) {
    tournaments(includePast: $includePast) {
      id
      active
      name
      startTime
      endTime
      closeTime
      entryCredits
      scoreMetric
      numMatches
      prizePool
      prizeUnit
      prizeHtml
      hidden
      limit
      full
      remaining
      individual
      moreInfo
      views
      privateMatch
      alternateMetrics
      html
      entries
      hasMinimumEntries
      playWindow
      filters {
        mode
        price
        day
      }
      levels {
        id
        label
        price
      }
      product {
        id
        label
        price
      }
      leaderboardTotals
      leaderboard {
        id
        team
        division
        user {
          id
          username
        }
        userScore
        dateUpdated
        gapped
        highlighted
        members {
          id
          username
          score
        }
        place
      }
    }
  }
`;

export function* fetchTournaments({ includePast }) {
  yield put(setMetaFlag('tournamentsLoading', true));

  try {
    const result = yield call(sendQuery, GET_TOURNAMENTS, {
      includePast,
    });
    const { data, errors } = yield result.json();

    if (errors) throw errors[0];

    yield put(setTournaments(data.tournaments));

    yield put(setMetaFlag('tournamentsLoading', false));
  } catch (e) {
    console.error(e.message);
  }
}

export const FETCH_TOURNAMENT = `
  query($id: ID!) {
    tournament(id: $id) {
      id
      active
      name
      startTime
      endTime
      closeTime
      entryCredits
      individual
      privateMatch
      alternateMetrics
      html
      entries
      hasMinimumEntries
      leaderboardTotals
      filters {
        mode
        price
        day
      }
      leaderboard {
        id
        team
        division
        user {
          id
          username
        }
        userScore
        dateUpdated
        gapped
        highlighted
        members {
          id
          username
          score
        }
        place
      }
    }
  }
`;

export function* fetchTournament(action) {
  yield put(setMetaFlag('tournamentsLoading', true));

  const result = yield call(sendQuery, FETCH_TOURNAMENT, {
    id: action.id,
  });
  const { data, errors } = yield result.json();

  yield put(addTournament(data.tournament));

  yield put(setMetaFlag('tournamentsLoading', false));
}

export const RECORD_ENTRY = `
  mutation($tournament: ID!, $payment: ID, $paymentType: String!) {
    recordEntry(tournament: $tournament, payment: $payment, paymentType: $paymentType)
  }
`;

export function* recordEntry(action) {
  yield put(setMetaFlag('processingEntry', true));

  const result = yield call(sendQuery, RECORD_ENTRY, {
    tournament: action.tournament,
    payment: action.payment,
    paymentType: action.paymentType,
  });
  const { data, errors } = yield result.json();

  if (data) {
    yield put(setMetaFlag('entrySuccess', true));
    yield put(setMetaFlag('entryId', data.recordEntry));

    const user = yield select((state) => state.auth.user);

    if (user) {
      const tournaments = yield select((state) => state.auth.user.tournaments);
      const tournament = yield select((state) =>
        state.tournaments.find((t) => t.id === action.tournament)
      );
      yield put(
        updateUser({
          credits: [
            {
              id: 'temp',
              amount: -tournament.entryCredits,
              date: new Date(),
              expired: false,
              note: `Entry fee: ${tournament.name}`,
            },
            ...user.credits,
          ],
          creditTotal: user.creditTotal - tournament.entryCredits,
          tournaments: [
            ...tournaments,
            {
              id: action.tournament,
              name: tournament.name,
            },
          ],
        })
      );
    }

    yield put(setMetaFlag('processingEntry', false));
  } else {
    yield put(setMetaFlag('entryError', errors[0].message));
  }
}

export const RECORD_ENTRY_ATTEMPT = `
  mutation($email: String!, $challenge: String!) {
    recordEntryAttempt(email: $email, challenge: $challenge)
  }
`;

export function* recordEntryAttempt(action) {
  yield put(setMetaFlag('processingEntry', true));

  const result = yield call(sendQuery, RECORD_ENTRY_ATTEMPT, {
    email: action.email,
    challenge: action.challenge,
  });
  const { data, errors } = yield result.json();

  yield put(setMetaFlag('processingEntry', false));
}

export const LINK_ENTRY = `
  mutation($tournamentId: ID!, $entryId: ID!) {
    linkEntry(tournamentId: $tournamentId, entryId: $entryId)
  }
`;

export function* linkEntry(action) {
  yield put(setMetaFlag('finalizingEntry', true));

  const result = yield call(sendQuery, LINK_ENTRY, {
    tournamentId: action.tournament,
    entryId: action.entry,
  });
  const { data, errors } = yield result.json();

  yield put(setMetaFlag('finalizingEntry', false));
  yield put(setMetaFlag('finalizingEntrySuccess', true));
}

export const UPDATE_ENTRY = `
  mutation($tournament: ID!, $activisionId: String!, $team: String, $platform: String!, $stream: String) {
    updateEntry(
      tournament: $tournament
      activisionId: $activisionId
      team: $team
      platform: $platform
      stream: $stream
    )
  }
`;

export function* updateEntry(action) {
  yield put(setMetaFlag('finalizingEntryDetails', true));

  const result = yield call(sendQuery, UPDATE_ENTRY, {
    tournament: action.tournament,
    activisionId: action.activisionId,
    team: action.team,
    platform: action.platform,
    stream: action.stream,
  });
  const { data, errors } = yield result.json();

  yield put(setMetaFlag('finalizingEntryDetails', false));
  yield put(setMetaFlag('finalizeEntryDetailsSuccess', true));
}

export function* resetEntry(action) {
  yield put(setMetaFlag('processingEntry', false));
  yield put(setMetaFlag('entrySuccess', false));
  yield put(setMetaFlag('entryId', ''));
}

export function* resetEntryDetails() {
  yield put(setMetaFlag('finalizeEntryDetailsSuccess', false));
}

export default function*() {
  yield takeEvery('FETCH_TOURNAMENTS', fetchTournaments);
  yield takeEvery('FETCH_TOURNAMENT', fetchTournament);
  yield takeEvery('FETCH_ENTRY', fetchEntry);
  yield takeEvery('RECORD_ENTRY', recordEntry);
  yield takeEvery('RECORD_ENTRY_ATTEMPT', recordEntryAttempt);
  yield takeEvery('LINK_ENTRY', linkEntry);
  yield takeEvery('RESET_ENTRY', resetEntry);
  yield takeEvery('RESET_ENTRY_DETAILS', resetEntryDetails);
  yield takeEvery('UPDATE_ENTRY', updateEntry);
}
