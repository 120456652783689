export const completeCreditPurchase = (order, orderType) => ({
  type: 'COMPLETE_CREDIT_PURCHASE',
  order,
  orderType,
});

export const fetchCreditProducts = () => ({
  type: 'FETCH_CREDIT_PRODUCTS',
});

export const setCreditProducts = (products) => ({
  type: 'SET_CREDIT_PRODUCTS',
  products,
});
