// Libraries
import { call, put, select, takeEvery } from 'redux-saga/effects';

// Actions
import { setLeaderboard } from '../../actions/leaderboard';
import { setMetaFlag } from '../../actions/meta';

// Utils
import sendQuery from '../../utils/sendQuery';

export const FETCH_LEADERBOARD = `
  query ($game: String!, $timeframe: String!) {
    leaderboard(game: $game, timeframe: $timeframe) {
      id
      kills
      losses
      streak
      winrate
      wins
      user {
        id
        username
      }
    }
  }
`;

export function* fetchLeaderboard(action) {
  yield put(setMetaFlag('globalLeaderboardLoading', true));

  const result = yield call(sendQuery, FETCH_LEADERBOARD, {
    game: action.game,
    timeframe: action.timeframe,
  });
  const { data, errors } = yield result.json();

  yield put(setLeaderboard(data.leaderboard));

  yield put(setMetaFlag('globalLeaderboardLoading', false));
}

export default function*() {
  yield takeEvery('FETCH_LEADERBOARD', fetchLeaderboard);
}
