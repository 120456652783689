// Libararies
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { gql, useQuery } from '@apollo/client';
import useFirebaseUser from '../../hooks/useFirebaseUser';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Assets
import favicon from '../../assets/favicon-32x32.png';

// Components
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

// Pages
import AccountPage from '../../pages/Account';
import AffiliatePage from '../../pages/Affiliate';
import ArchivePage from '../../pages/Archive';
import ChallengePage from '../../pages/Challenge';
import ContestPage from '../../pages/Contest';
import ContestsPage from '../../pages/Contests';
import EntryPage from '../../pages/Entry';
import EntryDetailsPage from '../../pages/EntryDetails';
import EntrySuccessPage from '../../pages/EntrySuccess';
import FinalizePage from '../../pages/Finalize';
import HomePage from '../../pages/Home';
import JoinPage from '../../pages/Join';
import LeaderboardPage from '../../pages/Leaderboard';
import LoadingPage from '../../pages/Loading';
import LoginPage from '../../pages/Login';
import LogoutPage from '../../pages/Logout';
import ResetPasswordPage from '../../pages/ResetPassword';
import SubmitPage from '../../pages/Submit';
import SubscriptionPage from '../../pages/Subscription';
import TournamentsPage from '../../pages/Tournaments';
import UploadSuccess from '../../pages/UploadSuccess';
import ValidationPage from '../../pages/Validation';
import VideosPage from '../../pages/Videos';
import WatchPage from '../../pages/Watch';

export const App = ({ user }) => {
  return (
    <div className={classes.site}>
      <Helmet>
        <link rel="shortcut icon" href={favicon} type="image/png" />
        <script
          src={`https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`}
          defer
        />
      </Helmet>

      <Router>
        <Header />

        <div className={classes.content}>
          <Switch>
            <Route path="/logout">
              <LogoutPage />
            </Route>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/finalize">
              <FinalizePage />
            </Route>
            <Route path="/register">
              <JoinPage />
            </Route>
            <Route path="/r/:id?">
              <AffiliatePage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/success/:video">
              <UploadSuccess />
            </Route>
            <Route path="/watch">
              <WatchPage />
            </Route>
            <Route path="/contests">
              <ContestsPage />
            </Route>
            <Route path="/tournaments">
              <TournamentsPage />
            </Route>
            <Route path="/archive">
              <ArchivePage />
            </Route>
            <Route path="/leaderboard">
              <LeaderboardPage />
            </Route>
            <Route path="/subscription/:id?" component={SubscriptionPage} />
            <Route path="/challenges">
              <Redirect to="/tournaments" />
            </Route>
            <Route path="/entry/success/:id" component={EntrySuccessPage} />
            <Route path="/entry/details/:id" component={EntryDetailsPage} />
            <Route path="/entry/:id?" component={EntryPage} />
            <Route path="/reset">
              <ResetPasswordPage />
            </Route>
            <Route path="/contest/:id" component={ContestPage} />
            <Route
              path="/user/:id"
              render={(props) => <VideosPage type="user" {...props} />}
            />
            <Route path="/latest">
              <VideosPage type="latest" />
            </Route>
            <Route path="/trending">
              <VideosPage type="trending" />
            </Route>
            <Route path="/account">
              <AccountPage />
            </Route>
            <Route
              path="/challenge/:id"
              render={({ match }) => (
                <Redirect to={`/tournament/${match.params.id}`} />
              )}
            />
            <Route path="/tournament/:id" component={ChallengePage} />
            <Route path="/submit">
              {user ? <SubmitPage /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </div>

        <Footer />
      </Router>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(App);
