// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Styles
import classes from './style.module.scss';

// Actions
import { fetchTournaments } from '../../redux/actions/tournaments';

// Selectors
import {
  currentTournaments,
  futureTournaments,
  pastTournaments,
} from '../../redux/selectors/tournaments';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Tournament from './components/Tournament';

export const Tournaments = ({
  loading,
  user,
  currentTournaments,
  futureTournaments,
  pastTournaments,
  fetchTournaments,
}) => {
  console.log(currentTournaments);
  useEffect(() => {
    fetchTournaments();
  }, []);

  return (
    <div className={classes.page}>
      <Helmet>
        <title>Tournaments | You Freaking Noob</title>
      </Helmet>

      <div className={classes.hero}>
        <div className={classes.wrap}>
          <div className={classes.innerWrap}>
            <div className={classes.textWrap}>
              <h1>Win Money Playing Call of Duty</h1>
              <small>
                Enter a Tournament to compete against other players for cash and
                prizes.
              </small>
            </div>

            <div className={classes.videoWrap}>
              <div className={classes.innerVideoWrap}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/5NZrXaTsO2A"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.tournamentWrap}>
        <h2>Current Tournaments</h2>

        {loading ? (
          <Loader button={true} />
        ) : (
          <ul className={classes.tournaments}>
            {currentTournaments.length > 0 ? (
              currentTournaments.map(({ id, ...data }) => (
                <Tournament
                  key={id}
                  id={id}
                  userTournament={
                    user
                      ? user.tournaments.find(
                          (tournament) => tournament.id === id
                        )
                      : null
                  }
                  {...data}
                />
              ))
            ) : (
              <li className={classes.emptyMessage}>
                None, at the moment... Check out our schedule below
              </li>
            )}
          </ul>
        )}

        <h2>Next Tournaments</h2>

        {loading ? (
          <Loader button={true} />
        ) : (
          <ul className={classes.tournaments}>
            {futureTournaments.length > 0 ? (
              futureTournaments.map(({ id, ...data }) => (
                <Tournament
                  key={id}
                  id={id}
                  userTournament={
                    user
                      ? user.tournaments.find(
                          (tournament) => tournament.id === id
                        )
                      : null
                  }
                  {...data}
                />
              ))
            ) : (
              <li className={classes.emptyMessage}>Coming soon...</li>
            )}
          </ul>
        )}

        {/*<h2>Past Challenges</h2>
          {loading ? (
            <Loader button={true} />
          ) : (
            <ul className={classes.tournaments}>
              {pastTournaments.length > 0 ? (
                pastTournaments.map(({ id, ...data }) => (
                  <Tournament key={id} id={id} {...data} />
                ))
              ) : (
                <li className={classes.emptyMessage}>
                  There are not yet any past challenges
                </li>
              )}
            </ul>
          )}*/}
      </div>

      <div className={classes.links}>
        <a href="https://www.youfreakingnoob.com/tournaments-how-it-works">
          How it Works
        </a>
        <a href="https://www.youfreakingnoob.com/tournaments-faq">FAQs</a>
        <a href="https://www.youfreakingnoob.com/tournaments-rules">Rules</a>
      </div>
    </div>
  );
};

export const mapStateToProps = ({ auth, meta, tournaments }) => ({
  loading: meta.tournamentsLoading || meta.userLoading,
  user: auth.user,
  currentTournaments: currentTournaments(tournaments),
  futureTournaments: futureTournaments(tournaments),
  pastTournaments: pastTournaments(tournaments),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchTournaments: () => dispatch(fetchTournaments(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tournaments);
