// Libraries
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip';

// Styles
import classes from './style.module.scss';

// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';

export const UpvoteButton = ({ loggedIn, voted, onVote }) => {
  const [highlighted, setHighlighted] = useState(voted);

  useEffect(() => {
    setHighlighted(voted);
  }, [voted]);

  const handleClick = (e) => {
    setHighlighted(!highlighted);
    onVote(e);
  };

  if (!loggedIn)
    return (
      <Link to="/login" className={classes.loginButton}>
        Login to Vote
      </Link>
    );

  return (
    <button
      className={`${classes.voteButton} ${highlighted ? classes.voted : ''}`}
      onClick={handleClick}
      data-tip={highlighted ? 'Cancel Upvote' : 'Upvote'}
    >
      <FontAwesomeIcon icon={faThumbsUp} />
      <Tooltip />
    </button>
  );
};

export default UpvoteButton;
