export default {
  style: {
    base: {
      color: '#e8e8e8',
      iconColor: '#e8e8e8',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      '::placeholder': {
        color: '#838383',
      },
    },
    invalid: {
      color: '#922719',
      iconColor: '#fa755a',
    },
  },
};
