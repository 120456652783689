export const DEFAULT_STATE = {
  contestsLoading: true,
  videosLoading: true,
  userLoading: true,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_CONTESTS_LOADING':
      return {
        ...state,
        contestsLoading: action.loading,
      };
    case 'SET_META_FLAG':
      return {
        ...state,
        [action.flag]: action.value,
      };
    case 'SET_VIDEOS_LOADING':
      return {
        ...state,
        videosLoading: action.loading,
      };
    default:
      return state;
  }
};
