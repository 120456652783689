// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

// Actions
import { fetchTournament } from '../../redux/actions/tournaments';

// Selectors
import { currentTournament } from '../../redux/selectors/tournaments';

// Components
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord';

export const EntrySuccessPage = ({
  match,
  tournament,
  tournamentLoading,
  fetchTournament,
}) => {
  useEffect(() => {
    if (!tournament) fetchTournament(match.params.id);
  }, []);

  return (
    <div className={classes.page}>
      <div className={classes.wrap}>
        <div className={classes.formWrap}>
          {tournamentLoading && !tournament ? (
            <Loader button={true} />
          ) : tournament ? (
            <React.Fragment>
              <h2>Entry Successful!</h2>
              <p>
                Your Tournament Period begins on{' '}
                {moment(tournament.startTime).format('MMM Do, YYYY')} at{' '}
                {moment(tournament.startTime).format('h:mma')} and ends at{' '}
                {moment(tournament.endTime).format('h:mma')} on{' '}
                {moment(tournament.endTime).format('MMM Do, YYYY')}.
              </p>

              <p>
                We will track all eligible matches played during the Tournament
                Period using your Activision ID.
              </p>

              <p>
                The leaderboard can be accessed in the My Tournaments section of
                your Account page.
              </p>

              <a
                className={classes.button}
                href="https://discord.gg/g8DApH7"
                target="_blank"
              >
                <FontAwesomeIcon icon={faDiscord} className={classes.icon} />{' '}
                Join our Discord to meet Challengers!
              </a>
            </React.Fragment>
          ) : (
            <h2>An error has occurred</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = ({ meta, tournaments }, { match }) => ({
  tournament: currentTournament(match.params.id, tournaments),
  tournamentLoading: meta.tournamentsLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchTournament: (id) => dispatch(fetchTournament(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntrySuccessPage);
