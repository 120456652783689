// Libraries
import React from 'react';
import moment from 'moment';

// Styles
import classes from './style.module.scss';

export const Comment = ({ commenter, comment, date }) => {
  return (
    <li className={classes.comment}>
      <div className={classes.header}>
        <small className={classes.commenter}>{commenter}</small>
        <small className={classes.date}>{moment(date).fromNow()}</small>
      </div>
      <div className={classes.content}>{comment}</div>
    </li>
  );
};

export default Comment;
