// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Actions
import { fetchContests } from '../../../../redux/actions/contests';
import { resetSubmit } from '../../../../redux/actions/submit';

// Selectors
import { latestVideos } from '../../../../redux/selectors/videos';

// Components
import TextArea from '../../../TextArea';

export const Step2 = ({
  classes,
  clip,
  contests,
  currentStep,
  fetchContests,
  onSubmit,
  resetSubmit,
  submitLoading,
  submitSuccess,
  submittedVideo,
}) => {
  // Short-circuit if wrong step
  if (currentStep !== 2) return null;

  // Hooks
  const history = useHistory();

  // State
  const [contest, setContest] = useState('');
  const [title, setTitle] = useState(clip.title);
  const [desc, setDesc] = useState('');
  const [agree, setAgree] = useState(false);

  const [contestError, setContestError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descError, setDescError] = useState('');
  const [termsError, setTermsError] = useState('');

  // Effects
  useEffect(() => {
    fetchContests('active');
  }, []);

  useEffect(() => {
    resetSubmit();
    if (submitSuccess) history.push(`/success/${submittedVideo.id}`);
  }, [submitSuccess]);

  // Handlers
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    if (!contest) return setContestError('You must select a contest');
    if (!desc) return setDescError('You must provide a video description');
    if (!agree)
      return setTermsError('You must agree to the terms to submit a video');

    // Submit
    onSubmit(contest, title, desc);
  };

  return (
    <div className={classes.step}>
      <h2>2. Add Info</h2>
      {clip.type !== 'facebook' ? (
        <iframe
          src={`${clip.embed_url}${
            clip.embed_url.includes('https://clips.twitch.tv')
              ? `&parent=${process.env.BASE_DOMAIN}`
              : ''
          }`}
          frameBorder="0"
          scrolling="no"
          allowFullScreen={true}
          width="100%"
          height="316px"
          allow={
            clip.type === 'youtube' &&
            'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          }
        />
      ) : (
        <div
          className={classes.fbWrap}
          dangerouslySetInnerHTML={{
            __html: clip.embed_html.replace(
              'iframe',
              `iframe class="${classes.fb}"`
            ),
          }}
        />
      )}
      <h3>{clip.title}</h3>

      <form className={classes.form} onSubmit={handleSubmit}>
        <div
          className={`${classes.group} ${contestError ? classes.error : ''}`}
        >
          <label htmlFor="contest">Contest</label>
          <select
            value={contest}
            onChange={(e) => {
              setContest(e.target.value);
              setContestError('');
            }}
          >
            <option value="">Select contest...</option>
            {contests.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          {contestError && <div className={classes.error}>{contestError}</div>}
        </div>

        <div className={`${classes.group} ${titleError ? classes.error : ''}`}>
          <label htmlFor="title">Video Title</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
          />

          {titleError && <div className={classes.error}>{titleError}</div>}
        </div>

        <div className={`${classes.group} ${descError ? classes.error : ''}`}>
          <label htmlFor="description">Tell us why your clip should win</label>
          <TextArea
            id="description"
            value={desc}
            onChange={(value) => {
              setDesc(value);
              setDescError('');
            }}
            limit={300}
          />

          {descError && <div className={classes.error}>{descError}</div>}
        </div>

        <div className={`${classes.group} ${termsError ? classes.error : ''}`}>
          <label htmlFor="confirm">
            <input
              id="confirm"
              type="checkbox"
              value={agree}
              onChange={(e) => {
                setAgree(e.target.value);
                setTermsError('');
              }}
            />
            I agree to abide by the{' '}
            <a
              href="https://youfreakingnoob.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://youfreakingnoob.com/contest-rules">
              Contest Rules
            </a>
          </label>

          {termsError && <div className={classes.error}>{termsError}</div>}
        </div>
        <button disabled={submitLoading}>
          {submitLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export const mapStateToProps = ({ contests, submit, videos }) => ({
  contests,
  submitLoading: submit.submitLoading,
  submitSuccess: submit.submitSuccess,
  submittedVideo: latestVideos(videos)[0],
});

export const mapDispatchToProps = (dispatch) => ({
  fetchContests: (state) => dispatch(fetchContests(state)),
  resetSubmit: () => dispatch(resetSubmit()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
